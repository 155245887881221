import { Badge, Card, Descriptions, Divider, Grid, Popover, Space, Spin, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { machineExistSelector, machineSelector } from "../../recoil/atom.machines"
import { MachineGrid } from "../Dashboard/MachineGrid"
import { LoadingOutlined } from "@ant-design/icons"
import { soudronicBlue } from "../../constants/colors"
import { ComponentGrid } from "../Dashboard/ComponentGrid"
import { SoudronicButtonOrange } from "../Components/SoudronicButtonOrange"
import { DEMO_MACHINE_ID } from "../Dashboard/MachineDashboard"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { DownloadReport } from "../Components/ReportDownload"
import { RequestMachineButton } from "../Components/RequestButton"

const { Title, Text } = Typography
const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />
const { useBreakpoint } = Grid

export const MachinePage = () => {
    const params = useParams()
    const machineId = params.machineId ?? ""

    const timeSettings = useRecoilValue(selectedTimeSelector)
    const screens = useBreakpoint()

    const machine = useRecoilValue(machineSelector(machineId))
    const isConnected = machine?.lineConnection?.status == LineConnectionStatusEnumType.Connected

    const machineExists = useRecoilValue(machineExistSelector(machineId))
    const { t } = useTranslation()

    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        const timout = setTimeout(() => {
            setShowLoader(false)
        }, 6000)
        return () => clearTimeout(timout)
    }, [])

    if (!machineExists) {
        return showLoader ? (
            <Card style={{ marginTop: 20, cursor: "default" }} title={t("Loading...")}>
                <Spin indicator={antIcon} />
            </Card>
        ) : (
            <Card style={{ marginTop: 20, cursor: "default" }} title="No Machine">
                <p>No machine with id: {machineId}</p>
            </Card>
        )
    }
    const popoverInfos = (
        <>
            <Descriptions
                style={{ width: 300 }}
                bordered
                column={1}
                title="Machine Details"
                items={[
                    { key: "km", label: "KM", children: machine?.id },
                    { key: "customer", label: "Customer", children: machine?.customer },
                    { key: "type", label: "Machine Type", children: machine?.machineType },
                ]}
            />
        </>
    )

    return (
        <>
            <Divider />
            <Space direction="horizontal">
                {isConnected ? <Badge size="default" color="green" status="processing" text="" /> : <Badge status="error" text="" />}
                <Title level={2}>{machine?.name}</Title>
                <Popover content={popoverInfos}>
                    <Text style={{ color: soudronicBlue }}>{machine?.machineType}</Text>
                </Popover>

                <div style={{ position: "absolute", right: 35 }}>
                    <Space wrap direction="horizontal">
                        <DownloadReport all={true} deviceId={machineId} begin={timeSettings.start} end={timeSettings.stop} />
                        {machineId == DEMO_MACHINE_ID && screens.sm && <RequestMachineButton />}
                    </Space>
                </div>
            </Space>
            <MachineGrid machineId={machineId} connected={machine?.lineConnection?.status} />
            <ComponentGrid machineId={machineId} />
        </>
    )
}
