import { AtomEffect, DefaultValue, atom } from "recoil"

export const editDashboard = atom({
    key: "editDashboard",
    default: false,
})

export const notifyState = atom({
    key: "notifyState",
    default: false,
})

export const filterOnlineMachinesState = atom({
    key: "filterOnlineMachinesState",
    default: true,
})

export const rollerheadStatisticUnitState = atom({
    key: "rollerheadStatisticUnit",
    default: true,
})

export const allMachineIdsState = atom({
    key: "allMachineIds",
    default: [] as string[],
})

export const premiumState = atom({
    key: "premiumState",
    default: false,
})

export enum InstanceType {
    Customer = "CUSTOMER",
    Factory = "FACTORY",
    Line = "LINE",
    Machine = "MACHINE",
}

export const filterState = atom({
    key: "filterState",
    default: { CUSTOMER: [], FACTORY: [], LINE: [], MACHINE: [] } as { [key in InstanceType]: string[] },
})

export const selectedFilterListState = atom({
    key: "selectedFilterListState",
    default: [] as string[],
})

export const selectedMachineTypesState = atom({
    key: "selectedMachineTypesState",
    default: [] as string[],
})

const localStorageEffect: <T>(key: string) => AtomEffect<T> =
    (key: string) =>
    ({ setSelf, onSet }) => {
        const savedValue = localStorage.getItem(key)
        if (savedValue != null) {
            setSelf(JSON.parse(savedValue))
        }

        onSet((newValue) => {
            if (newValue instanceof DefaultValue) {
                localStorage.removeItem(key)
            } else {
                localStorage.setItem(key, JSON.stringify(newValue))
            }
        })
    }

export const darkModeActive = atom({
    key: "darkModeActive",
    default: false,
    effects: [localStorageEffect("darkMode")],
})
