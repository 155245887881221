import * as vis from "vis-timeline"
import { TimelineOptionsGroupHeightModeType } from "vis-timeline"
import { soudronicAnthracite60 } from "../../constants/colors"

export const optionsTimeline: vis.TimelineOptions = {
  margin: {
    item: {
      horizontal: 0,
    },
  },
  rollingMode: { follow: false },
  maxHeight: "1200px",
  // moment: date => {
  //     return new Date(date);
  // },
  selectable: true,
  stack: false,
  zoomKey: "ctrlKey",
}

export const optionsTimelineStacked: vis.TimelineOptions = {
  // groupHeightMode: "fitItems" as TimelineOptionsGroupHeightModeType,
  // margin: {
  //     item: {
  //         horizontal: 0
  //     }
  // },
  margin: { axis: 40 },
  orientation: "both",
  zoomKey: "ctrlKey",
  // maxHeight: "1200px",
  // moment: date => {
  //     return new Date(date);
  // },
  // height: "500px",
  selectable: true,
  stack: false,
  type: "point",
}

export const optionsTimelineStackedRange: vis.TimelineOptions = {
  // groupHeightMode: "fitItems" as TimelineOptionsGroupHeightModeType,
  // margin: {
  //     item: {
  //         horizontal: 0
  //     }
  // },
  margin: { axis: 40 },
  orientation: "both",
  zoomKey: "ctrlKey",
  // maxHeight: "1200px",
  // moment: date => {
  //     return new Date(date);
  // },
  // height: "500px",
  selectable: true,
  stack: false,
  type: "range",
}

export const datasetOptions: vis.TimelineOptions = {
  format: {
    majorLabels: {
      day: "MMMM YYYY",
      hour: "HH",
      millisecond: "HH:mm:ss",
      minute: "mm",
      month: "YYYY",
      second: "ss",
      week: "MMMM YYYY",
      weekday: "MMMM YYYY",
      year: "",
    },
    minorLabels: {
      day: "D",
      hour: "HH",
      millisecond: "SSS",
      minute: "mm",
      month: "MMM",
      second: "s",
      week: "w",
      weekday: "D",
      year: "YYYY",
    },
  },
  //         groupOrder: (a,b) => (a.value - b.value),
}

export const groups = [
  {
    content: "ON",
    id: "ON",
    value: 1,
  },
  {
    content: "OFF",
    id: "OFF",
    value: 0,
  },
  {
    content: "Error",
    id: "ERROR",
    value: 3,
  },
  {
    content: "Lack of Material",
    id: "LACK_OF_MATERIAL",
    value: 4,
  },
  {
    content: "LineStop",
    id: "LINE_STOP",
    value: 2,
  },
  {
    content: "Disabled",
    id: "DISABLED",
    value: 5,
  },
  {
    content: "Unknown",
    id: "UNKNOWN",
    value: 6,
  },
]

export const getStyleString = (machineStatus?: string): string => {
  let styleString = ""
  switch (machineStatus) {
    case "ON": {
      const colorLocal = getMachineStateColor("ON")
      styleString = "background-color: " + colorLocal + ";"
      break
    }
    case "ERROR": {
      const colorLocal = getMachineStateColor("ERROR")
      styleString = "background-color: " + colorLocal + ";"
      break
    }
    case "OFF": {
      const colorLocal = getMachineStateColor("OFF")
      styleString = "background-color: " + colorLocal + ";"
      break
    }
    case "LACK_OF_MATERIAL": {
      const colorLocal = getMachineStateColor("LACK_OF_MATERIAL")
      styleString = "background-color: " + colorLocal + ";"
      break
    }
    case "LINE_STOP": {
      const colorLocal = getMachineStateColor("LINE_STOP")
      styleString = "background-color: " + colorLocal + ";"
      break
    }
    case "DISABLED": {
      const colorLocal = getMachineStateColor("DISABLED")
      styleString = "background-color: " + colorLocal + ";"
      break
    }
    case "UNKNOWN": {
      const colorLocal = getMachineStateColor("Unknown")
      styleString = "background-color: " + colorLocal + ";"
      break
    }
  }
  return styleString
}
const colors = [
  {
    color: "#607D8B",
    flash: false,
    state: "DISABLED",
  },
  {
    color: "#F44336",
    flash: false,
    state: "ERROR",
  },
  {
    color: "#FF9800",
    flash: false,
    state: "LACK_OF_MATERIAL",
  },
  {
    color: "#FFEB3B",
    flash: false,
    state: "LINE_STOP",
  },
  {
    color: soudronicAnthracite60,
    flash: false,
    state: "OFF",
  },
  {
    color: "#4CAF50",
    flash: false,
    state: "ON",
  },
  {
    color: "#607D8B",
    flash: false,
    state: "UNKNOWN",
  },
  {
    color: "#607D8B",
    flash: false,
    state: "BAD_CONNECTION",
  },
]
const getMachineStateColor = (state: string) => {
  const c = colors.filter((c) => c.state == state).map((c) => c.color)
  c.push("#FFFFFF")
  return c[0]
}
