import React, { useEffect } from "react"
import { Button, ConfigProvider, notification, Space, Spin, theme } from "antd"
import { AppLayout } from "./AppLayout"
import { useStartAuth } from "./auth/start"
// import de_DE from 'antd/es/locale/de_DE';
import { useIsAuthenticated } from "@azure/msal-react"
import { Welcome } from "./components/Pages/Welcome"
import { Insights } from "./Insights"
import { useRecoilState, useRecoilValue } from "recoil"
import { hasAccessToken } from "./recoil/atom.msal"
import { ApolloProviderProject } from "./graphql/ApolloProvider"
import { useServiceWorker } from "./useServiceworker"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "./helpers/useLocalStorage"
import { darkModeActive } from "./recoil/atoms.application"

export const App: React.FC = () => {
    const { t } = useTranslation()
    const { waitingWorker, showReload, reloadPage } = useServiceWorker()
    const [api, contextHolder] = notification.useNotification()

    const darkmode = useRecoilValue(darkModeActive)

    useStartAuth()
    const isAuth = useIsAuthenticated()
    const hasToken = useRecoilValue(hasAccessToken)

    useEffect(() => {
        navigator.serviceWorker.getRegistrations().then((registration) => {
            if (registration[0]) {
                if (registration[0].waiting) {
                    openNotification()
                }
            }
        })
    }, [])

    useEffect(() => {
        if (showReload) {
            openNotification()
        }
    }, [waitingWorker, showReload, reloadPage])

    const handleWorkerUpdate = () => {
        navigator.serviceWorker.ready.then((registration) => {
            registration.waiting?.postMessage({ type: "SKIP_WAITING" })
            document.location.href = "/"
            window.location.reload()
        })
    }

    const openNotification = () => {
        const key = `open${Date.now()}`
        const btn = (
            <Space>
                <Button type="link" size="small" onClick={() => api.destroy()}>
                    Cancel
                </Button>
                <Button type="primary" size="small" onClick={handleWorkerUpdate}>
                    Update
                </Button>
            </Space>
        )
        api.open({
            message: t("notification.update.title"),
            description: t("notification.update.description"),
            btn,
            key,
            onClose: close,
            duration: 0,
        })
    }

    return (
        <ConfigProvider
            theme={{
                algorithm: darkmode ? theme.darkAlgorithm : theme.defaultAlgorithm,
                token: {
                    fontFamily: "Roboto",
                    borderRadius: 10,
                },

                components: {
                    Button: { borderRadius: 999 },
                    Segmented: {
                        borderRadius: 10,
                    },
                    Tooltip: {
                        borderRadius: 10,
                    },
                    Radio: {
                        borderRadius: 10,
                    },
                },
            }}
            // locale={de_DE}
        >
            <AppLayout>
                {contextHolder}
                {isAuth && hasToken ? (
                    <ApolloProviderProject>
                        <Insights />
                    </ApolloProviderProject>
                ) : (
                    <Spin />
                )}
            </AppLayout>
        </ConfigProvider>
    )
}
