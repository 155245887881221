import { useLazyQuery, useQuery } from "@apollo/client"
import { ChartData, TooltipItem } from "chart.js"
import React, { useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { getSecondsFromhhmmss, secondsToDHHMMSS } from "../../../helpers/TimeHelpers"
import { gql } from "../../../__generated__/gql"
import { GetStateTimesFromToQuery, GetStateTimesQuery, MachineStatusType } from "../../../__generated__/graphql"
import { getColorForState } from "./MachineState"

import ChartDataLabels, { Context } from "chartjs-plugin-datalabels"
import { contrastColors } from "../../../constants/colors"
import { Empty } from "antd"

const GET_STATE_TIMES = gql(`
        query GetStateTimes($deviceId: String!) {
            machineStateTime(deviceId: $deviceId){
                duration
                status
                machineId
  }
}`)

export const MachineStatesWidget = ({ machineId, inputData }: { machineId?: string; inputData?: GetStateTimesFromToQuery | undefined }) => {
    const [getStates, { loading, error, data, refetch }] = useLazyQuery(GET_STATE_TIMES, { variables: { deviceId: machineId ?? "" } })
    const [chartData, setChartData] = useState<ChartData<"doughnut">>({ labels: [], datasets: [] })

    useEffect(() => {
        if (inputData && inputData.machineStateTime) {
            const labels = inputData.machineStateTime.map((d) => d?.status)
            const values = inputData.machineStateTime.map((d) => getSecondsFromhhmmss(d?.duration))
            const backgroundColor = inputData.machineStateTime.map((d) => getColorForState(d?.status ?? MachineStatusType.Unknown))
            setChartData({ labels: labels, datasets: [{ data: values, backgroundColor: backgroundColor, borderRadius: 5 }] })
        } else {
            if (data?.machineStateTime) {
                const labels = data.machineStateTime.map((d) => d?.status)
                const values = data.machineStateTime.map((d) => getSecondsFromhhmmss(d?.duration))
                const backgroundColor = data.machineStateTime.map((d) => getColorForState(d?.status ?? MachineStatusType.Unknown))
                setChartData({ labels: labels, datasets: [{ data: values, backgroundColor: backgroundColor, borderRadius: 5 }] })
            }
        }
    }, [data, inputData])
    useEffect(() => {
        if (!inputData) {
            getStates()
        }
    }, [inputData])

    return chartData.datasets[0]?.data.length > 0 ? (
        <Doughnut
            height={240}
            plugins={[ChartDataLabels as any]}
            options={{
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true,
                            pointStyle: "rectRounded",
                        },
                    },
                    datalabels: {
                        color: (context: Context) => {
                            const index = context.dataIndex
                            let c = "black"
                            if (context.dataset.backgroundColor?.constructor === Array) {
                                c = context.dataset.backgroundColor ? context.dataset.backgroundColor[index] : "black"
                            }
                            return contrastColors[c]
                        },
                        formatter: (value, context: Context) => {
                            const total = context.dataset.data.filter((_, i) => context.chart.getDataVisibility(i)).reduce<number>((prev, actual) => prev + (actual as number), 0)
                            const percentage = Math.round((value / total) * 100)
                            if (percentage < 4) return ""
                            return percentage + "%"
                        },
                        font: {
                            weight: "bold",
                            size: 12,
                        },
                        anchor: "center",
                        display: "auto",
                        clip: false,
                        offset: 200,
                        // align: 'top'
                    },
                    tooltip: {
                        callbacks: {
                            label: (v: TooltipItem<"doughnut">) => `${secondsToDHHMMSS(parseInt(v.raw as any))} `,
                        },
                    },
                },
            }}
            data={chartData}
        />
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )
}
