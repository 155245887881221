import { Button } from "antd"
import { SizeType } from "antd/es/config-provider/SizeContext"
import React from "react"
import { Link } from "react-router-dom"
import { soudronicAnthracite, soudronicBlue, soudronicFontWhite } from "../../constants/colors"
import "./soudronic-button.css"


export const NavigationButton = ({ linkTo, text, alternative, size, externalLink }: { linkTo?: string, text: string, alternative?: boolean, size?: SizeType, externalLink?: boolean }) => {

    const button = <Button className="soudronic-button" size={size} style={{ backgroundColor: alternative ? soudronicAnthracite : soudronicBlue, color: soudronicFontWhite, whiteSpace: "pre-wrap", }}>
        {'\u0020'}{'\u0020'}{'\u2014'}{'\u0020'} {text}
    </Button>


    return (
        <Link to={linkTo ? linkTo : "/"}>
            {button}
        </Link >

    )
}