import { Badge, Card, Descriptions, Divider, Grid, Popover, Progress, Space, Spin, Tag, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { machineExistSelector, machineSelector } from "../../recoil/atom.machines"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { LoadingOutlined } from "@ant-design/icons"
import { soudronicBlue } from "../../constants/colors"
import { LineView } from "./LineView"
import { LineGrid } from "../Dashboard/LineGrid"
import { gql } from "../../__generated__/gql"
import { useQuery } from "@apollo/client"
import dayjs from "dayjs"
import { secondsToDHHMM } from "../../helpers/TimeHelpers"
import { SyncOutlined } from "@ant-design/icons"
import { DEMO_LINE_ID } from "../Dashboard/LineDashboard"
import { DownloadReport } from "../Components/ReportDownload"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { RequestLineButton } from "../Components/RequestButton"
const { useBreakpoint } = Grid

export const LINE_STATE_SUBSCRIPTION = gql(`
subscription linestateSubscription($deviceId: String!) {
  onLineStatus(deviceId: $deviceId) {
    value {
      status
    }
    deviceId
  }
}`)

export const LINE_MACHINES_SUBSCRIPTION = gql(`
subscription onLineMachines($deviceId: String!){
  onLineMachines(deviceId: $deviceId){
    value{
      machines{
        id
        name
        viewDefinitionId
        status{
          status
          since
        }
        error{
          errorId
          errorText
        }
      }
    }
    deviceId
  }
}
`)

export const LINE_DOWNCAUSE_SUBSCRIPTION = gql(`
subscription onLineDowncause($deviceId: String!){
  onLineDowncause(deviceId: $deviceId){
    deviceId
    value{
      reason
      machineId
      error{
        errorId
        errorText
      }
      state
      begin
      end
    }
  }
}
`)

export const LINE_PROGRESS_SUBSCRIPTION = gql(`
subscription onLineProgress($deviceId: String!) {
  onLineProgress(deviceId: $deviceId) {
    deviceId
    value{
      progress
      current
      begin
      end
    }
  }
}
`)
export const LINE_EFFICIENCY_SUBSCRIPTION = gql(`
subscription onLineEfficiency($deviceId: String!) {
  onLineEfficiency(deviceId: $deviceId) {
    deviceId
    value{
      producedUnits
      possibleUnits
      efficiency
      trend
    }
  }
}
`)

export const GET_LINE_DATA = gql(`
query getLineData($deviceId: String!) {
    line(deviceId: $deviceId) {
      deviceId
      progress {
        progress
      },
      settings{
        lineName
        masterMachineId
      },
      currentShift{
        name
        begin
        end
      },
      mode{
        mode
        since
      },
      state{
        status
        color
        changed
      },
      recipe{
        name
      }
      ,
      currentDownCause {
        machineId
        reason
        state
        begin
        end
        error {
          errorId
          errorText
        }
      }
      progress{
        progress,
        begin
        end
        current
      },
      efficiency{
        producedUnits
        possibleUnits
        efficiency
        trend
      }
      configuration {
        machines {
          id
          name
          viewDefinitionId
          status{
            status
            since
          }
          error {
            errorId
            errorText
          }
        }
      }
      productionPath{
        name
        id
        machines{
          id
          name
        }
      }
    }
  }
`)

const { Title, Text } = Typography
const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />

export const LinePage = () => {
    const params = useParams()
    const lineId = params.machineId ?? ""

    const machine = useRecoilValue(machineSelector(lineId))
    const isConnected = machine?.lineConnection?.status == LineConnectionStatusEnumType.Connected
    const machineExists = useRecoilValue(machineExistSelector(lineId))
    const { t } = useTranslation()

    const timeSettings = useRecoilValue(selectedTimeSelector)

    const screens = useBreakpoint()

    const { data: lineData, subscribeToMore } = useQuery(GET_LINE_DATA, { variables: { deviceId: lineId } })
    useEffect(() => {
        subscribeToMore({
            document: LINE_STATE_SUBSCRIPTION,
            variables: { deviceId: lineId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev
                }
                const state = subscriptionData.data.onLineStatus?.value?.status

                return Object.assign({}, prev, { ...prev, line: { ...prev.line, state: { ...prev.line?.state, status: state } } })
            },
        })
        subscribeToMore({
            document: LINE_MACHINES_SUBSCRIPTION,
            variables: { deviceId: lineId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev
                }
                const mcs = subscriptionData.data.onLineMachines?.value?.machines

                return Object.assign({}, prev, { ...prev, line: { ...prev.line, configuration: { ...prev.line?.configuration, machines: mcs } } })
            },
        })
        subscribeToMore({
            document: LINE_DOWNCAUSE_SUBSCRIPTION,
            variables: { deviceId: lineId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev
                }
                const downcasue = subscriptionData.data.onLineDowncause?.value

                return Object.assign({}, prev, { ...prev, line: { ...prev.line, currentDownCause: downcasue } })
            },
        })
        subscribeToMore({
            document: LINE_PROGRESS_SUBSCRIPTION,
            variables: { deviceId: lineId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev
                }
                const downcasue = subscriptionData.data.onLineProgress?.value

                return Object.assign({}, prev, { ...prev, line: { ...prev.line, progress: downcasue } })
            },
        })
        subscribeToMore({
            document: LINE_EFFICIENCY_SUBSCRIPTION,
            variables: { deviceId: lineId },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) {
                    return prev
                }
                const efficiency = subscriptionData.data.onLineEfficiency?.value

                return Object.assign({}, prev, { ...prev, line: { ...prev.line, efficiency: efficiency } })
            },
        })
    }, [])

    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        const timout = setTimeout(() => {
            setShowLoader(false)
        }, 6000)
        return () => clearTimeout(timout)
    }, [])

    if (!machineExists) {
        return showLoader ? (
            <Card style={{ marginTop: 20, cursor: "default" }} title={t("Loading...")}>
                <Spin indicator={antIcon} />
            </Card>
        ) : (
            <Card style={{ marginTop: 20, cursor: "default" }} title="No Machine">
                <p>No machine with id: {lineId}</p>
            </Card>
        )
    }
    const progressDetails = (
        <>
            <Descriptions column={1} style={{ width: 250 }}>
                <Descriptions.Item label="Start">{new Date(lineData?.line?.progress?.begin).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item label="End">{new Date(lineData?.line?.progress?.end).toLocaleString()}</Descriptions.Item>
                <Descriptions.Item label="Progress">{Math.round((lineData?.line?.progress?.progress ?? 0) * 100 * 10) / 10}%</Descriptions.Item>
                <Descriptions.Item label="Remaining">
                    {secondsToDHHMM(dayjs.duration(dayjs(lineData?.line?.progress?.end).diff(dayjs(lineData?.line?.progress?.current))).asSeconds())}h
                </Descriptions.Item>
            </Descriptions>
        </>
    )
    const popoverInfos = (
        <>
            <Descriptions
                style={{ width: 300 }}
                bordered
                column={1}
                title="Machine Details"
                items={[
                    { key: "km", label: "KM", children: machine?.id },
                    { key: "customer", label: "Customer", children: machine?.customer },
                    { key: "type", label: "Machine Type", children: machine?.machineType },
                ]}
            />
        </>
    )

    return (
        <>
            <Divider />
            <Space direction="horizontal">
                {isConnected ? <Badge size="default" color="green" status="processing" text="" /> : <Badge status="error" text="" />}
                <Title level={2}>{lineData?.line?.settings?.lineName ?? machine?.name}</Title>
                <Popover content={popoverInfos}>
                    <Text style={{ color: soudronicBlue }}>{machine?.machineType}</Text>
                </Popover>
                <div style={{ position: "absolute", right: 35 }}>
                    <Space wrap direction="horizontal">
                        <DownloadReport all={true} deviceId={lineId} begin={timeSettings.start} end={timeSettings.stop} />
                        {lineId == DEMO_LINE_ID && screens.sm && <RequestLineButton />}
                    </Space>
                </div>
            </Space>
            <LineGrid connected={LineConnectionStatusEnumType.Connected} lineId={lineId} />
            <Popover placement="top" content={progressDetails}>
                <Progress strokeColor={{ from: soudronicBlue, to: soudronicBlue }} size={["100%", 20]} percent={(lineData?.line?.progress?.progress ?? 0) * 100} status="active" showInfo={false} />
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Tag icon={<SyncOutlined spin />} color="processing">
                        Current Shift Progress
                    </Tag>
                </div>
            </Popover>

            <Divider />
            {lineData && lineData.line && <LineView lineData={lineData} />}
        </>
    )
}
