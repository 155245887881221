import { Badge, Space, Statistic, Tooltip } from "antd";
import React, { useEffect } from "react"
import { getFilter } from "../../../helpers/MachineColors";
import { gql } from "../../../__generated__/gql";
import { LineConnectionStatusEnumType, MachineStatusType } from "../../../__generated__/graphql";
import { useRecoilValue } from "recoil";
import { machineSelector } from "../../../recoil/atom.machines";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { soudronicRaspberry } from "../../../constants/colors";
import { useLazyQuery } from "@apollo/client";



export const getColorForState = (stateName?: MachineStatusType): string => {
    switch (stateName) {
        case MachineStatusType.Error:
            return 'red'
        case MachineStatusType.LineStop:
            return 'yellow'
        case MachineStatusType.On:
            return 'green'
        case MachineStatusType.Off:
            return 'lightGray'
        case MachineStatusType.LackOfMaterial:
            return 'orange'
        default:
            return 'gray'
    }
}

export const getLightColorForState = (stateName?: MachineStatusType): string => {
    switch (stateName) {
        case MachineStatusType.Error:
            return '#ff000078'
        case MachineStatusType.LineStop:
            return '#ffff009e'
        case MachineStatusType.On:
            return '#0080008a'
        case MachineStatusType.Off:
            return 'lightGray'
        case MachineStatusType.LackOfMaterial:
            return '#ffa500a3'
        default:
            return 'gray'
    }
}

export const GET_LAST_CONNECTED_QUERY = gql(`
    query lastConnectd($deviceId: String!) {
        lastConnected(deviceId: $deviceId)
    }
`)

export const MachineState = ({ machineId, connected }: { machineId?: string, connected?: LineConnectionStatusEnumType | null }) => {
    const machine = useRecoilValue(machineSelector(machineId))

    const picturepath = machine?.machineTypeImageName ? `/images/${machine.machineTypeImageName}.png` : '/images/Abstract_MLC_black.svg'
    const picture =
        <img style={{ maxWidth: "100%", maxHeight: "100%", verticalAlign: "middle" }} src={picturepath} alt={picturepath} />

    const mcIsConnected = connected === LineConnectionStatusEnumType.Connected
    const backgroundColor = mcIsConnected ? getLightColorForState(machine?.status?.status ?? MachineStatusType.Unknown) : "gray"

    const [getLastConnected, { loading, error, data: lastConnected, refetch }] = useLazyQuery(GET_LAST_CONNECTED_QUERY, { variables: { deviceId: machineId ?? "" } });

    useEffect(() => {
        if (!mcIsConnected) {
            getLastConnected()
        }
    }, [mcIsConnected])

    const onlineState = <>{machine?.error?.errorText ?
        <Tooltip placement="right" color={soudronicRaspberry} title={machine?.error?.errorText}>
            <Statistic title="Machine State" value={machine?.status?.status ?? MachineStatusType.Unknown} />
        </Tooltip>
        : <Statistic title="Machine State" value={machine?.status?.status ?? MachineStatusType.Unknown} />}
        {machine?.status?.status && <Statistic title="Since" value={new Date(machine?.status?.changed).toLocaleTimeString()} />}
    </>

    const offlineState = <>
        <Statistic title="Status" value={"Offline"} />
        <Statistic title="Since" value={lastConnected?.lastConnected ? new Date(lastConnected?.lastConnected).toLocaleString() : "more than 60 days"} />

    </>

    return (
        <>
            {
                <div style={{ height: 180, alignItems: "center", display: "flex", justifyContent: "center" }}>
                    {picture}
                </div>
            }
            <div style={{ backgroundColor: backgroundColor, position: "absolute", bottom: 0, left: 0, right: 0, height: 80 }}>
                <Space style={{ marginLeft: 10, bottom: 5, position: "absolute" }} direction="horizontal" size={"large"} >
                    {
                        mcIsConnected ? onlineState : offlineState
                    }
                </Space>
            </div>
        </>
    )
}