export const soudronicBlue = "#3D7CC9";
export const soudronicAnthracite = "#54565A"
export const soudronicOrange = "#ff7500"
export const soudronicAnthracite60 = "#98999C"
export const soudronicAnthracite10 = "#EEEEEE"
export const soudronicGreen = "#80bd01"
export const soudronicRaspberry = "#c71d3d"
export const soudronicGold = "#95774c"
export const soudronicSilver = "#8d8e8e"


export const soudronicFontAnthracite = "#333333"
export const soudronicFontWhite = "#FFFFFF"

export const contrastColors: { [key: string]: string } = { 'lightGray': 'black', 'green': 'white', 'red': 'white', 'yellow': 'black', 'orange': 'black' }