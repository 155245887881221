import { useApolloClient, useQuery } from "@apollo/client"
import { Modal, Select, Spin, message } from "antd"
import React, { useEffect, useState } from "react"
import { Routes, Route, useNavigate } from "react-router-dom"
import { useRecoilState, useSetRecoilState } from "recoil"
import { Dashboard } from "./components/Dashboard/Dashboard"
import { MachineErrorPage } from "./components/Pages/ErrorPage"
import { MachineCounterPage } from "./components/Pages/MachineCounterPage"
import { MachineStatePage } from "./components/Pages/MachineStatePage"
import { TopErrorPage } from "./components/Pages/TopErrorPage"
import { machinesState } from "./recoil/atom.machines"
import { gql } from "./__generated__/gql"
import { MachineRollerheadPage } from "./components/Pages/MachineRollerheadPage"
import { VersionHistory } from "./components/Pages/VersionHistory"
import { allMachineIdsState } from "./recoil/atoms.application"
import { GET_LINES_OVERVIEW } from "./graphql/requests/LineRequests"
import { useKey } from "./helpers/useKey"
import { DevPage } from "./components/Pages/DevPage"
import { UserManager } from "./UserManager/UserManagerPage"
import { DetailPageRouter } from "./components/Pages/DetailPageRouter"
import { LineMachineStatesPage } from "./components/Pages/LineMachineStatesPage"
import { LineGeneralPage } from "./components/Pages/LineGeneralPage"
import { LineEfficiencyPage } from "./components/Pages/LineEfficiencyPage"
import { LineDowncausePage } from "./components/Pages/LineDowncausePage"
import { LineErrorPage } from "./components/Pages/LineErrorPage"
import { PremiumOnlyPage } from "./components/Pages/PremiumOnlyPage"

const MACHINE_STATUS_SUB = gql(/* GraphQL */ `
    subscription MachineStatSub($deviceId: String!) {
        onMachineStatus(deviceId: $deviceId) {
            deviceId
            value {
                id
                status
                changed
            }
        }
    }
`)

const MACHINE_ERROR_SUB = gql(`
    subscription MachineError($deviceId: String!) {
		onMachineError(deviceId: $deviceId) {
            deviceId
            value {
                errorId
                errorText
            }
  }
}
`)

const MACHINE_COUNTER_SUB = gql(`
subscription CounterSub($deviceId: String!){
  onMachineCounter(deviceId: $deviceId){
    deviceId
    value
  }
}
`)

const MACHINE_CONNECTION_SUB = gql(`
subscription deviceConnection($deviceId: String!){
  onLineConnection(deviceId: $deviceId){
    deviceId
    value{
      status
      color
      changed
    }
  }
}
`)

const GET_MACHINES = gql(/* GraphQL */ `
    query GetMachines {
        machines {
            error {
                errorId
                errorText
            }
            id
            name
            customer
            machineType
            machineTypeImageName
            status {
                status
                changed
            }
            counter
            productionRate
            lineConnection {
                status
                color
                changed
            }
        }
    }
`)

export const Insights = () => {
    const setMachines = useSetRecoilState(machinesState)
    const [loading, setLoading] = useState(true)

    const apolloClient = useApolloClient()

    const [messageApi, contextHolder] = message.useMessage()

    const startStatusSub = (id?: string | null) => {
        if (id) {
            apolloClient.subscribe({ query: MACHINE_STATUS_SUB, variables: { deviceId: id } }).subscribe({
                next: (data) => {
                    setMachines((current) => {
                        const newValue = data.data?.onMachineStatus?.value
                        const newList = current?.machines?.map((m) => {
                            if (m?.id == newValue?.id) return { ...m, status: newValue }
                            return m
                        })
                        if (newList) {
                            return { machines: newList }
                        }
                        return { current }
                    })
                },
            })
            apolloClient.subscribe({ query: MACHINE_ERROR_SUB, variables: { deviceId: id } }).subscribe({
                next: (data) => {
                    setMachines((current) => {
                        const newValue = data.data?.onMachineError
                        const newList = current?.machines?.map((m) => {
                            if (m?.id == newValue?.deviceId) return { ...m, error: newValue?.value }
                            return m
                        })
                        if (newList) {
                            return { machines: newList }
                        }
                        return current
                    })
                },
            })
            apolloClient.subscribe({ query: MACHINE_COUNTER_SUB, variables: { deviceId: id } }).subscribe({
                next: (data) => {
                    setMachines((current) => {
                        const newValue = data.data?.onMachineCounter
                        const newList = current?.machines?.map((m) => {
                            if (m?.id == newValue?.deviceId) return { ...m, counter: newValue?.value }
                            return m
                        })
                        if (newList) {
                            return { machines: newList }
                        }
                        return current
                    })
                },
            })
            apolloClient.subscribe({ query: MACHINE_CONNECTION_SUB, variables: { deviceId: id } }).subscribe({
                next: (data) => {
                    setMachines((current) => {
                        const newValue = data.data?.onLineConnection
                        const newList = current?.machines?.map((m) => {
                            if (m?.id == newValue?.deviceId) return { ...m, lineConnection: newValue?.value }
                            return m
                        })
                        if (newList) {
                            positive("Update Machine " + newValue?.deviceId + " " + newValue?.value?.status)
                            return { machines: newList }
                        }
                        return current
                    })
                },
            })
        }
    }

    const positive = (message: string) => {
        messageApi.open({
            type: "info",
            content: message,
            duration: 10,
        })
    }
    const infoMaintenance = (message: string) => {
        messageApi.open({
            type: "info",
            content: message,
            duration: 0,
        })
    }

    const navigate = useNavigate()
    const [allIds, setAllIds] = useRecoilState(allMachineIdsState)
    const { loading: linesLoading, error, data } = useQuery(GET_LINES_OVERVIEW)

    const [showSearch, setShowSearch] = useState(false)
    useKey("f", () => {
        setTimeout(() => {
            setShowSearch(true)
        }, 20)
    })

    const handleSelect = (value: string) => {
        setShowSearch(false)
        navigate(value)
    }
    useEffect(() => {
        if (data && data.lines) {
            const allMachineIds = data.lines.map((l) => l?.deviceId ?? "")
            allMachineIds.unshift("/")
            setAllIds(allMachineIds)
        }
    }, [data])

    useEffect(() => {
        // infoMaintenance("10.07.2023 - Maintenance Downtime possible")
        const start = async () => {
            const result = await apolloClient.query({ query: GET_MACHINES })
            setMachines({ machines: result.data.machines })
            setLoading(false)
            result.data.machines?.forEach((m) => startStatusSub(m?.id))
        }
        start()
    }, [])

    return loading ? (
        <Spin />
    ) : (
        <>
            {contextHolder}
            {
                <Modal title="Go to KM..." open={showSearch} onOk={() => setShowSearch(false)} destroyOnClose keyboard onCancel={() => setShowSearch(false)}>
                    <Select
                        ref={(input) => input && input.focus()}
                        showSearch
                        style={{ width: "100%" }}
                        placeholder=" 🔎 "
                        autoFocus={true}
                        optionFilterProp="value"
                        defaultOpen
                        onSelect={handleSelect}
                        options={allIds.map((id) => ({ value: id, label: id }))}
                        onBlur={() => setShowSearch(false)}
                    />
                </Modal>
            }

            <Routes>
                <Route path="/" element={<Dashboard />}></Route>
                <Route path="/:machineId/MachineState/" element={<MachineStatePage />} />
                <Route path="/:machineId/MachineError/" element={<MachineErrorPage />} />
                <Route path="/:machineId/MachineTopError/" element={<TopErrorPage />} />
                <Route path="/:machineId/Effectiveness/" element={<MachineCounterPage />} />
                <Route
                    path="/:machineId/Rollerhead/"
                    element={
                        <PremiumOnlyPage>
                            <MachineRollerheadPage />
                        </PremiumOnlyPage>
                    }
                />
                <Route path="/:lineId/LineMachines/" element={<LineMachineStatesPage />} />
                <Route path="/:lineId/LineGeneral/" element={<LineGeneralPage />} />
                <Route path="/:lineId/LineEfficiency/" element={<LineEfficiencyPage />} />
                <Route path="/:lineId/LineDowncause/" element={<LineDowncausePage />} />
                <Route path="/:lineId/LineError/" element={<LineErrorPage />} />
                <Route path="/:machineId/" element={<DetailPageRouter />} />
                <Route path="/versionHistory/" element={<VersionHistory />} />
                <Route path="/usermanager/" element={<UserManager />} />
                <Route path="/dev/manage/api/" element={<DevPage />} />
            </Routes>
        </>
    )
}
