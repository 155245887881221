import React, { useEffect } from "react"
import * as vis from 'vis-timeline'
import { datasetOptions, getStyleString, groups, optionsTimeline } from "./Vis.Options"
import './vis.css'
import "vis-timeline/dist/vis-timeline-graph2d.css";
import { useQuery } from "@apollo/client";
import { gql } from "../../__generated__/gql";
import { GetStateHistQuery } from "../../__generated__/graphql";
import { secondsToDHHMMSS } from "../../helpers/TimeHelpers";
import { useRecoilValue } from "recoil";
import { selectedTimeSelector } from "../../recoil/atom.time";

const datasetItems: vis.DataItem[] = []
let timeline: vis.Timeline
var items: vis.DataItem[] = []
const lineModeGroups: vis.DataGroup[] = [];



export const MachineStatesTimeline = ({ data }: { data?: GetStateHistQuery }) => {

    const container = React.useRef(null) as any
    const timeSettings = useRecoilValue(selectedTimeSelector)


    useEffect(() => {
        if (container && container.current) {
            timeline = new vis.Timeline(container.current, items, groups, optionsTimeline)
        } else {
            console.error("Effect container.current undefined")
        }
    }, [])


    useEffect(() => {
        if (container && container.current && data && data.stateLog && timeline) {
            items = data.stateLog.map((d, i) => {
                const message = `${d?.status} `
                const title = `${d?.status} - ${secondsToDHHMMSS(d?.eventDuration)}s`
                const item: vis.DataItem = {
                    title: title,
                    id: i,
                    start: d?.startTimestamp,
                    end: d?.endTimestamp,
                    content: message,
                    group: d?.status?.toString() ?? "",
                    style: getStyleString(d?.status?.toString())
                }
                return item
            })
            timeline.setItems(items)
            timeline.redraw()
            setTimeout(() => {
                timeline.setWindow(timeSettings.start.toDate(), timeSettings.stop.toDate())
            }, 100)
        }
    }, [data])





    return (
        <div style={{}} ref={container} />
    )
}

