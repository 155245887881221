import { useParams } from "react-router-dom"
import { LinePage } from "./LinePage"
import React from "react"
import { MachinePage } from "./MachinePage"

export const DetailPageRouter = () => {
    const params = useParams()
    const machineId = params.machineId ?? ""


    if (machineId.endsWith("-ST")) {
        return <LinePage />
    } else {
        return <MachinePage />
    }
}