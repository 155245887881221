import { useQuery } from "@apollo/client"
import { Alert, Button, Card, Col, message, Row, Space, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { soudronicAnthracite } from "../../constants/colors"
import { machineExistSelector } from "../../recoil/atom.machines"
import { gql } from "../../__generated__/gql"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { TimeEditor } from "../Components/TimeEdit"
import { MachineState } from "../Dashboard/Widgets/MachineState"
import { MachineStatesTimeline } from "../Timelines/MachineStatesTimeline"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { ScrollHint } from "./Helpers"
import { MachineStatesWidget } from "../Dashboard/Widgets/MachineStatesWidget"
import { StatesTable } from "../Components/StatesTable"
import { DownloadReport } from "../Components/ReportDownload"

const { Title } = Typography

const GET_ERROR_HIST = gql(`
query GetStateHist($deviceId: String!, $max: Int, $begin: DateTime, $end: DateTime){
  stateLog(deviceId: $deviceId, maxNumber: $max, begin: $begin, end: $end){
    machineId
    status
    startTimestamp
    endTimestamp
    eventDuration
  }
}        
`)
const GET_STATE_TIMES = gql(`
        query GetStateTimesFromTo($deviceId: String!, $begin: DateTime, $end: DateTime) {
            machineStateTime(deviceId: $deviceId, begin: $begin, end: $end){
                duration
                status
                machineId
  }
}`)

const MAX_ELEMENTS = 3000

export const MachineStatePage = () => {
    const { t } = useTranslation()

    const params = useParams()
    const machineId = params.machineId ?? ""
    const timeSettings = useRecoilValue(selectedTimeSelector)

    const { data, refetch } = useQuery(GET_ERROR_HIST, { variables: { deviceId: machineId ?? "", max: MAX_ELEMENTS, begin: timeSettings.start.toISOString(), end: timeSettings.stop.toISOString() } })
    const { data: statesData } = useQuery(GET_STATE_TIMES, { variables: { deviceId: machineId, begin: timeSettings.start.toISOString(), end: timeSettings.stop.toISOString() } })

    const handleRefetch = () => {
        refetch()
        success()
    }
    const [messageApi, contextHolder] = message.useMessage()
    const success = () => {
        messageApi.open({
            type: "success",
            content: "Updated Data",
        })
    }

    const updateComponent = (
        <Space wrap>
            <Button onClick={() => handleRefetch()} style={{ backgroundColor: soudronicAnthracite, color: "white" }}>
                {t("updateData")}
            </Button>
            <DownloadReport begin={timeSettings.start} end={timeSettings.stop} deviceId={machineId} typeOfReport={"Errors"} name="Default" />
        </Space>
    )

    const machineExists = useRecoilValue(machineExistSelector(machineId))

    if (!machineExists) {
        return (
            <Card style={{ marginTop: 20, cursor: "default" }} title="No Machine">
                <p>No machine with id: {machineId}</p>
            </Card>
        )
    }

    const maxElementHint = (
        <Alert
            showIcon
            type="warning"
            message={"The number of displayed elements is limited due to the selected time frame being too large. Please consider narrowing down your time range for more accurate results."}
        />
    )

    return (
        <Space size={"middle"} direction="vertical" style={{ display: "flex" }}>
            <div />
            {contextHolder}
            <Title level={2}>{t("MachineState.Title")}</Title>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xl={8} sm={24}>
                    <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Time">
                        <div style={{ height: "100%", width: "100%" }}>
                            {" "}
                            {/*  justifyContent: "center", display: "flex", */}
                            <TimeEditor machineId={machineId} />
                        </div>
                    </Card>
                </Col>
                <Col xl={8} sm={24}>
                    <Card style={{ height: 350, alignItems: "center", cursor: "default", position: "relative", overflow: "hidden" }} hoverable title="Live Status">
                        <MachineState machineId={machineId} connected={LineConnectionStatusEnumType.Connected} />
                    </Card>
                </Col>
                <Col xl={8} sm={24}>
                    <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title={t("machineStates.widgetTitle")}>
                        <div style={{ height: "100%", width: "100%" }}>
                            {" "}
                            {/*  justifyContent: "center", display: "flex", */}
                            <MachineStatesWidget inputData={statesData} machineId={machineId} />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Card style={{ height: 850, alignItems: "center", cursor: "default" }} hoverable title="Production History" extra={updateComponent}>
                <Space direction="vertical" style={{ display: "flex" }}>
                    {ScrollHint}
                    {data?.stateLog?.length == MAX_ELEMENTS && maxElementHint}
                    <MachineStatesTimeline data={data} />
                    {statesData && <StatesTable errorData={statesData} />}
                </Space>
            </Card>
        </Space>
    )
}
