import { Button, Col, Descriptions, DescriptionsProps, Divider, Row, Spin, Statistic, TimePicker, Tour, TourProps } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useRef, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { machineSelector, plannedProductionTime } from "../../recoil/atom.machines"
import { secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import { Doughnut } from "react-chartjs-2"
import { gql } from "../../__generated__/gql"
import { useQuery } from "@apollo/client"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { TooltipItem } from "chart.js"

const GET_OEE = gql(`
    query GetOee($deviceId: String!, $begin: DateTime!, $end: DateTime!, $plannedTime : Int) {
        oee( deviceId: $deviceId,
            begin: $begin
            end: $end ,plannedTime: $plannedTime){
            machineId
            duration
            plannedProduction
            startTimestamp
            endTimestamp
            availability
            performance
            quality
            oee
            
        }
    }
 
`)
const chartOption = { maintainAspectRatio: false, responsive: true, plugins: { tooltip: { callbacks: { label: (v: TooltipItem<"doughnut">) => v.formattedValue + "%" } } } }

const format = "HH:mm"
export const Oee = ({ machineId }: { machineId: string }) => {
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
    const ref4 = useRef(null)

    const machine = useRecoilValue(machineSelector(machineId))
    const [productionTime, setProductionTime] = useRecoilState(plannedProductionTime)
    const timeSettings = useRecoilValue(selectedTimeSelector)
    const { data: oeeData } = useQuery(GET_OEE, {
        variables: {
            deviceId: machineId ?? "",
            begin: timeSettings.start.toISOString(),
            end: timeSettings.stop.toISOString(),
            plannedTime: productionTime > 0 ? Math.round(productionTime / 60) : undefined,
        },
    })

    const [open, setOpen] = useState(false)
    const handleTextChange = (time: dayjs.Dayjs, _timestring: string | string[]) => {
        try {
            if (time) {
                const updatedDuration = dayjs.duration({ hours: time?.hour(), minutes: time.minute() }).asSeconds()
                if (updatedDuration <= timeSettings.duration / 1000) {
                    setProductionTime(updatedDuration)
                }
            }
        } catch (e) {}
    }

    useEffect(() => {
        setProductionTime(timeSettings.duration / 1000)
    }, [timeSettings])

    const steps: TourProps["steps"] = [
        {
            title: "Availability",
            description: "Calculation: operating time / planned operating time",
            target: () => ref1.current,
        },
        {
            title: "Performance",
            description: "Calculation: Produced Unit / planned production",
            target: () => ref2.current,
        },
        {
            title: "Quality",
            description: "Calculation: Good parts / planned parts",
            target: () => ref3.current,
        },
        {
            title: "OEE - Overall Equipment Effectiveness",
            description: "Calculation: Availability x Performance x Quality",
            cover: <img alt="Oee" src="/oee.jpg" />,
            target: () => ref4.current,
        },
    ]

    const descriptionItems: DescriptionsProps["items"] = [
        {
            key: "1",
            label: "Production Rate",
            children: machine?.productionRate + " units/min",
        },
        {
            key: "2",
            label: "Planned Production",
            children: secondsToDHHMMSS(oeeData?.oee.plannedProduction) + "h",
        },
        {
            key: "3",
            label: "Window Duration",
            children: secondsToDHHMMSS(oeeData?.oee.duration) + "h",
        },
    ]

    return (
        <>
            <Row>
                <Col sm={24} xl={8}>
                    <p>Set planned production time [h]</p>
                    <TimePicker onChange={handleTextChange} value={dayjs.utc(productionTime * 1000)} defaultValue={dayjs.utc(timeSettings.duration)} format={format} />
                    {/* {productionTime > timeSettings.duration / 1000 && <Alert banner message="Planned Production > Selected Time" type="warning" />} */}
                    {/* <Tag
                        style={{ userSelect: 'none' }}
                        onClose={handleCloseTag}
                    ><p>{secondsToDHHMMSS(productionTime)}h</p></Tag> */}
                </Col>
                <Col sm={24} xl={10}>
                    <Descriptions title="OEE Info" items={descriptionItems} />
                </Col>
                <Col sm={24} xl={6}>
                    <Button style={{}} type="primary" onClick={() => setOpen(true)}>
                        What is OEE?
                    </Button>
                </Col>
            </Row>

            <Divider />
            <Row gutter={16}>
                <Col ref={ref1} sm={12} xl={6}>
                    {/* <Tooltip title="Calculation: operating time / planned operating time"> */}
                    <Statistic title="Availability" value={(oeeData?.oee.availability ?? 0) * 100} suffix={"%"} precision={2} />
                    {/* </Tooltip> */}
                    <div style={{ height: 150, width: 150 }}>
                        {oeeData?.oee.availability ? (
                            <Doughnut options={chartOption} data={{ datasets: [{ label: "Availability", data: [oeeData?.oee.availability * 100, (1 - oeeData?.oee.availability) * 100] }] }} />
                        ) : (
                            <Spin />
                        )}
                    </div>
                </Col>
                <Col ref={ref2} sm={12} xl={6}>
                    <Statistic title="Performance" value={(oeeData?.oee.performance ?? 0) * 100} suffix={"%"} precision={2} />
                    <div style={{ height: 150, width: 150 }}>
                        {oeeData?.oee.performance ? (
                            <Doughnut options={chartOption} data={{ datasets: [{ label: "Performance", data: [oeeData.oee.performance * 100, (1 - oeeData.oee.performance) * 100] }] }} />
                        ) : (
                            <Spin />
                        )}
                    </div>
                </Col>
                <Col ref={ref3} sm={12} xl={6}>
                    <Statistic title="Quality" value={(oeeData?.oee.quality ?? 0) * 100} suffix={"%"} precision={2} />
                    <div style={{ height: 150, width: 150 }}>
                        {oeeData?.oee.quality ? (
                            <Doughnut options={chartOption} data={{ datasets: [{ label: "Quality", data: [oeeData.oee.quality * 100, (1 - oeeData.oee.quality) * 100] }] }} />
                        ) : (
                            <Spin />
                        )}
                    </div>
                </Col>
                <Col ref={ref4} sm={12} xl={6}>
                    <Statistic title="OEE" value={(oeeData?.oee.oee ?? 0) * 100} suffix={"%"} precision={2} />
                    <div style={{ height: 150, width: 150 }}>
                        {oeeData?.oee.oee ? <Doughnut options={chartOption} data={{ datasets: [{ label: "OEE", data: [oeeData.oee.oee * 100, (1 - oeeData.oee.oee) * 100] }] }} /> : <Spin />}
                    </div>
                </Col>
            </Row>
            <Tour open={open} onClose={() => setOpen(false)} steps={steps} />
        </>
    )
}
