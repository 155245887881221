import { Button, Checkbox, Divider, Input, Popconfirm, Space, Table, TimePicker, Tooltip } from "antd"
import { CheckboxValueType } from "antd/es/checkbox/Group"
import { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { ActionButton } from "./ActionButton"
import { v4 as uuidv4 } from "uuid"
import { useMutation, useQuery } from "@apollo/client"
import { ShiftInput, ShiftUpdateInput } from "../../__generated__/graphql"
import { gql } from "../../__generated__/gql"
import { InfoCircleOutlined } from "@ant-design/icons"

enum Operation {
    added,
    deleted,
    modified,
}

export interface IShift {
    id: string
    name: string
    begin: dayjs.Dayjs
    end: dayjs.Dayjs
    duration?: number
    days: number[]
    event?: Operation
}
interface Weekday {
    value: number
    label: string
}
export const weekdays: Weekday[] = [
    { label: "Mon", value: 1 },
    { label: "Tue", value: 2 },
    { label: "Wed", value: 3 },
    { label: "Thu", value: 4 },
    { label: "Fri", value: 5 },
    { label: "Sat", value: 6 },
    { label: "Sun", value: 0 },
]

interface WeekdayDict {
    [key: number]: string
}

const weekdaysDict: WeekdayDict = weekdays.reduce((acc, day) => {
    acc[day.value] = day.label
    return acc
}, {} as WeekdayDict)

export const getWeekdayFromNumner = (weekdayNumber: number): string => {
    return weekdaysDict[weekdayNumber]
}

const format = "HH:mm"

enum timeSelection {
    begin,
    end,
}
export const GET_SHIFTS = gql(`
    query GetShifts($deviceId: String!){
        shifts(deviceId: $deviceId) {
            id
            name
            begin
            end
            days
        }
    }
`)

const ADD_SHIFT = gql(`
    mutation addShift($deviceId: String!, $shift: ShiftInput!){
        addShift(deviceId: $deviceId, shift: $shift){
            name
            begin
            end
            days
            id
        }
    }
`)

const UPDATE_SHIFT = gql(`
    mutation updateShift($deviceId: String!, $shift: ShiftUpdateInput!){
        updateShift(deviceId: $deviceId,shift: $shift ){
            id
            name
            begin
            end
            days
        }
    }
`)

const DELETE_SHIFT = gql(`
    mutation deleteShift($deviceId: String!, $shiftId: String!){
        deleteShift(deviceId: $deviceId,shiftId: $shiftId )
    }
`)
const getShiftFromFetch = (inputShift: { __typename?: "ShiftType" | undefined; id: string; name: string; begin: any; end: any; days: number[] }) => {
    const shift: IShift = { id: inputShift.id, name: inputShift.name, begin: dayjs(inputShift.begin, format), end: dayjs(inputShift.end, format), days: inputShift.days }
    return shift
}
const getShiftsFromFetch = (
    data: {
        __typename?: "ShiftType" | undefined
        id: string
        name: string
        begin: any
        end: any
        days: number[]
    }[]
) => {
    const shifts: IShift[] = data.map((s) => getShiftFromFetch(s))
    return shifts
}

const getShiftFetchFromShift = (shift: IShift) => {
    const shiftsToStore: ShiftInput = { name: shift.name, begin: shift.begin.format(format), end: shift.end.format(format), days: shift.days }
    return shiftsToStore
}
const getShiftFetchUpdateFromShift = (shift: IShift) => {
    const shiftsToStore: ShiftUpdateInput = { id: shift.id, name: shift.name, begin: shift.begin.format(format), end: shift.end.format(format), days: shift.days }
    return shiftsToStore
}

export const ShiftsEditor = ({ machineId }: { machineId: string }) => {
    // const shiftsStored = useRecoilValue(shiftSelector(machineId))

    const { data, loading, error, refetch } = useQuery(GET_SHIFTS, { variables: { deviceId: machineId } })
    const [addShifts, { data: addData, loading: addLoading, error: addError }] = useMutation(ADD_SHIFT)
    const [updateShift, { data: updateData, loading: updateLoading, error: updateError }] = useMutation(UPDATE_SHIFT)
    const [deleteShift, { data: deleteData, loading: deleteLoading, error: deleteError }] = useMutation(DELETE_SHIFT)

    const [dataSource, setDataSource] = useState<IShift[]>([])
    // const [origData, setOrigData] = useState<IShift[]>([])
    const [modifiedRows, setModifiedRows] = useState<string[]>([])
    const [modified, setModified] = useState(false)
    const [disableAdd, setDisableAdd] = useState(false)

    useEffect(() => {
        if (data) {
            const shifts = getShiftsFromFetch(data.shifts)
            setDataSource(shifts)
            // setOrigData(shifts)
        }
    }, [data])

    const replaceShift = (shift: IShift) => {
        setDataSource((prev) =>
            prev.map((s) => {
                if (s.id == shift.id) {
                    return shift
                }
                return s
            })
        )
    }
    const addShift = (shift: IShift) => {
        setDataSource((prev) =>
            prev.map((s) => {
                if (s.event == Operation.added) {
                    return shift
                }
                return s
            })
        )
    }

    useEffect(() => {
        if (addData && addData?.addShift) {
            const shift = getShiftFromFetch(addData.addShift)
            addShift(shift)
        }
    }, [addData])

    useEffect(() => {
        if (updateData && updateData.updateShift) {
            const shift = getShiftFromFetch(updateData.updateShift)
            replaceShift(shift)
        }
    }, [updateData])

    useEffect(() => {
        if (deleteData && deleteData.deleteShift) {
            setDataSource((source) => source.filter((s) => s.id !== deleteData.deleteShift))
        }
    }, [deleteData])

    useEffect(() => {
        if (dataSource) {
            const changedIds: string[] = dataSource.filter((shift) => shift.event != undefined).map((shift) => shift.id)
            const added = dataSource.filter((shift) => shift.event == Operation.added)
            setModifiedRows(changedIds)
            if (added.length > 0) {
                setDisableAdd(true)
            } else {
                setDisableAdd(false)
            }
        }
    }, [dataSource])

    const onChange = (record: IShift) => (checkedValue: CheckboxValueType[]) => {
        setDataSource((data) =>
            data.map((value) => {
                if (value.id != record.id) {
                    return value
                }
                return { ...value, days: checkedValue as number[], event: value.event == Operation.added ? Operation.added : Operation.modified }
            })
        )
    }

    const handleAddShift = () => {
        setDataSource([
            ...dataSource,
            { event: Operation.added, id: uuidv4(), name: "Shift " + (dataSource.length + 1), begin: dayjs("12:00", format), end: dayjs("16:00", format), days: [0, 1, 2, 3, 4, 5, 6] },
        ])
    }

    const handleStore = (record: IShift) => {
        switch (record.event) {
            case Operation.added:
                addShifts({ variables: { deviceId: machineId, shift: getShiftFetchFromShift(record) } })
                break
            case Operation.modified:
                updateShift({ variables: { deviceId: machineId, shift: getShiftFetchUpdateFromShift(record) } })
                break

            default:
                break
        }
    }
    const handleCancel = () => {
        // setDataSource(origData)
    }

    const handleDeleteShift = (record: IShift) => () => {
        if (record.event == Operation.added) {
            setDataSource((source) => source.filter((s) => s.id !== record.id))
        } else {
            deleteShift({ variables: { deviceId: machineId, shiftId: record.id } })
        }
    }
    const handleSelectChange = (record: IShift, selection: timeSelection) => (updatedTime: dayjs.Dayjs, dateString: string | string[]) => {
        if (typeof dateString == "string") {
            switch (selection) {
                case timeSelection.begin:
                    setDataSource(
                        dataSource.map((value) => {
                            if (value.id != record.id) return value
                            return { ...value, begin: dayjs(dateString, format), event: value.event == Operation.added ? Operation.added : Operation.modified }
                        })
                    )
                    break
                case timeSelection.end:
                    setDataSource(
                        dataSource.map((value) => {
                            if (value.id != record.id) return value
                            return { ...value, end: dayjs(dateString, format), event: value.event == Operation.added ? Operation.added : Operation.modified }
                        })
                    )
                    break
                default:
                    break
            }
        }
    }

    const handleNameChange = (record: IShift) => (e: any) => {
        setDataSource((data) =>
            data.map((value) => {
                if (value.id != record.id) return value
                return { ...value, name: e.target.value, event: value.event == Operation.added ? Operation.added : Operation.modified }
            })
        )
    }

    const columns: ColumnsType<IShift> = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (value: any, record: IShift, index: number) => {
                return <Input value={value} onChange={handleNameChange(record)}></Input>
            },
        },
        {
            title: "begin",
            dataIndex: "begin",
            key: "begin",
            render: (value: dayjs.Dayjs, record: IShift, index: number) => {
                return <TimePicker value={value} format={format} minuteStep={5} onChange={handleSelectChange(record, timeSelection.begin)} />
            },
        },
        {
            title: "end",
            dataIndex: "end",
            key: "end",
            render: (value: dayjs.Dayjs, record: IShift, index: number) => {
                return <TimePicker value={value} format={format} minuteStep={5} onChange={handleSelectChange(record, timeSelection.end)} />
            },
        },
        {
            title: "duration",
            key: "name",
            render: (value: any, record: IShift, index: number) => {
                let diff = dayjs.duration(record.end.diff(record.begin))
                if (diff.asSeconds() < 0) {
                    const updatedEnd = record.end.add(dayjs.duration(1, "day"))
                    diff = dayjs.duration(updatedEnd.diff(record.begin))
                }
                return <p>{diff.format("HH:mm")}h</p>
            },
        },
        {
            title: "days",
            dataIndex: "days",
            key: "days",
            render: (value: any, record: IShift, index: number) => {
                return <Checkbox.Group options={weekdays} value={record.days} onChange={onChange(record)} />
            },
        },
        {
            title: "Edit",
            key: "name",
            render: (value: any, record: IShift, index: number) => {
                return (
                    <Space direction="horizontal">
                        <Popconfirm title="Delete shift" description={`Are you sure to delete ${record.name} shift?`} okText="Yes" cancelText="No" onConfirm={handleDeleteShift(record)}>
                            <Button danger>Delete</Button>
                        </Popconfirm>
                        {(record.event == Operation.added || record.event == Operation.modified) && (
                            <Button type="primary" onClick={() => handleStore(record)}>
                                Save
                            </Button>
                        )}
                        <Tooltip title={"ID: " + record.id}>
                            <InfoCircleOutlined />
                        </Tooltip>
                    </Space>
                )
            },
        },
    ]

    return (
        <>
            <Table
                rowKey={"id"}
                pagination={false}
                dataSource={dataSource.filter((s) => s.event !== Operation.deleted)}
                columns={columns}
                rowSelection={{ selectedRowKeys: modifiedRows, columnTitle: "Modified", hideSelectAll: true }}
            />
            <Divider dashed />
            <Space direction="horizontal">
                <ActionButton onClick={handleAddShift} disabled={disableAdd} text="Add Shift" />
                {/* <ActionButton onClick={handleStore} disabled={!modified} text="Save" /> */}
                <ActionButton onClick={handleCancel} disabled={!modified} text="Cancel" alternative />
            </Space>
        </>
    )
}
