import { Button, Tooltip } from "antd"
import { SizeType } from "antd/es/config-provider/SizeContext"
import React from "react"
import { Link } from "react-router-dom"
import { soudronicFontWhite, soudronicOrange } from "../../constants/colors"
import "./soudronic-button.css"

export const SoudronicButtonOrange = ({ text, size, externalLink, tooltip }: { text: string; size?: SizeType; externalLink: string; tooltip?: string }) => {
    const button = (
        <Button className="soudronic-button-orange" size={size} style={{ backgroundColor: soudronicOrange, color: soudronicFontWhite, whiteSpace: "pre-wrap" }}>
            {" "}
            {"\u2014"} {text}
        </Button>
    )
    const component = (
        <Link target="_blank" to={externalLink}>
            {button}
        </Link>
    )
    if (tooltip) {
        return <Tooltip title={tooltip}>{component}</Tooltip>
    }

    return component
}
