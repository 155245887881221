import React, { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { accessTokenRolesState, accessTokenState } from "../../recoil/atom.msal"
import { Button, Card, Divider, List, Space, message } from "antd"
import { useAccount, useMsal } from "@azure/msal-react"

export const DevPage = () => {

    const token = useRecoilValue(accessTokenState)
    const [apiResult, setApiResult] = useState({})
    const [machinesResult, setMachinesResult] = useState({})
    const [messageApi, contextHolder] = message.useMessage();
    const roles = useRecoilValue(accessTokenRolesState)


    useEffect(() => {
        const getVersion = async () => {
            const result = await (await fetch(process.env.REACT_APP_API_REST_URL + '/welcome', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                }),
            })).json();
            if (result.apiVersion) {
                setApiResult(result)
            }
        }
        const getMachines = async () => {
            const result = await (await fetch(process.env.REACT_APP_API_REST_URL + '/manage', {
                method: 'get',
                headers: new Headers({
                    'Authorization': 'Bearer ' + token,
                }),
            })).json();
            if (result) {
                setMachinesResult(result)
            }
        }
        if (token !== "" && roles && roles.includes("Insights.Dev")) {
            getVersion()
            getMachines()
        }
    }, [token])
    const positive = (message: string) => {
        messageApi.open({
            type: 'success',
            content: message,
            duration: 10
        });
    };
    const negative = (message: string) => {
        messageApi.open({
            type: 'error',
            content: message,
            duration: 10
        });
    };

    const handleUpdateData = async () => {
        const result = await (await fetch(process.env.REACT_APP_API_REST_URL + '/manage', {
            method: 'put',
            headers: new Headers({
                'Authorization': 'Bearer ' + token,
            }),
        })).status
        if (result == 200) {
            positive("Update Successful")
        } else {
            negative("Update not successful")
        }

    }
    return <>
        {contextHolder}
        <Divider />
        <Space direction="vertical">
            <Card title="Roles">
                <List dataSource={roles} renderItem={(item) => (<List.Item>{item}</List.Item>)} />
            </Card>
            {
                roles && roles.includes("Insights.Dev") &&
                <>
                    <Card title="Update Data">
                        <Button onClick={handleUpdateData}>Update</Button>
                    </Card>
                    <Card title="Version">
                        <pre>
                            {apiResult && JSON.stringify(apiResult, null, 2)}
                        </pre>
                    </Card>
                    <Card title="Machines">
                        <pre>
                            {machinesResult && JSON.stringify(machinesResult, null, 2)}
                        </pre>
                    </Card></>
            }
        </Space>
    </>
}