import { Descriptions, Popover, Space, Statistic, Tooltip } from "antd"
import React, { useEffect, useState } from "react"
import { gql } from "../../../__generated__/gql"
import { IdNameType_1, LineConnectionStatusEnumType, LineMachineType, MachineStatusType } from "../../../__generated__/graphql"
import { soudronicGreen, soudronicRaspberry } from "../../../constants/colors"

import { InfoCircleFilled } from "@ant-design/icons"
import { NavigationButton } from "../../Components/NavigationButton"

export const getColorForState = (stateName?: MachineStatusType): string => {
    switch (stateName) {
        case MachineStatusType.Error:
            return "red"
        case MachineStatusType.LineStop:
            return "yellow"
        case MachineStatusType.On:
            return "green"
        case MachineStatusType.Off:
            return "lightGray"
        case MachineStatusType.LackOfMaterial:
            return "orange"
        default:
            return "gray"
    }
}

export const getLightColorForState = (stateName?: MachineStatusType): string => {
    switch (stateName) {
        case MachineStatusType.Error:
            return "#ff000078"
        case MachineStatusType.LineStop:
            return "#ffff009e"
        case MachineStatusType.On:
            return "#0080008a"
        case MachineStatusType.Off:
            return "lightGray"
        case MachineStatusType.LackOfMaterial:
            return "#ffa500a3"
        default:
            return "gray"
    }
}

export const GET_LAST_CONNECTED_QUERY = gql(`
    query lastConnectd($deviceId: String!) {
        lastConnected(deviceId: $deviceId)
    }
`)

export const LineMachineStatus = ({ machineId, connected, machine }: { machineId?: IdNameType_1; connected?: LineConnectionStatusEnumType | null; machine: LineMachineType | null | undefined }) => {
    const picturepath = `/images/LineMachines/${machine?.viewDefinitionId ?? "missingImage"}.svg`
    const picture = <img style={{ maxWidth: "100%", maxHeight: "100%", verticalAlign: "middle" }} src={picturepath} alt={picturepath} />

    const mcIsConnected = connected === LineConnectionStatusEnumType.Connected

    const [backgroundColor, setBackgroundColor] = useState("gray")
    useEffect(() => {
        setBackgroundColor(mcIsConnected ? getLightColorForState(machine?.status?.status ?? MachineStatusType.Unknown) : "gray")
    }, [machine?.status?.status, mcIsConnected])

    const state = <Statistic title="Machine State" value={machine?.status?.status ?? MachineStatusType.Unknown} />

    const onlineState = (
        <>
            {machine?.error?.errorText ? (
                <Tooltip placement="right" color={soudronicRaspberry} title={machine?.error?.errorText}>
                    {state}
                </Tooltip>
            ) : (
                state
            )}
            {machine?.status && <Statistic title="Since" value={new Date(machine.status.since).toLocaleTimeString() ?? "-"} />}
        </>
    )

    const offlineState = (
        <>
            <Statistic title="Status" value={"Offline"} />
        </>
    )
    const popoverInfos = (mc: string) => (
        <>
            <Space direction="vertical">
                <Descriptions style={{ width: 300 }} bordered column={1} title={mc} items={[{ key: "name", label: "Name", children: mc }]} />
                <NavigationButton text="Details" />
            </Space>
        </>
    )
    const subMachinesList = ["Inside Coating", "Outside Coating", "Oven", "Massconveyor", "Turn Table"]
    const subMachines = (
        <>
            <Space direction="horizontal" wrap>
                {subMachinesList.map((mc, i) => (
                    <Popover key={mc} content={popoverInfos(mc)}>
                        <InfoCircleFilled style={{ fontSize: 18, color: i % 2 == 0 ? getColorForState(MachineStatusType.On) : getColorForState(MachineStatusType.LackOfMaterial) }} />
                    </Popover>
                ))}
            </Space>
        </>
    )

    return (
        <>
            {machineId?.id == "welder" && false && subMachines}
            {<div style={{ height: 180, alignItems: "center", display: "flex", justifyContent: "center" }}>{picture}</div>}
            <div style={{ backgroundColor: backgroundColor, position: "absolute", bottom: 0, left: 0, right: 0, height: 80 }}>
                <Space style={{ marginLeft: 10, bottom: 5, position: "absolute" }} direction="horizontal" size={"large"}>
                    {mcIsConnected ? onlineState : offlineState}
                </Space>
            </div>
        </>
    )
}
