import { Popover, Space, Statistic } from "antd";
import React from "react";
import { GetLineDataQuery, LineConnectionStatusEnumType } from "../../../__generated__/graphql";
import { CaretUpOutlined, CaretDownOutlined } from "@ant-design/icons";

export const getColorForEfficiency = (efficiency: number | null | undefined, mcIsConnected: boolean): string => {
    if (!mcIsConnected) {
        return "gray";
    }
    if (!efficiency) {
        return "gray";
    }
    if (efficiency < 30) {
        return "#ff000078";
    }
    if (efficiency < 70) {
        return "#ffff009e";
    }

    return "#0080008a";
};

export const LineEfficiencyWidget = ({ machineId, connected, lineData }: { machineId?: string; connected?: LineConnectionStatusEnumType | null; lineData: GetLineDataQuery | undefined }) => {
    const mcIsConnected = connected === LineConnectionStatusEnumType.Connected;
    const trendContent = <Statistic title="Difference" value={lineData?.line?.efficiency?.trend ?? 0} suffix="%" />;

    const onlineState = (
        <>
            <Space direction="horizontal">
                {lineData && lineData.line && <Statistic title={"Efficiency"} value={lineData.line.efficiency?.efficiency ?? "-"} suffix="%" />}
                <Popover content={trendContent} title={"Trend"}>
                    {(lineData?.line?.efficiency?.trend ?? 0) > 0 ? <CaretUpOutlined /> : <CaretDownOutlined />}
                </Popover>
            </Space>
        </>
    );
    const offlineState = <Statistic title="Status" value={"Offline"} />;

    return (
        <>
            {lineData && lineData.line && (
                <>
                    <Statistic title={"Produced Units"} value={lineData.line.efficiency?.producedUnits} />
                    <Statistic title={"Possible Units"} value={lineData.line.efficiency?.possibleUnits} />
                </>
            )}
            <div style={{ backgroundColor: getColorForEfficiency(lineData?.line?.efficiency?.efficiency, mcIsConnected), position: "absolute", bottom: 0, left: 0, right: 0, height: 80 }}>
                <Space style={{ marginLeft: 10, bottom: 5, position: "absolute" }} direction="horizontal" size={"large"}>
                    {mcIsConnected ? onlineState : offlineState}
                </Space>
            </div>
        </>
    );
};

