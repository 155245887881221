import { useQuery } from "@apollo/client"
import { ActiveElement, Chart, ChartData, ChartEvent, TooltipItem } from "chart.js"
import React, { useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { gql } from "../../__generated__/gql"
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels"
import { contrastColors, soudronicGold } from "../../constants/colors"
import { secondsToDHHMM, secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import { Alert, Button, Card, Col, Descriptions, Divider, Input, List, Modal, Row, Space, Typography } from "antd"
import { DowncauseByErrorType, MachineDowntimePartType, MachineStatusType } from "../../__generated__/graphql"
import { getColorForState } from "../Dashboard/Widgets/MachineState"
import { LineDowncauseTable } from "./LineDowncauseTable"
import Table, { ColumnsType } from "antd/es/table"
import { t } from "i18next"
import { useRecoilValue } from "recoil"
import { selectedTimeSelector } from "../../recoil/atom.time"
const { Title, Text } = Typography

import { MonitorOutlined } from "@ant-design/icons"
import { DownloadReport } from "../Components/ReportDownload"

export const DOWNTIME_DETAIL = gql(`
query DowntimeDetailQuery($deviceId: String!, $begin: DateTime, $end: DateTime){
downtimeStatistic(deviceId:$deviceId, begin: $begin, end: $end){
    lineId
    totalDowntimeDuration
     downCausesByReason{
      reason
      totalDuration
      percentage
    }
    downCausesByState{
      state
      totalDuration
      percentage
    }
    machineDowntimes{
      deviceId
      totalDuration
      percentage
      downCausesByError{
        errorId
        errorText
        counter
        totalDuration
        percentage
      }
      downCausesByReason{
        reason
        totalDuration
        percentage
      }
      downCausesByState{
        state
        totalDuration
        percentage
      }
      downCauses{
        reason
        machineId
        error{
          errorId
          errorText
        }
        state
        begin
        end
        duration
      }
    }
  }
  }
`)

export const LineDowncauseDetails = ({ lineId }: { lineId: string }) => {
    const { data } = useQuery(DOWNTIME_DETAIL, { variables: { deviceId: lineId ?? "" } })
    const [chartData, setChartData] = useState<ChartData<"doughnut", number[], string>>({ labels: [], datasets: [] })
    const [lineCauseChartData, setLineCauseChartData] = useState<ChartData<"doughnut", number[], string>>({ labels: [], datasets: [] })
    const [machineStateChartData, setMachineStateChartData] = useState<ChartData<"doughnut", number[], string>>({ labels: [], datasets: [] })
    const [lineStateChartData, setLineStateChartData] = useState<ChartData<"doughnut", number[], string>>({ labels: [], datasets: [] })
    const [selectedMachine, setSelectedMachine] = useState("")
    const [selectedMachineData, setSelectedMachineData] = useState<MachineDowntimePartType | undefined>()
    const timeSettings = useRecoilValue(selectedTimeSelector)

    const [isModalOpen, setIsModalOpen] = useState(false)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (data && data.downtimeStatistic) {
            const labels = data.downtimeStatistic.machineDowntimes.map((mc) => mc?.deviceId)
            const values = data.downtimeStatistic.machineDowntimes.map((mc) => mc.totalDuration as number)
            setChartData({ labels: labels, datasets: [{ data: values, borderRadius: 5 }] })

            if (data.downtimeStatistic.downCausesByState) {
                const stateLabels = data.downtimeStatistic.downCausesByState.map((mc) => mc?.state ?? MachineStatusType.Unknown)
                const backgroundColor = stateLabels.map((state) => getColorForState(state))
                const stateValues = data.downtimeStatistic.downCausesByState.map((mc) => mc?.totalDuration as number)
                setLineStateChartData({ labels: stateLabels, datasets: [{ data: stateValues, borderRadius: 5, backgroundColor: backgroundColor }] })
            }
            if (data.downtimeStatistic.downCausesByReason) {
                const labels = data.downtimeStatistic.downCausesByReason.map((mc) => (mc?.reason ?? "").toString())
                const values = data.downtimeStatistic.downCausesByReason.map((mc) => mc?.totalDuration as number)
                const backgroundColor = labels.map((label) => (label === "MACHINE" ? "#059bff" : getColorForState(MachineStatusType.LineStop)))
                setLineCauseChartData({ labels: labels, datasets: [{ data: values, borderRadius: 5, backgroundColor: backgroundColor }] })
            }
        }
    }, [data])

    useEffect(() => {
        if (data && data.downtimeStatistic && selectedMachine) {
            const mcDowntime = data.downtimeStatistic.machineDowntimes.find((mc) => mc.deviceId == selectedMachine)
            if (mcDowntime) {
                setSelectedMachineData(mcDowntime)
                const stateLabels = mcDowntime.downCausesByState.map((mc) => mc?.state ?? MachineStatusType.Unknown)
                const backgroundColor = stateLabels.map((state) => getColorForState(state))
                const stateValues = mcDowntime.downCausesByState.map((mc) => mc?.totalDuration as number)
                setMachineStateChartData({ labels: stateLabels, datasets: [{ data: stateValues, borderRadius: 5, backgroundColor: backgroundColor }] })
            }
        }
    }, [selectedMachine])

    const handleOnClick = (_event: ChartEvent, elements: ActiveElement[], _chart: Chart): void => {
        if (elements.length > 0 && chartData.labels) {
            const label = chartData.labels[elements[0].index]
            console.log(label)
            setSelectedMachine(label)
        }
    }

    const columns: ColumnsType<DowncauseByErrorType> = [
        {
            title: t("Percentage"),
            dataIndex: "percentage",
            render: (value) => Math.round(value * 100 * 1000) / 1000 + "%",
            sorter: (a, b) => a?.percentage ?? 0 - (b?.percentage ?? 0),
        },
        {
            title: t("Count"),
            dataIndex: "counter",
            sorter: (a, b) => a?.counter ?? 0 - (b?.counter ?? 0),
        },
        {
            title: t("topErrorTable.duration"),
            dataIndex: "totalDuration",
            sorter: (a, b) => a.totalDuration - b.totalDuration,
            render: (value) => secondsToDHHMMSS(value),
        },
        {
            title: t("errorId"),
            dataIndex: "error",
            responsive: ["md"],
            render: (_value, record) => record?.errorId,
        },
        {
            title: t("errorText"),
            dataIndex: "error",
            responsive: ["md"],
            render: (_value, record) => record?.errorText ?? "",
        },
    ]

    const downloadButton = (
        <>
            <DownloadReport begin={timeSettings.start} end={timeSettings.stop} deviceId={lineId} typeOfReport={"Downtimes"} name="Default" data={data?.downtimeStatistic?.machineDowntimes} />
        </>
    )

    const helpItems = ["test", "test2"]

    const infoPopover = (
        <Space direction="vertical">
            <p>
                <Text>The most frequent downtime was cause by </Text>
                <Text strong>{data?.downtimeStatistic?.machineDowntimes[0].deviceId.toUpperCase()}</Text>
                <Text> with fault: </Text>
                <Text code>{data?.downtimeStatistic?.machineDowntimes[0].downCausesByError[0]?.errorText ?? ""}</Text>
                <Text> total downtime because of this fault is </Text>
                <Text strong>{secondsToDHHMM(data?.downtimeStatistic?.machineDowntimes[0].downCausesByError[0]?.totalDuration)}h</Text>
                <Text> and occurred </Text>
                <Text strong>{data?.downtimeStatistic?.machineDowntimes[0].downCausesByError[0]?.counter}</Text>
                <Text> times.</Text>
            </p>
            <List
                header={<div>How to fix this fault</div>}
                footer={<div>More Information: </div>}
                bordered
                dataSource={helpItems}
                renderItem={(item) => (
                    <List.Item>
                        <Typography.Text mark>[ITEM]</Typography.Text> {item}
                    </List.Item>
                )}
            />
            <Space.Compact style={{ width: "100%" }}>
                <Input placeholder="Ask me something..." />
                <Button type="primary">Submit</Button>
            </Space.Compact>
        </Space>
    )

    const extraView = (
        <Space direction="horizontal">
            {downloadButton}
            {
                <Button icon={<MonitorOutlined />} style={{ color: "white", backgroundColor: soudronicGold }} onClick={showModal}>
                    Help
                </Button>
            }
        </Space>
    )

    return (
        <>
            <Card style={{ alignItems: "center", cursor: "default" }} extra={extraView} hoverable title={`Downtime Statistic `}>
                <Space style={{ width: "100%" }} direction="vertical">
                    <Title level={5}>Statistic Line</Title>
                    <Descriptions column={2}>
                        <Descriptions.Item label={"Total Downtime Duration"}>{secondsToDHHMMSS(data?.downtimeStatistic?.totalDowntimeDuration)}h</Descriptions.Item>
                        <Descriptions.Item label={"Selected Duration"}>{secondsToDHHMMSS(timeSettings.duration / 1000)}h</Descriptions.Item>
                    </Descriptions>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xl={8} sm={24}>
                            <DowncauseChart chartData={chartData} eventHandler={handleOnClick} />
                        </Col>
                        <Col xl={8} sm={24}>
                            <div style={{ height: 250 }}>{data?.downtimeStatistic && <DowncauseChart chartData={lineCauseChartData} />}</div>
                        </Col>
                        <Col xl={8} sm={24}>
                            <div style={{ height: 250 }}>{data?.downtimeStatistic && <DowncauseChart chartData={lineStateChartData} />}</div>
                        </Col>
                    </Row>
                    <Divider orientation="center" />
                    <Title level={5}>{`Details ${selectedMachine.toUpperCase()}`}</Title>
                    {!selectedMachine && <Alert type="info" message={"Choose Machine in chart"} />}
                    {selectedMachineData && (
                        <>
                            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                                <Col xl={8} sm={24}>
                                    <Descriptions column={1}>
                                        <Descriptions.Item label={`Total Downtime`}>{secondsToDHHMMSS(selectedMachineData.totalDuration)}h</Descriptions.Item>
                                        {selectedMachineData.downCausesByState.map((dcByState) => (
                                            <Descriptions.Item key={dcByState.state} label={`Downtime Machine was in ${dcByState.state}`}>
                                                {secondsToDHHMMSS(dcByState.totalDuration)}h
                                            </Descriptions.Item>
                                        ))}
                                    </Descriptions>
                                </Col>
                                <Col xl={8} sm={24}>
                                    <div style={{ height: 250 }}>{selectedMachineData && <DowncauseChart chartData={machineStateChartData} />}</div>
                                </Col>
                            </Row>

                            <Title level={5}>Downcauses Top List</Title>
                            <Table columns={columns} dataSource={selectedMachineData?.downCausesByError as DowncauseByErrorType[]} rowKey="errorId" />
                            <Title level={5}>Downcauses History</Title>
                            <LineDowncauseTable data={selectedMachineData.downCauses} />
                        </>
                    )}
                </Space>
            </Card>
            <Modal title="How to improve the line?" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                {infoPopover}
            </Modal>
        </>
    )
}

export const DowncauseChart = ({
    chartData,
    eventHandler,
}: {
    chartData: ChartData<"doughnut", number[], string>
    eventHandler?: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => void
}) => {
    const overviewChart = (
        <Doughnut
            height={250}
            width={250}
            plugins={[ChartDataLabels as any]}
            options={{
                onClick: eventHandler ?? eventHandler,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: true,
                        labels: {
                            usePointStyle: true,
                            pointStyle: "rectRounded",
                        },
                    },
                    datalabels: {
                        color: (context: Context) => {
                            const index = context.dataIndex
                            let c = "black"
                            if (context.dataset.backgroundColor?.constructor === Array) {
                                c = context.dataset.backgroundColor ? context.dataset.backgroundColor[index] : "black"
                            }
                            return contrastColors[c]
                        },
                        formatter: (value, context: Context) => {
                            const total = context.dataset.data.filter((_, i) => context.chart.getDataVisibility(i)).reduce<number>((prev, actual) => prev + (actual as number), 0)
                            const percentage = Math.round((value / total) * 100)
                            if (percentage < 4) return ""
                            return percentage + "%"
                        },
                        font: {
                            weight: "bold",
                            size: 12,
                        },
                        anchor: "center",
                        display: "auto",
                        clip: false,
                        offset: 200,
                    },
                    tooltip: {
                        callbacks: {
                            label: (v: TooltipItem<"doughnut">) => `${secondsToDHHMMSS(parseInt(v.raw as any))} `,
                        },
                    },
                },
            }}
            data={chartData}
        />
    )
    return overviewChart
}
