import { useQuery } from "@apollo/client"
import { Table, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import React from "react"
import { useTranslation } from "react-i18next"
import { secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import { DownCauseType, MachineTopErrorType } from "../../__generated__/graphql"
import { GET_TOP_ERRORS } from "../Dashboard/Widgets/LineTopErrorWidget"

export const LineTopErrorTable = ({ lineId }: { lineId: string }) => {
  const { t } = useTranslation()
  const { loading, error, data } = useQuery(GET_TOP_ERRORS, { pollInterval: 120000, variables: { deviceId: lineId ?? "", take: 9999 } })
  data?.topErrorLine?.topMachineErrors

  const columns: ColumnsType<MachineTopErrorType> = [
    {
      title: t("machine"),
      dataIndex: "machineId",
    },
    {
      title: t("count"),
      dataIndex: "count",
      sorter: (a, b) => a.count - b.count,
    },
    {
      title: t("topErrorTable.duration"),
      dataIndex: "totalTime",
      sorter: (a, b) => a.totalTime - b.totalTime,
      render: (value) => secondsToDHHMMSS(value),
    },
    {
      title: t("errorId"),
      dataIndex: "error",
      responsive: ["md"],
      render: (_value, record) => record.error?.errorId,
    },
    {
      title: t("errorText"),
      dataIndex: "error",
      responsive: ["md"],
      render: (_value, record) => record.error?.errorText,
    },
  ]
  if (!data) return <p>Loading</p>
  return <>{data && <Table columns={columns} dataSource={data.topErrorLine?.topMachineErrors as MachineTopErrorType[]} rowKey={(record) => record.machineId + record.error?.errorId} />}</>
}
