import { useApolloClient, useQuery } from "@apollo/client"
import { Alert, Divider, Select, Space, Switch, TreeSelect } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import { useRecoilState } from "recoil"
import { GET_LINES_OVERVIEW } from "../../graphql/requests/LineRequests"
import { filterOnlineMachinesState, filterState, selectedFilterListState, selectedMachineTypesState } from "../../recoil/atoms.application"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { GeneralInfos } from "./GeneralInfos"
import { MachineDashboard } from "./MachineDashboard"
import { gql } from "../../__generated__"
import { groupBy } from "../../helpers/helpers"
import { DefaultOptionType, SelectProps } from "antd/es/select"
import { DelayedLoader } from "../Components/DelayedLoader"
import { useWindowScrollPosition } from "../../helpers/useWindowScrollPosition"
import { LineDashboard } from "./LineDashboard"
import { InstanceType } from "../../recoil/atoms.application"

const MACHINE_CONNECTION_SUB = gql(`
subscription deviceConnection($deviceId: String!){
  onLineConnection(deviceId: $deviceId){
    deviceId
    value{
      status
      color
      changed
    }
  }
}
`)

interface CustomOptionType extends DefaultOptionType {
    type: InstanceType
}

export const Dashboard = () => {
    const [filterOnline, setFilterOnline] = useRecoilState(filterOnlineMachinesState)

    const [connectedLinesCount, setConnectedLinesCount] = useState(0)

    const [originalFilterList, setOriginalFilterList] = useState<string[]>([])

    const [machineTypes, setMachineTypes] = useState<SelectProps["options"]>([])
    const [selectedMachineTypes, SetSelectedMachineTypes] = useRecoilState(selectedMachineTypesState)
    const [machineTypeList, setMachineTypeList] = useState<Record<string, string>>({})
    const [initialized, setInitialized] = useState(false)

    const { data } = useQuery(GET_LINES_OVERVIEW)
    const [connectionStatusMachines, setConnectionStatusMachines] = useState<Record<string, boolean>>({})

    const [value, setValue] = useRecoilState(selectedFilterListState)
    const [filters, setFilters] = useRecoilState(filterState) //const [filters, setFilters] = useState<{ [key in InstanceType]: string[] }>({ CUSTOMER: [], FACTORY: [], LINE: [], MACHINE: [] })
    const [treeData, setTreeData] = useState<CustomOptionType[]>([])

    useWindowScrollPosition("dashboard-pos", initialized)
    const apolloClient = useApolloClient()

    useEffect(() => {
        const connectedMachines = Object.values(connectionStatusMachines).filter((value) => value).length
        setConnectedLinesCount(connectedMachines)
    }, [connectionStatusMachines])

    const filterListMemo = useMemo(() => {
        if (data && data.lines) {
            const customerSelected = data.lines.filter((l) => filters.CUSTOMER.includes(l?.customer ?? "")).map((l) => l?.deviceId ?? "")
            const machinesSelected = data.lines.filter((l) => filters.MACHINE.includes(l?.deviceId ?? "")).map((l) => l?.deviceId ?? "")
            const distinctList = [...new Set([...customerSelected, ...machinesSelected])]

            let baseData = distinctList.length > 0 ? distinctList : originalFilterList

            if (filterOnline) {
                baseData = baseData.filter((id) => connectionStatusMachines[id])
            }
            if (selectedMachineTypes.length > 0) {
                baseData = baseData.filter((id) => selectedMachineTypes.includes(machineTypeList[id]))
            }

            return baseData
        }
        return []
    }, [filters, data, filterOnline, originalFilterList, selectedMachineTypes, connectionStatusMachines])

    const onChange = (value: string[]) => {
        setValue(value)
    }
    const onSelect = (_value: string, node: CustomOptionType) => {
        const filterValue = node.value as string
        switch (node.type) {
            case InstanceType.Customer:
                setFilters((prev) => ({ ...prev, CUSTOMER: [...prev.CUSTOMER, filterValue] }))
                break
            case InstanceType.Machine:
                setFilters((prev) => ({ ...prev, MACHINE: [...prev.MACHINE, filterValue] }))
                break
            default:
                break
        }
    }
    const onClear = () => {
        setFilters({ CUSTOMER: [], FACTORY: [], LINE: [], MACHINE: [] })
    }

    const onDeselect = (_value: string, node: CustomOptionType) => {
        const filterValue = node.value as string
        switch (node.type) {
            case InstanceType.Customer:
                setFilters((prev) => ({ ...prev, CUSTOMER: prev.CUSTOMER.filter((c) => c !== filterValue) }))
                break
            case InstanceType.Machine:
                setFilters((prev) => ({ ...prev, MACHINE: prev.MACHINE.filter((m) => m !== filterValue) }))
                break
            default:
                break
        }
    }

    const handleFilterOnlineChanged = () => {
        setFilterOnline(!filterOnline)
    }

    const handleMachineTypeSelect = (values: string[]) => {
        SetSelectedMachineTypes(values)
    }

    useEffect(() => {
        if (data && data.lines) {
            const groupedList = groupBy(data.lines, (l) => l?.customer)
            const listData: CustomOptionType[] = []
            for (const key in groupedList) {
                const machines: CustomOptionType[] = groupedList[key].map((m) => ({ label: "⚙ " + m?.name, value: m?.deviceId, type: InstanceType.Machine }))
                const keyValue = key == "null" ? "No Customer" : key
                const customer: CustomOptionType = {
                    label: "🏭 " + keyValue,
                    value: key,
                    children: machines,
                    type: InstanceType.Customer,
                }
                listData.push(customer)
            }
            setTreeData(listData)

            const allMachineIds = data.lines.map((l) => l?.deviceId ?? "")
            setOriginalFilterList(allMachineIds)
            const initalList = data.lines.filter((l) => l?.lineConnection?.status == LineConnectionStatusEnumType.Connected).map((l) => l?.deviceId ?? "")
            setConnectedLinesCount(initalList.length)

            const machineTypesGrouped = groupBy(data.lines, (l) => l?.machineType)
            const mcTypes: SelectProps["options"] = []
            for (const key in machineTypesGrouped) {
                if (key !== "null") {
                    mcTypes.push({ label: "⚙️ " + key, value: key })
                }
            }
            setMachineTypes(mcTypes)

            const status: Record<string, boolean> = {}
            const machineTypeListLocal: Record<string, string> = {}
            data?.lines?.forEach((l) => {
                const id = l?.deviceId ?? ""
                status[id] = l?.lineConnection?.status === LineConnectionStatusEnumType.Connected
                machineTypeListLocal[id] = l?.machineType ?? "null"
                apolloClient.subscribe({ query: MACHINE_CONNECTION_SUB, variables: { deviceId: id } }).subscribe({
                    next: (data) => {
                        const id = data.data?.onLineConnection?.deviceId ?? ""
                        const connectionStatus = data.data?.onLineConnection?.value?.status
                        setConnectionStatusMachines((prev) => {
                            const updated = { ...prev }
                            updated[id] = connectionStatus === LineConnectionStatusEnumType.Connected
                            return updated
                        })
                    },
                })
            })
            setConnectionStatusMachines(status)
            setMachineTypeList(machineTypeListLocal)
            setInitialized(true)
        }
    }, [data])

    return (
        <>
            <Divider />
            <Space direction="vertical" size="middle" style={{ display: "flex" }}>
                <GeneralInfos connectedLines={connectedLinesCount} totalMachines={data?.lines?.length} />
                <div style={{ width: "100%", alignItems: "center", justifyContent: "right", display: "flex" }}>
                    <Space wrap direction="horizontal">
                        <Switch checkedChildren="Online" unCheckedChildren="All" checked={filterOnline} onChange={handleFilterOnlineChanged} title="Edit" />
                        <Divider type="vertical" />
                        <Select
                            mode="multiple"
                            allowClear
                            style={{ width: 200 }}
                            placeholder="Filter Machine Type"
                            value={selectedMachineTypes}
                            onChange={handleMachineTypeSelect}
                            options={machineTypes}
                        />
                        <TreeSelect
                            showSearch
                            style={{ width: 300 }}
                            value={value}
                            autoClearSearchValue
                            dropdownStyle={{ maxHeight: 300, overflow: "auto" }}
                            placeholder="Select Machine"
                            allowClear
                            multiple
                            onClear={onClear}
                            onChange={onChange}
                            treeNodeFilterProp={"label"}
                            onSelect={onSelect}
                            onDeselect={onDeselect}
                            treeData={treeData}
                        />
                    </Space>
                </div>
                {data?.lines &&
                    data.lines.map((m, i) =>
                        m?.deviceId.endsWith("ST") ? (
                            <LineDashboard key={m.deviceId} hide={!filterListMemo.includes(m?.deviceId ?? "")} lineId={m.deviceId} />
                        ) : (
                            <MachineDashboard hide={!filterListMemo.includes(m?.deviceId ?? "")} key={i} machineId={m?.deviceId} />
                        ),
                    )}
                {filterListMemo.length == 0 && (
                    <DelayedLoader>
                        <Alert message="Warning" description="No machine with selected filter" type="warning" showIcon />
                    </DelayedLoader>
                )}
            </Space>
        </>
    )
}
