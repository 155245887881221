import { useQuery } from "@apollo/client"
import { Card, Col, Descriptions, Row, Space, Typography } from "antd"
import Table, { ColumnsType } from "antd/es/table"
import Column from "antd/es/table/Column"
import { t } from "i18next"
import React from "react"
import { useParams } from "react-router-dom"
import { gql } from "../../__generated__/gql"
import { IdNameType_1, ShiftType } from "../../__generated__/graphql"
import { getWeekdayFromNumner, weekdays } from "../Components/ShiftsEditor"

const { Title } = Typography

export const LINE_GENERAL_INFOS_QUERY = gql(`
query LineGeneralInfos($deviceId: String!){
    line(deviceId: $deviceId){
        shiftDefinitions {
      definitions {
        id
        name
        begin
        end
        days
      }
    }
    customer
    name
    project {
      customer
      number
      timezone
    }
    recipe {
      name
    }
    productionPath {
      id
      name
      machines {
        id
        name
      }
    }
    settings {
      lineName
      masterMachineId
    }

    configuration {
      machines {
        id
        name
      }
    }
  }
  }
`)

export const LineGeneralPage = () => {
  const params = useParams()
  const lineId = params.lineId ?? ""
  const { loading, error, data, refetch } = useQuery(LINE_GENERAL_INFOS_QUERY, { variables: { deviceId: lineId ?? "" } })

  const infos = (
    <Card hoverable style={{ cursor: "default" }}>
      <Descriptions title="General Infos">
        <Descriptions.Item label={t("Customer")}>{data?.line?.customer}</Descriptions.Item>
        <Descriptions.Item label={t("Timezone")}>{data?.line?.project?.timezone}</Descriptions.Item>
        <Descriptions.Item label={t("Project Number")}>{data?.line?.project?.number}</Descriptions.Item>
        <Descriptions.Item label={t("Line Name")}>{data?.line?.settings?.lineName}</Descriptions.Item>
        <Descriptions.Item label={t("Master Machine")}>{data?.line?.settings?.masterMachineId}</Descriptions.Item>
        <Descriptions.Item label={t("Recipe")}>{data?.line?.recipe?.name}</Descriptions.Item>
        <Descriptions.Item label={t("Production Path")}>{data?.line?.productionPath?.name}</Descriptions.Item>
        <Descriptions.Item label={t("Production Path Id")}>{data?.line?.productionPath?.id}</Descriptions.Item>
      </Descriptions>
    </Card>
  )

  const shiftColumns: ColumnsType<ShiftType> = [
    {
      title: t("Id"),
      dataIndex: "id",
    },
    {
      title: t("Name"),
      dataIndex: "name",
    },
    {
      title: t("begin"),
      dataIndex: "begin",
      render: (value) => value.substring(0, 8),
    },
    {
      title: t("end"),
      dataIndex: "end",
      render: (value) => value.substring(0, 8),
    },
    {
      title: t("Days"),
      dataIndex: "days",
      render: (value, record) => record.days.map((dayNumber) => getWeekdayFromNumner(dayNumber)).join(", "),
    },
  ]
  const productionPathColumns: ColumnsType<IdNameType_1> = [
    {
      title: t("Id"),
      dataIndex: "id",
    },
    {
      title: t("Name"),
      dataIndex: "name",
    },
  ]

  return (
    <Space direction="vertical" style={{ marginTop: 30 }} wrap>
      {infos}
      <Title level={3}>Shifts</Title>
      <Table style={{ overflowX: "scroll" }} columns={shiftColumns} dataSource={data?.line?.shiftDefinitions?.definitions} rowKey="id" pagination={false} />
      <Row gutter={12}>
        <Col xl={12} md={12}>
          <Title level={3}>{t("ProductionPath")}</Title>
          <Table size={"small"} style={{ overflowX: "scroll" }} columns={productionPathColumns} dataSource={data?.line?.productionPath?.machines as any} rowKey="id" pagination={false} />
        </Col>
        <Col xl={12} md={12}>
          <Title level={3}>{t("All Machines")}</Title>
          <Table size={"small"} columns={productionPathColumns} dataSource={data?.line?.configuration?.machines as any} rowKey="id" pagination={false} />
        </Col>
      </Row>
    </Space>
  )
}
