import { useQuery } from "@apollo/client"
import { Button, Card, Col, message, Row, Space, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { soudronicAnthracite } from "../../constants/colors"
import { machineExistSelector } from "../../recoil/atom.machines"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { gql } from "../../__generated__/gql"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { DownloadReport } from "../Components/ReportDownload"
import { TimeEditor } from "../Components/TimeEdit"
import { MachineCounterWidget } from "../Dashboard/Widgets/MachineCounterWidget"
import { MemoCounter } from "../Timelines/CounterChart"
import { Oee } from "./Oee"

const { Title } = Typography

const GET_COUNTER_HIST = gql(`
query GetCounterHistory($deviceId: String!, $begin: DateTime, $end: DateTime){
  machineCounterHistory(deviceId: $deviceId, begin: $begin, end: $end){
    eventDuration
    startTimestamp
    endTimestamp
    machineId
    points
  }
}    
`)

export const MachineCounterPage = () => {
    const { t } = useTranslation()

    const params = useParams()
    const machineId = params.machineId ?? ""
    const timeSettings = useRecoilValue(selectedTimeSelector)

    const { loading, error, data, refetch } = useQuery(GET_COUNTER_HIST, { variables: { deviceId: machineId ?? "", begin: timeSettings.start.toISOString(), end: timeSettings.stop.toISOString() } })

    const handleRefetch = () => {
        refetch()
        success()
    }
    const [messageApi, contextHolder] = message.useMessage()
    const success = () => {
        messageApi.open({
            type: "success",
            content: "Updated Data",
        })
    }

    const updateComponent = (
        <Space wrap>
            <Button onClick={() => handleRefetch()} style={{ backgroundColor: soudronicAnthracite, color: "white" }}>
                {t("updateData")}
            </Button>
            <DownloadReport begin={timeSettings.start} end={timeSettings.stop} deviceId={machineId} typeOfReport={"Errors"} name="Default" />
        </Space>
    )

    const machineExists = useRecoilValue(machineExistSelector(machineId))

    if (!machineExists) {
        return (
            <Card style={{ marginTop: 20, cursor: "default" }} title="No Machine">
                <p>No machine with id: {machineId}</p>
            </Card>
        )
    }

    return (
        <Space size={"middle"} direction="vertical" style={{ display: "flex" }}>
            <div />
            {contextHolder}
            <Title level={2}>{t("machineEffectiveness.title")}</Title>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xl={8} sm={24}>
                    <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Time">
                        <div style={{ height: "100%", width: "100%" }}>
                            {" "}
                            {/*  justifyContent: "center", display: "flex", */}
                            <TimeEditor machineId={machineId} />
                        </div>
                    </Card>
                </Col>
                <Col xl={16} sm={24}>
                    <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Current Status">
                        <div style={{ height: "100%", width: "100%" }}>
                            {" "}
                            {/*  justifyContent: "center", display: "flex", */}
                            <MachineCounterWidget machineId={machineId} connected={LineConnectionStatusEnumType.Connected} showOee={false} />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Card style={{ alignItems: "center", cursor: "default" }} hoverable title="OEE" extra={updateComponent}>
                <Oee machineId={machineId} />
            </Card>
            <Card style={{ alignItems: "center", cursor: "default" }} hoverable title="Production History" extra={updateComponent}>
                <MemoCounter data={data} />
            </Card>
        </Space>
    )
}
