import { Badge, Descriptions, Progress, Space, Statistic, Tooltip } from "antd"
import React from "react"
import { GetLineDataQuery, LineConnectionStatusEnumType, LineModeEnumType, LineStatusEnumType, MachineStatusType } from "../../../__generated__/graphql"
import { useRecoilValue } from "recoil"
import { machineSelector } from "../../../recoil/atom.machines"
import { soudronicRaspberry } from "../../../constants/colors"

export const getColorForState = (stateName?: MachineStatusType): string => {
    switch (stateName) {
        case MachineStatusType.Error:
            return "red"
        case MachineStatusType.LineStop:
            return "yellow"
        case MachineStatusType.On:
            return "green"
        case MachineStatusType.Off:
            return "lightGray"
        case MachineStatusType.LackOfMaterial:
            return "orange"
        default:
            return "gray"
    }
}

export const getLightColorForState = (stateName?: LineStatusEnumType): string => {
    switch (stateName) {
        case LineStatusEnumType.Error:
            return "#ff000078"
        case LineStatusEnumType.LinePileUp:
            return "#ffff009e"
        case LineStatusEnumType.On:
            return "#0080008a"
        case LineStatusEnumType.Off:
            return "lightGray"
        case LineStatusEnumType.LackOfMaterial:
            return "#ffa500a3"
        default:
            return "gray"
    }
}

export const LineState = ({ machineId, connected, lineData }: { machineId?: string; connected?: LineConnectionStatusEnumType | null; lineData: GetLineDataQuery | undefined }) => {
    const machine = useRecoilValue(machineSelector(machineId))

    const picturepath = "/images/line.png"
    const picture = <img style={{ maxWidth: "100%", maxHeight: "100%", verticalAlign: "middle" }} src={picturepath} alt={picturepath} />

    const mcIsConnected = connected === LineConnectionStatusEnumType.Connected
    const backgroundColor = mcIsConnected ? getLightColorForState(lineData?.line?.state?.status ?? LineStatusEnumType.Unknown) : "gray"

    const onlineState = (
        <>
            {machine?.error?.errorText ? (
                <Tooltip placement="right" color={soudronicRaspberry} title={machine?.error?.errorText}>
                    <Statistic title="Line State" value={lineData?.line?.state?.status ?? MachineStatusType.Unknown} />
                </Tooltip>
            ) : (
                <Statistic title="Line State" value={lineData?.line?.state?.status ?? MachineStatusType.Unknown} />
            )}
            {lineData?.line?.state?.status && <Statistic title="Since" value={new Date(lineData.line.state.changed).toLocaleTimeString()} />}
        </>
    )

    const offlineState = <Statistic title="Status" value={"Offline"} />

    return (
        <>
            {lineData && lineData.line && (
                <Descriptions column={1} size="small">
                    <Descriptions.Item label="Mode">{lineData.line.mode?.mode}</Descriptions.Item>
                    <Descriptions.Item label="Line Recipe"> {lineData.line.recipe?.name}</Descriptions.Item>
                    <Descriptions.Item label="Shift"> {lineData.line.currentShift?.name}</Descriptions.Item>
                </Descriptions>
            )}
            <div style={{ backgroundColor: backgroundColor, position: "absolute", bottom: 0, left: 0, right: 0, height: 80 }}>
                <Space style={{ marginLeft: 10, bottom: 5, position: "absolute" }} direction="horizontal" size={"large"}>
                    {mcIsConnected ? onlineState : offlineState}
                </Space>
            </div>
        </>
    )
}
