import { atom, selector, selectorFamily } from "recoil"
import { gql } from "../__generated__/gql"
import { GetMachinesQuery, LineConnectionStatusEnumType } from "../__generated__/graphql"

export const searchFilterState = atom({
    key: "searchFilterState",
    default: "",
})

export const machinesState = atom({
    key: "machinesState",
    default: {} as GetMachinesQuery,
})

export const plannedProductionTime = atom({
    key: "plannedProductionTime",
    default: 0,
})

export const machineSelector = selectorFamily({
    key: "machineSelector",
    get:
        (machineId: string | undefined) =>
        ({ get }) => {
            const machines = get(machinesState)
            if (machines.machines) {
                const machine = machines.machines.find((m: any) => m?.id == machineId)
                return machine
            }
            return null
        },
})

export const machineExistSelector = selectorFamily({
    key: "machineExistsSelector",
    get:
        (machineId: string) =>
        ({ get }) => {
            const machines = get(machinesState)
            const found = machines.machines?.findIndex((m) => m?.id == machineId) ?? -1
            return found >= 0
        },
})
