export const getSecondsFromhhmmss = (value: string) => {
    if (value.toString().includes(":")) {
        var a = value.split(':'); // split it at the colons

        // minutes are worth 60 seconds. Hours are worth 60 minutes.
        var seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);
        return seconds
    }
    return Number.parseInt(value)
}

export const secondsToDHHMM = (secondsTotal?: number): string => {
    if (secondsTotal) {
        const hhmmss = secondsToDHHMMSS(secondsTotal)
        return hhmmss.substring(0, hhmmss.length - 3)

    }
    return ""
}


export const secondsToDHHMMSS = (secondsTotal?: number): string => {
    if (secondsTotal) {

        const days = Math.floor(secondsTotal / 60 / 60 / 24)
        const time = new Date(secondsTotal * 1000).toISOString().substring(11, 19)

        if (days > 0) {
            return days + 'd ' + time
        } else {
            return time
        }
    }
    return ""
}
