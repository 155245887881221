import { Button } from "antd"
import { SizeType } from "antd/es/config-provider/SizeContext"
import React from "react"
import { Link } from "react-router-dom"
import { soudronicAnthracite, soudronicAnthracite10, soudronicBlue, soudronicFontWhite } from "../../constants/colors"
import "./soudronic-button.css"


export const ActionButton = ({ text, alternative, size, onClick, disabled }: { text: string, alternative?: boolean, size?: SizeType, onClick?: () => void, disabled?: boolean }) => {
    let backgroundColor = alternative ? soudronicAnthracite : soudronicBlue
    if (disabled) backgroundColor = soudronicAnthracite10
    const button = <Button className="soudronic-button" onClick={onClick} disabled={disabled} size={size} style={{ backgroundColor: backgroundColor, color: soudronicFontWhite, whiteSpace: "pre-wrap", }}>
        {'\u0020'}{'\u0020'}{'\u2014'}{'\u0020'} {text}
    </Button>


    return (
        button
    )
}