import React, { useEffect } from "react"
import * as vis from "vis-timeline"
import { optionsTimelineStacked, optionsTimelineStackedRange } from "./Vis.Options"
import "./vis.css"
import "vis-timeline/dist/vis-timeline-graph2d.css"
import { ErrorHistQuery } from "../../__generated__/graphql"
import { useRecoilValue } from "recoil"
import { selectedTimeSelector } from "../../recoil/atom.time"

let timeline: vis.Timeline
var items: vis.DataItem[] = []

export const MachineErrosTimeline = ({ data, isLine }: { data: ErrorHistQuery | undefined; isLine: boolean }) => {
  const container = React.useRef(null) as any
  const timeSettings = useRecoilValue(selectedTimeSelector)

  useEffect(() => {
    if (container && container.current) {
      timeline = new vis.Timeline(container.current, items, isLine ? optionsTimelineStackedRange : optionsTimelineStacked)
    } else {
      console.error("Effect container.current undefined")
    }
  }, [])

  useEffect(() => {
    if (data && data.eventLog && timeline) {
      const groups: { [id: string]: string } = {}
      const machineGroups: { [id: string]: string[] } = {}
      const groupItems: vis.DataItem[] = []
      items = data.eventLog.map((d, i) => {
        const idGroup = d?.machineId ? d.machineId + d.errorId : d?.errorId ?? ""
        const start = new Date(d?.startTimestamp)
        const end = d?.endTimestamp ? new Date(d?.endTimestamp) : undefined
        const message = isLine ? d?.errorId ?? "" : ``
        if (d?.errorId) {
          groups[idGroup] = d?.errorText
          if (d.machineId) {
            machineGroups[d.machineId] = (machineGroups[d.machineId] ?? []).concat([idGroup])
          }
        }
        const tooltip = `${start.toLocaleString()} || Error: ${d?.errorText} || Error ID: ${d?.errorId}`
        const styleString = "background-color: " + "#ff6384" + ";"
        const item: vis.DataItem = { group: idGroup, id: i, start: start.toISOString(), end: end ? end.toISOString() : undefined, content: message, title: tooltip, style: styleString }
        groupItems.push({ group: d?.machineId, id: i + "group", start: start.toISOString(), end: end ? end.toISOString() : undefined, content: message, title: tooltip, style: styleString })
        return item
      })
      const background = {
        id: "A",
        content: "",
        start: timeSettings.start.toISOString(),
        end: timeSettings.stop.toISOString(),
        type: "background",
      }
      items.push(background)
      const options: vis.TimelineGroup[] = []

      for (let [key, value] of Object.entries(groups)) {
        options.push({ content: value, id: key, treeLevel: 2 } as any) // -> treeLevel not defined in interface
      }

      for (let [key, value] of Object.entries(machineGroups)) {
        options.push({ content: key, id: key, nestedGroups: value, showNested: false })
      }

      const updatedOptions = { min: timeSettings.start.add(-1, "hour").toISOString(), max: timeSettings.stop.add(1, "hour").toISOString() }
      timeline.setOptions(updatedOptions)

      timeline.setItems(items.concat(groupItems))
      timeline.setGroups(options)
      timeline.redraw()
      setTimeout(() => {
        timeline.setWindow(timeSettings.start.toDate(), timeSettings.stop.toDate())
      }, 100)
    }
  }, [data])

  return <div ref={container} />
}
