import { useQuery } from "@apollo/client"
import { Alert, Button, Card, Col, message, Row, Space, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { soudronicAnthracite } from "../../constants/colors"
import { machineExistSelector } from "../../recoil/atom.machines"
import { gql } from "../../__generated__/gql"
import { TimeEditor } from "../Components/TimeEdit"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { ScrollHint } from "./Helpers"
import LineMachineStatesTimeline from "../Timelines/LineMachineStatesTimeline"
import { DownloadReport } from "../Components/ReportDownload"

const { Title } = Typography

export const LINE_MACHINE_LOG = gql(`
query LineStatesLog($deviceId: String!, $begin: DateTime, $end: DateTime, $max: Int){
    lineStatesLog(deviceId: $deviceId, begin: $begin, end: $end, maxNumber: $max){
      machineId
      machineStatus
      changed
      errorText
      errorId
      end
      duration
    }
  }
`)

const MAX_ELEMENTS = 3000

export const LineMachineStatesPage = () => {
    const { t } = useTranslation()

    const params = useParams()
    const machineId = params.lineId ?? ""
    const timeSettings = useRecoilValue(selectedTimeSelector)

    const { loading, data, refetch } = useQuery(LINE_MACHINE_LOG, {
        variables: { deviceId: machineId ?? "", begin: timeSettings.start.startOf("minute").toISOString(), end: timeSettings.stop.startOf("minute").toISOString(), max: 1000 },
        fetchPolicy: "cache-first",
    })

    const handleRefetch = () => {
        refetch()
        success()
    }
    const [messageApi, contextHolder] = message.useMessage()
    const success = () => {
        messageApi.open({
            type: "success",
            content: "Updated Data",
        })
    }

    const updateComponent = (
        <Space wrap>
            <Button onClick={() => handleRefetch()} style={{ backgroundColor: soudronicAnthracite, color: "white" }}>
                {t("updateData")}
            </Button>
            <DownloadReport begin={timeSettings.start} end={timeSettings.stop} deviceId={machineId} typeOfReport={"Errors"} name="Default" />
        </Space>
    )

    const machineExists = useRecoilValue(machineExistSelector(machineId))

    if (!machineExists) {
        return (
            <Card style={{ marginTop: 20, cursor: "default" }} title="No Machine">
                <p>No machine with id: {machineId}</p>
            </Card>
        )
    }

    const maxElementHint = (
        <Alert
            showIcon
            type="warning"
            message={"The number of displayed elements is limited due to the selected time frame being too large. Please consider narrowing down your time range for more accurate results."}
        />
    )

    return (
        <Space size={"middle"} direction="vertical" style={{ display: "flex" }}>
            <div />
            {contextHolder}
            <Title level={2}>{t("MachineState.Title")}</Title>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xl={8} sm={24}>
                    <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Time">
                        <div style={{ height: "100%", width: "100%" }}>
                            {" "}
                            {/*  justifyContent: "center", display: "flex", */}
                            <TimeEditor machineId={machineId} defaultDuration={4} />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Card style={{ alignItems: "center", cursor: "default" }} hoverable title="Production History" extra={updateComponent}>
                <Space direction="vertical" style={{ display: "flex" }}>
                    {ScrollHint}
                    {data?.lineStatesLog?.length == MAX_ELEMENTS && maxElementHint}
                    {!loading && <LineMachineStatesTimeline data={data} />}
                </Space>
            </Card>
        </Space>
    )
}
