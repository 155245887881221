import { Button } from "antd"
import { SizeType } from "antd/es/config-provider/SizeContext"
import React from "react"
import { Link } from "react-router-dom"
import { soudronicAnthracite, soudronicBlue, soudronicFontWhite, soudronicGreen, soudronicOrange } from "../../constants/colors"
import "./soudronic-button.css"


export const SoudronicButtonGreen = ({ text, size, externalLink }: { text: string, size?: SizeType, externalLink: string }) => {

    const button = <Button className="soudronic-button-green" size={size} style={{ backgroundColor: soudronicGreen, color: soudronicFontWhite, whiteSpace: "pre-wrap", }}>
        {'\u0020'}{'\u0020'}{'\u2014'}{'\u0020'} {text}
    </Button>


    return (
        <Link target="_blank" to={externalLink} >
            {button}
        </Link >

    )
}