import { useQuery } from "@apollo/client"
import { Card, Col, Row, Space, Table, Tag, Typography } from "antd"
import { ColumnsType } from "antd/es/table"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { gql } from "../../__generated__/gql"
import { DownCauseType, MachineStatusType } from "../../__generated__/graphql"
import { TimeEditor } from "../Components/TimeEdit"
import { DownCauseWidget } from "../Dashboard/Widgets/DownCauseWidget"
import { getColorForState, getLightColorForState } from "../Dashboard/Widgets/MachineState"
import { LineDowncauseDetails } from "./LineDowncauseDetails"
import { LineDowncauseTable } from "./LineDowncauseTable"
import { GET_LINE_DATA } from "./LinePage"

const { Title } = Typography

export const DOWNTIME_EVENT_LOG = gql(`
query DowntimeEventLog($deviceId: String!, $begin: DateTime, $end: DateTime){
     downtimeHistory(deviceId: $deviceId, begin: $begin, end: $end){
      reason
      machineId
      error{
        errorId
        errorText
      }
      state
      begin
      end
      duration
  }
  }
`)

export const LineDowncausePage = () => {
  const params = useParams()
  const lineId = params.lineId ?? ""

  const timeSettings = useRecoilValue(selectedTimeSelector)
  const { data } = useQuery(DOWNTIME_EVENT_LOG, { variables: { deviceId: lineId ?? "" } })
  const { data: lineData } = useQuery(GET_LINE_DATA, { variables: { deviceId: lineId ?? "" } })
  const { t } = useTranslation()

  if (!data?.downtimeHistory) return <p>Loading</p>
  return (
    <>
      <Space size={"middle"} direction="vertical" style={{ display: "flex" }}>
        <div />
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col xl={12} sm={24}>
            <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Time">
              <div style={{ height: "100%", width: "100%" }}>
                {" "}
                {/*  justifyContent: "center", display: "flex", */}
                <TimeEditor machineId={lineId} />
              </div>
            </Card>
          </Col>
          <Col xl={12} sm={24}>
            <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Actual Downcause">
              <div style={{ height: "100%", width: "100%" }}>
                {" "}
                {/*  justifyContent: "center", display: "flex", */}
                {lineData?.line?.deviceId && <DownCauseWidget downCauseInformation={lineData?.line?.currentDownCause} lineData={lineData} />}
              </div>
            </Card>
          </Col>
        </Row>
        <Title level={2}>Downtime Statistic</Title>
        <LineDowncauseDetails lineId={lineId} />
        <Card style={{ cursor: "default" }} hoverable title={"Downtime History complete Line"}>
          <LineDowncauseTable data={data.downtimeHistory} />
        </Card>
      </Space>
    </>
  )
}
