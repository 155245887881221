import { useQuery } from "@apollo/client"
import React from "react"
import { gql } from "../../../__generated__/gql"
import { ErrorTimeline } from "../../Timelines/ErrorTimeline"

const GET_ERROR_HIST = gql(`
        query ErrorHist($deviceId: String!, $max: Int){
        eventLog(deviceId: $deviceId, maxNumber: $max){
            machineId
            errorId
            errorText
            startTimestamp
            duration
            endTimestamp
        }
    }`)

export const TimelineWidget = ({ machineId }: { machineId?: string }) => {
    const { data } = useQuery(GET_ERROR_HIST, { variables: { deviceId: machineId ?? "", max: 5 } })

    return <ErrorTimeline data={data} numberOfElements={4} />
}
