import dayjs, { Dayjs } from "dayjs"
import { atom, selector } from "recoil"

import utc from "dayjs/plugin/utc"
import localizedFormat from "dayjs/plugin/localizedFormat"
import duration from "dayjs/plugin/duration"
dayjs.extend(utc)
dayjs.extend(localizedFormat)
dayjs.extend(duration)

import "dayjs/locale/de"
dayjs.locale("de")

interface ITimeSelection {
    start: number
    stop: number
    shiftName?: string
}
interface ITimeSelectionDate {
    start: Dayjs
    stop: Dayjs
    duration: number
    shiftName?: string
}
export const selectedTimeState = atom<ITimeSelection>({
    key: "startTimestampState",
    default: { start: dayjs.utc().subtract(8, "hours").unix(), stop: dayjs.utc().unix() },
})

export const selectedTimeSelector = selector<ITimeSelectionDate>({
    key: "selectedTimeSelector",
    get: ({ get }) => {
        const t = get(selectedTimeState)
        const start = dayjs.unix(t.start)
        const stop = dayjs.unix(t.stop)
        const duration = stop.diff(start)
        return { start: start, stop: stop, duration: duration, shiftName: t.shiftName }
    },
})
