import { Card, Checkbox, Space, Tree } from "antd"
import { DataNode, EventDataNode, TreeProps } from "antd/es/tree";
import React, { Children, useEffect, useState } from "react"
import { HomeOutlined, ShopOutlined, SettingOutlined, GroupOutlined } from '@ant-design/icons';
import { soudronicGold, soudronicGreen, soudronicOrange, soudronicRaspberry } from "../constants/colors";

import { v4 as uuidv4 } from 'uuid';

enum IHierarchyType {
    Company,
    Factory,
    Line,
    Machine
}

interface HierarchyElement {
    title: string,
    id: string,
    type: IHierarchyType
    children?: HierarchyElement[]
}



const requestData: HierarchyElement[] = [
    {
        title: "Tin-Can",
        id: uuidv4(),
        type: IHierarchyType.Company,
        children: [
            {
                title: "Tin-Can Ambalaj Sanayi Ticaret, Hadimköy/Catalca/Istanbul, TR",
                id: "C27070",
                type: IHierarchyType.Factory,
                children: [
                    {
                        title: "Line 316647",
                        id: uuidv4(),
                        type: IHierarchyType.Line,
                        children: [
                            {
                                title: "316647-SW",
                                id: uuidv4(),
                                type: IHierarchyType.Machine
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        title: "Silgan Metal Packaging",
        id: uuidv4(),
        type: IHierarchyType.Company,
        children: [
            {
                title: "Silgan Metal Packaging, Nove Mesto nad Vahom, SK",
                id: "C26230",
                type: IHierarchyType.Factory,
                children: [
                    {
                        id: uuidv4(),
                        title: "Line 316618",
                        type: IHierarchyType.Line,
                        children: [
                            {
                                id: uuidv4(),
                                title: "16618-SP",
                                type: IHierarchyType.Machine
                            },
                            {
                                id: uuidv4(),
                                title: "16618-XY",
                                type: IHierarchyType.Machine
                            }
                        ]
                    }
                ]

            },
            {
                title: "Silgan Metal Packaging, Kenosha",
                id: "C33360",
                type: IHierarchyType.Factory,
                children: [
                    {
                        id: uuidv4(),
                        title: "Line 316443",
                        type: IHierarchyType.Line,
                        children: [
                            {
                                id: uuidv4(),
                                title: "316443-SW",
                                type: IHierarchyType.Machine
                            },
                            {
                                id: uuidv4(),
                                title: "316443-SF",
                                type: IHierarchyType.Machine
                            },
                            {
                                id: uuidv4(),
                                title: "316443-SB",
                                type: IHierarchyType.Machine
                            }
                        ]
                    },
                    {
                        id: uuidv4(),
                        title: "Line 316444",
                        type: IHierarchyType.Line,
                        children: [
                            {
                                id: uuidv4(),
                                title: "316444-SW",
                                type: IHierarchyType.Machine
                            },
                            {
                                id: uuidv4(),
                                title: "316444-SF",
                                type: IHierarchyType.Machine
                            },
                            {
                                id: uuidv4(),
                                title: "316444-SB",
                                type: IHierarchyType.Machine
                            }
                        ]
                    }
                ]
            }
        ]
    }
]

const treeDataNew: DataNode[] = requestData.map(company => {
    return ({
        title: company.title, key: company.id, icon: <HomeOutlined style={{ color: soudronicGreen }} />,
        children: company.children?.map(factory => ({
            title: `${factory.id} - ${factory.title}`, key: factory.id, icon: <ShopOutlined style={{ color: soudronicGold }} />,
            children: factory.children?.map(line => ({
                title: line.title, key: line.id, icon: <GroupOutlined style={{ color: soudronicRaspberry }} />,
                children: line.children?.map(machine => ({
                    title: machine.title, key: machine.id, icon: <SettingOutlined style={{ color: soudronicOrange }} />
                }))
            }))
        }))
    })
})


const getIdsRecursive = (element: any): string[] => {
    let ids = [element.key as string]
    if (element.children) {
        element.children.forEach((child: any) => {
            ids = ids.concat(getIdsRecursive(child))
        })
    }
    return ids
}

export const HierarchyTree = () => {


    const [checkedKeys, setCheckedKey] = useState<{ checked: string[], halfChecked: string[] }>({ checked: [], halfChecked: [] })

    useEffect(() => {
        console.log(checkedKeys)
    }, [checkedKeys])
    const onSelect = (selectedKeys: React.Key[], info: any) => {

        console.log('selected', selectedKeys, info);
    };


    const onCheck: TreeProps['onCheck'] = (checkedKeysNew, info) => {
        const selected = info.node.key as string
        const halfChecked = getIdsRecursive(info.node).filter(id => id !== selected)
        if (checkedKeys.halfChecked.includes(selected)) {
            return
        }
        console.log(halfChecked)
        if (info.checked) {
            setCheckedKey(prev => ({ halfChecked: [...prev.halfChecked, ...halfChecked], checked: [...prev.checked.filter(id => !halfChecked.includes(id)), selected] }))
        } else {

            setCheckedKey(prev => ({ halfChecked: prev.halfChecked.filter(id => !halfChecked.includes(id)), checked: prev.checked.filter(id => id !== selected) }))
        }
    };

    return (
        <>
            <Space direction="vertical">
                <Card hoverable>
                    <Space direction="horizontal">
                        <Checkbox indeterminate />
                        <p>Accessable Objects</p>
                        <Checkbox checked />
                        <p>Access Level</p>
                    </Space>
                </Card>
                <Card hoverable >
                    <Tree
                        showLine={true}
                        showIcon={true}
                        onSelect={onSelect}
                        onCheck={onCheck}
                        treeData={treeDataNew}
                        selectable
                        checkable
                        multiple
                        checkStrictly
                        checkedKeys={checkedKeys}
                        autoExpandParent
                        defaultExpandAll
                    />
                </Card>
            </Space>
        </>
    )
}