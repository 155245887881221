import { Table, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import React from "react"
import { useTranslation } from "react-i18next"
import { secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import { DownCauseType } from "../../__generated__/graphql"

export const LineDowncauseTable = ({ data }: { data: (DownCauseType | null)[] | null | undefined }) => {
  const { t } = useTranslation()

  const columns: ColumnsType<DownCauseType> = [
    {
      title: t("reason"),
      dataIndex: "reason",
      render: (value, record) =>
        value === "MACHINE" ? (
          <Tag key={record.begin}>Machine</Tag>
        ) : (
          <Tag key={record.begin} color={"warning"}>
            Line Pile Up
          </Tag>
        ),
    },
    {
      title: t("machine"),
      dataIndex: "machineId",
    },
    {
      title: t("state"),
      dataIndex: "state",
    },
    {
      title: t("errorId"),
      dataIndex: "error",
      responsive: ["md"],
      render: (_value, record) => record.error?.errorId,
    },
    {
      title: t("errorText"),
      dataIndex: "error",
      responsive: ["md"],
      render: (_value, record) => record.error?.errorText,
    },
    {
      title: t("topErrorTable.duration"),
      dataIndex: "duration",
      sorter: (a, b) => a.duration - b.duration,
      render: (value) => secondsToDHHMMSS(value),
    },
    {
      title: t("begin"),
      dataIndex: "begin",
      sorter: (a, b) => new Date(a.begin).valueOf() - new Date(b.begin).valueOf(),
      render: (value) => new Date(value).toLocaleString(),
    },
    {
      title: t("end"),
      dataIndex: "end",
      sorter: (a, b) => new Date(a.end).valueOf() - new Date(b.end).valueOf(),
      defaultSortOrder: "descend",
      render: (value) => new Date(value).toLocaleString(),
    },
  ]
  if (!data) return <p>Loading</p>
  return <>{data && <Table columns={columns} dataSource={data as DownCauseType[]} rowKey="begin" />}</>
}
