import { ChartData, Chart } from "chart.js"
import React, { useEffect, useMemo, useState } from "react"
import { Line } from "react-chartjs-2"
import { GetCounterHistoryQuery } from "../../__generated__/graphql"
import 'chart.js/auto'
import 'chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm';

import zoomPlugin from 'chartjs-plugin-zoom';



Chart.register(zoomPlugin);

export const CounterChart = ({ data }: { data?: GetCounterHistoryQuery }) => {
    const [chartData, setChartData] = useState<ChartData<"line">>({ datasets: [], labels: [] })

    useEffect(() => {
        if (data?.machineCounterHistory && data.machineCounterHistory.points) {
            const l: Date[] = []
            const d: number[] = []
            const values: { Item1: string, Item2: number }[] = JSON.parse(data.machineCounterHistory.points)
            values.forEach((v) => {
                l.push(new Date(v.Item1))
                d.push(v.Item2)
            })
            setChartData({ labels: l, datasets: [{ data: d, label: "Production Counter" }] })
        }

    }, [data])

    return (
        <Line
            height={400}
            options={
                {
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            type: 'time',
                            time: {
                                tooltipFormat: 'YY-MM-DD hh:mm:ss',
                                displayFormats: {
                                    'millisecond': 'hh:mm:ss',
                                    'second': 'hh:mm:ss',
                                    'minute': 'hh:mm',
                                    'hour': 'DD-MM hh:mm:ss',
                                    'day': 'DD-MM hh:mm:ss',
                                    'week': 'DD-MM hh:mm:ss',
                                    'month': 'DD-MM hh:mm:ss',
                                    'quarter': 'DD-MM hh:mm:ss',
                                    'year': 'DD-MM hh:mm:ss',
                                }
                            },
                            title: {
                                display: true,
                                text: 'Date'

                            }
                        },
                    },
                    plugins: {
                        zoom: {
                            zoom: {
                                wheel: {
                                    enabled: true // SET SCROOL ZOOM TO TRUE
                                },
                                mode: "x",
                            },
                            pan: {
                                enabled: true,
                                mode: "x",
                            }
                        }
                    }
                }

            }
            data={chartData} />
    )
}

export const MemoCounter = React.memo(CounterChart)