import React, { useEffect, useState } from "react"
import { DownCauseType, GetLineDataQuery, MachineStatusType } from "../../../__generated__/graphql"
import { Alert, Descriptions, Popover, Segmented, Space, Statistic, Tag, Tooltip } from "antd"
import { t } from "i18next"
import { FieldTimeOutlined, PlayCircleOutlined } from "@ant-design/icons"
import { contrastColors, soudronicRaspberry } from "../../../constants/colors"
import { SegmentedValue } from "antd/es/segmented"
import { ExampleChart } from "./ExampleCharts"
import { getLineMachineNameFromId } from "../../../helpers/lineData"
import { Doughnut } from "react-chartjs-2"

import ChartDataLabels, { Context } from "chartjs-plugin-datalabels"
import { ChartData, TooltipItem } from "chart.js"
import { secondsToDHHMMSS } from "../../../helpers/TimeHelpers"
import { gql } from "../../../__generated__/gql"
import { useQuery } from "@apollo/client"
import { getColorForState } from "./MachineState"
enum Selection {
  Actual,
  Shift,
}

const GET_ERROR_HIST = gql(`
        query downtimeStatistic($deviceId: String!){
            downtimeStatistic(deviceId: $deviceId){
            lineId
            totalDowntimeDuration
            machineDowntimes{
                deviceId
                totalDuration
                percentage
            }
            }
        }
`)

export const DownCauseWidget = ({ downCauseInformation, lineData }: { downCauseInformation: DownCauseType | undefined | null; lineData: GetLineDataQuery | undefined }) => {
  const { loading, error, data, refetch } = useQuery(GET_ERROR_HIST, { variables: { deviceId: lineData?.line?.deviceId ?? "" }, pollInterval: 30000 })

  const [chartData, setChartData] = useState<ChartData<"doughnut">>({ labels: [], datasets: [] })
  useEffect(() => {
    if (data && data.downtimeStatistic) {
      const labels = data.downtimeStatistic.machineDowntimes.map((mc) => mc?.deviceId)
      const values = data.downtimeStatistic.machineDowntimes.map((mc) => mc.totalDuration)
      setChartData({ labels: labels, datasets: [{ data: values, borderRadius: 5 }] })
    }
  }, [data])

  const [selectedItem, setSelectedItem] = useState(Selection.Actual)
  const handleSegmentChange = (value: SegmentedValue) => {
    switch (value) {
      case 0:
        setSelectedItem(Selection.Actual)
        break
      case 1:
        setSelectedItem(Selection.Shift)
        break
      default:
        break
    }
  }
  useEffect(() => {
    if (downCauseInformation) {
      setSelectedItem(Selection.Actual)
    } else {
      setSelectedItem(Selection.Shift)
    }
  }, [downCauseInformation])
  const picturepath = `/images/LineMachines/${downCauseInformation?.machineId}.svg`
  const picture = <img style={{ maxWidth: "100%", maxHeight: "60px", verticalAlign: "middle", width: "100%" }} src={picturepath} alt={picturepath} />

  const actual = (
    <>
      {downCauseInformation ? (
        <Descriptions column={1} size="small" bordered>
          {/* <Descriptions.Item label={t("Reason")}>{lineData?.reason}</Descriptions.Item> */}
          <Descriptions.Item label={t("Machine")}>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
              <Space style={{ alignItems: "center" }} direction="vertical">
                <Tag>{getLineMachineNameFromId(downCauseInformation?.machineId ?? "", lineData)}</Tag>
                {picture}
              </Space>
            </div>
          </Descriptions.Item>
          <Descriptions.Item label={t("State")}>{downCauseInformation?.state}</Descriptions.Item>
          {downCauseInformation?.error && (
            <Descriptions.Item label={t("Error")}>
              <Tooltip color={getColorForState(MachineStatusType.Error)} title={<p>{downCauseInformation?.error?.errorText}</p>}>
                {downCauseInformation?.error?.errorText.substring(0, 20)}...
              </Tooltip>
            </Descriptions.Item>
          )}
          <Descriptions.Item label={t("Since")}>{new Date(downCauseInformation?.begin).toLocaleString()}</Descriptions.Item>
        </Descriptions>
      ) : (
        <Alert
          style={{
            opacity: selectedItem == Selection.Shift ? "0" : "1",
            transition: "opacity 1s ease-in",
            visibility: selectedItem == Selection.Shift ? "hidden" : "visible",
          }}
          message="No Downtime"
          type="success"
          showIcon
        />
      )}
    </>
  )

  const shift = (
    <>
      <Doughnut
        height={170}
        width={170}
        plugins={[ChartDataLabels as any]}
        options={{
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: true,
              labels: {
                usePointStyle: true,
                pointStyle: "rectRounded",
                filter: (value, data) => {
                  return (value.index ?? 0) < 3
                },
              },
            },
            datalabels: {
              color: (context: Context) => {
                const index = context.dataIndex
                let c = "black"
                if (context.dataset.backgroundColor?.constructor === Array) {
                  c = context.dataset.backgroundColor ? context.dataset.backgroundColor[index] : "black"
                }
                return contrastColors[c]
              },
              formatter: (value, context: Context) => {
                const total = context.dataset.data.filter((_, i) => context.chart.getDataVisibility(i)).reduce<number>((prev, actual) => prev + (actual as number), 0)
                const percentage = Math.round((value / total) * 100)
                if (percentage < 4) return ""
                return percentage + "%"
              },
              font: {
                weight: "bold",
                size: 12,
              },
              anchor: "center",
              display: "auto",
              clip: false,
              offset: 200,
              // align: 'top'
            },
            tooltip: {
              callbacks: {
                label: (v: TooltipItem<"doughnut">) => `${secondsToDHHMMSS(parseInt(v.raw as any))} `,
              },
            },
          },
        }}
        data={chartData}
      />
      <Statistic style={{ position: "absolute", left: 10, bottom: 10 }} title={t("Total Downtime")} value={secondsToDHHMMSS(data?.downtimeStatistic?.totalDowntimeDuration)} />
    </>
  )
  return (
    <>
      <Space direction="vertical" style={{ width: "100%" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
          <Segmented
            value={selectedItem}
            onChange={handleSegmentChange}
            options={[
              {
                label: <PlayCircleOutlined style={{ fontSize: 20, color: downCauseInformation ? soudronicRaspberry : "black" }} />,
                value: Selection.Actual,
              },
              {
                label: <FieldTimeOutlined style={{ fontSize: 20 }} />,
                value: Selection.Shift,
              },
            ]}
          />
        </div>
        {selectedItem == Selection.Actual ? actual : shift}
      </Space>
    </>
  )
}
