import { InteractionRequiredAuthError, PopupRequest } from "@azure/msal-browser";
import { useIsAuthenticated, useMsal, useAccount } from "@azure/msal-react";
import { useEffect } from "react";
import { useRecoilState, useSetRecoilState } from "recoil";
import { accessTokenRolesState, accessTokenState } from "../recoil/atom.msal";


export const scopes = ["api://82db46e1-c7af-411e-a715-e69496e4fdf5/API_READ"]
export const loginRequest: PopupRequest = {
    scopes: scopes,
};

const TOKEN_UPDTATE_INTERVAL = 1000 * 60 * 5

const getRoles = (accessToken: string) => {
    const base64Url = accessToken.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    const decodedToken = JSON.parse(atob(base64));

    const appRoles = decodedToken.roles;
    return appRoles
}

export const useStartAuth = () => {
    const isAuth = useIsAuthenticated()
    const [accessToken, setAccessToken] = useRecoilState(accessTokenState)
    const setAccesstokenRoles = useSetRecoilState(accessTokenRolesState)

    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});



    const updateToken = () => {
        if (account) {
            instance.acquireTokenSilent({
                scopes: scopes,
                account: account
            }).then((response) => {
                if (response) {
                    setAccessToken(response.accessToken)
                    const roles = getRoles(response.accessToken)
                    setAccesstokenRoles(roles)
                    const env = process.env.REACT_APP_ENV
                    if (env == "dev") {
                        console.log(response.accessToken)
                        console.log(roles)
                    }
                }
            }).catch((error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    instance.loginRedirect()
                }
            })
        }
    }

    useEffect(() => {
        const interval = setInterval(() => {
            updateToken()
        }, TOKEN_UPDTATE_INTERVAL)
        return (() => clearInterval(interval))
    }, [])


    useEffect(() => {
        updateToken()
    }, [account, instance]);

}


