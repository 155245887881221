export const groupBy = <T>(arr: T[], fn: (item: T) => any) => {
    return arr.reduce<Record<string, T[]>>((prev, curr) => {
        const groupKey = fn(curr)
        const group = prev[groupKey] || []
        group.push(curr)
        return { ...prev, [groupKey]: group }
    }, {})
}

export const chunk = (text: string, maxLength: number) => {
    const words = text.split(" ")
    const result: string[] = []
    let currentLine = ""

    for (const word of words) {
        if ((currentLine + word).length <= maxLength) {
            currentLine += (currentLine === "" ? "" : " ") + word
        } else {
            result.push(currentLine)
            currentLine = word
        }
    }

    if (currentLine !== "") {
        result.push(currentLine)
    }

    return result
}

