import React from "react"
import { useRecoilValue } from "recoil"
import { premiumState } from "../../recoil/atoms.application"
import { GetPremium } from "../Components/GetPremium"

interface MyProps {}

export const PremiumOnlyPage = (props: React.PropsWithChildren<MyProps>) => {
    const premium = useRecoilValue(premiumState)
    if (premium) return <>{props.children}</>
    return <GetPremium />
}
