import { gql } from "../../__generated__/gql";

export const GET_LINES_OVERVIEW = gql(/* GraphQL */`

query GET_LINES_OVERVIEW {
  lines {
    deviceId
    customer
    name
    machineType
    lineConnection {
      status
      changed
    }
  }
}


`);