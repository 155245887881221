import { Card, Grid } from "antd"
import React, { useEffect, useRef, useState } from "react"
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import { useRecoilValue } from "recoil"
import { editDashboard } from "../../recoil/atoms.application"
import { GetLineDataQuery, LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { useTranslation } from "react-i18next"
import { NavigationButton } from "../Components/NavigationButton"
import { Xwrapper, useXarrow } from "react-xarrows"
import GridLayout from "react-grid-layout"
import { LineMachineStatus } from "../Dashboard/Widgets/LineMachineStatus"

const { useBreakpoint } = Grid

function useWindowSize() {
  const [windowSize, setWindowSize] = useState<{ width: number | undefined; height: number | undefined }>({
    width: undefined,
    height: undefined,
  })
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  return windowSize
}

const ELEMENT_HEIGHT = 350
const ELEMENT_WIDTH = 300

export const LineView = ({ lineData }: { lineData: GetLineDataQuery | undefined }) => {
  const { t } = useTranslation()

  const screens = useBreakpoint()
  const edit = useRecoilValue(editDashboard)
  const size = useWindowSize()
  const updateXarrow = useXarrow()
  const [numberOfElements, setNumberOfElements] = useState(3)
  const [gridHeigt, SetGridHeigt] = useState(1200)

  useEffect(() => {
    setTimeout(() => {
      updateXarrow()
    }, 300)
  }, [])

  const ref = useRef<HTMLDivElement>(null)

  const productionPath = lineData?.line?.productionPath
  if (!productionPath) {
    return <Card>No Production Path</Card>
  }
  if (!productionPath.machines) {
    return <Card>Empty Production Path</Card>
  }

  useEffect(() => {
    if (productionPath.machines) {
      if (size.width) {
        const numberOfElementsCalculated = Math.ceil(size.width / (ELEMENT_WIDTH * 1.3))
        setNumberOfElements(numberOfElementsCalculated)
        const gridHeigt = Math.ceil(productionPath.machines.length / numberOfElementsCalculated) * (ELEMENT_HEIGHT * 1.3)
        SetGridHeigt(gridHeigt)
        setTimeout(() => {
          updateXarrow()
        }, 400)
      }
    }
  }, [size.width, productionPath.machines])

  return (
    <>
      {productionPath && productionPath.machines && (
        <Card title={`Line Overview -  ${lineData?.line?.productionPath?.name}`} hoverable style={{ cursor: "default" }}>
          <Xwrapper>
            <div ref={ref}>
              <GridLayout
                style={{ height: gridHeigt }}
                className="layout"
                rowHeight={350 / 8}
                cols={32}
                isResizable={false}
                isDraggable={false}
                onDragStop={() => setTimeout(updateXarrow, 200)}
                width={size.width ?? 1800}
                compactType={null}
                allowOverlap
              >
                {productionPath.machines.map((productionPathMachine, i) => {
                  if (productionPathMachine) {
                    const mc = lineData?.line?.configuration?.machines?.find((m) => m?.id == productionPathMachine.id)
                    return (
                      <Card
                        key={"key-" + i + productionPathMachine.id}
                        data-grid={{
                          x: (i % numberOfElements) * Math.floor(32 / numberOfElements),
                          y: Math.floor(i / numberOfElements) * 7,
                          w: Math.floor(24 / numberOfElements),
                          h: 6,
                        }}
                        id={"element-" + i}
                        style={{ height: ELEMENT_HEIGHT, alignItems: "center", cursor: "default", position: "relative", overflow: "hidden" }}
                        hoverable
                        extra={<p>{productionPathMachine.name}</p>}
                      >
                        <LineMachineStatus machineId={productionPathMachine} connected={LineConnectionStatusEnumType.Connected} machine={mc} />
                      </Card>
                    )
                  }
                })}
              </GridLayout>
            </div>
            {/* {productionPath.machines.map((mId, i) => {

                            if (i < (productionPath.machines?.length ?? 0) - 1) {
                                const rest = (i % numberOfElements)
                                let startAnchor: anchorType = "auto"
                                let endAnchor: anchorType = "auto"
                                switch (rest) {
                                    case numberOfElements - 1:
                                        startAnchor = "bottom"
                                        endAnchor = "top"
                                        break;

                                    default:
                                        break;
                                }
                                return (
                                    <Xarrow
                                        key={
                                            "arrow+" + i
                                        }
                                        curveness={0.8}
                                        path="grid"
                                        startAnchor={startAnchor}
                                        endAnchor={endAnchor}
                                        start={"element-" + i}
                                        end={"element-" + (i + 1)}
                                    />
                                )
                            }
                        })} */}
          </Xwrapper>
        </Card>
      )}
    </>
  )
}
