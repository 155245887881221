import React, { ReactNode, useEffect, useState } from 'react'
interface MyProps { }
export const DelayedLoader = (props: React.PropsWithChildren<MyProps>) => {
    const [showSpinner, setShowSpinner] = useState(false)

    useEffect(() => {
        const timer = setTimeout(() => setShowSpinner(true), 750)

        return () => clearTimeout(timer)
    })

    return <>
        {showSpinner ? props.children : <div />}
    </>
}
