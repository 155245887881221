import { Card } from "antd"
import React from "react"
import { HierarchyTree } from "./HierarchyTree"

export const UserManager = () => {


    return (
        <Card style={{ marginTop: 15 }} title="User Manager" >
            <p>Test</p>
            <HierarchyTree />
        </Card>
    )
}