import { useQuery } from "@apollo/client"
import { Card, Col, Row, Space } from "antd"
import React from "react"
import { useParams } from "react-router-dom"
import { gql } from "../../__generated__/gql"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { TimeEditor } from "../Components/TimeEdit"
import { LineEfficiencyWidget } from "../Dashboard/Widgets/LineEfficiencyWidget"
import { GET_LINE_DATA } from "./LinePage"

export const LineEfficiencyPage = () => {
  const params = useParams()
  const lineId = params.lineId ?? ""
  const { data: lineData } = useQuery(GET_LINE_DATA, { variables: { deviceId: lineId ?? "" } })
  return (
    <Space size={"middle"} direction="vertical" style={{ display: "flex" }}>
      <div />
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xl={12} sm={24}>
          <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Time">
            <div style={{ height: "100%", width: "100%" }}>
              {" "}
              {/*  justifyContent: "center", display: "flex", */}
              <TimeEditor machineId={lineId} />
            </div>
          </Card>
        </Col>
        <Col xl={12} sm={24}>
          <Card style={{ height: 350, alignItems: "center", cursor: "default", position: "relative", overflow: "hidden" }} hoverable title="Line Efficiency">
            {lineData?.line?.deviceId && <LineEfficiencyWidget machineId={lineId} connected={LineConnectionStatusEnumType.Connected} lineData={lineData} />}
          </Card>
        </Col>
      </Row>
    </Space>
  )
}
