import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getSecondsFromhhmmss, secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import { GetStateTimesQuery, MachineStatusType } from "../../__generated__/graphql"

interface DataType {
    state: MachineStatusType
    duration: number
}

export const StatesTable = ({ errorData }: { errorData: GetStateTimesQuery }) => {


    const [data, setData] = useState<DataType[]>([])
    const { t } = useTranslation()
    useEffect(() => {
        if (errorData.machineStateTime) {
            const errors: DataType[] = errorData.machineStateTime?.map(e => ({ state: e?.status ?? MachineStatusType.Unknown, duration: e?.duration ?? 0 }))
            setData(errors)
        }
    }, [errorData])


    const columns: ColumnsType<DataType> = [
        {
            title: t("machineStatesTable.state"),
            dataIndex: "state"
        },
        {
            title: t("machineStatesTable.duration"),
            dataIndex: "duration",
            defaultSortOrder: 'descend',
            sorter: (a, b) => (a.duration) - (b.duration),
            render: (value, record) => secondsToDHHMMSS(value)
        },
    ]



    return (
        <Table pagination={false} columns={columns} dataSource={data} rowKey="state" />
    )
}