import { useAccount, useMsal } from "@azure/msal-react"
import { Button, Grid } from "antd"
import React from "react"
import { SoudronicButtonOrange } from "./SoudronicButtonOrange"
const { useBreakpoint } = Grid

export const RequestButton = ({ text, body, subject, tooltip }: { text: string; body: string; subject: string; tooltip?: string }) => {
    return <SoudronicButtonOrange externalLink={`mailto:spareparts@soudronic.com?subject=${subject}&body=${body}`} text={text} tooltip={tooltip} />
}

export const RequestMachineButton = () => {
    const { accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const screens = useBreakpoint()

    const subject = "Machine connection to mySoudronic Insights"
    const body = `Dear Sir or Madam, %20%0D%0AI'd like to connect following machines to mySoudronic Insights: %20%0D%0A-%20%0D%0A-%20%0D%0A-  %20%0D%0A%20%0D%0A%20%0D%0A Username: ${account?.username} `
    const text = "Request support to connect your machine"
    const conditionalText = screens.lg ? text : "?"
    const tooltip = screens.lg ? undefined : text
    return <RequestButton text={conditionalText} subject={subject} body={body} tooltip={tooltip} />
}
export const RequestLineButton = () => {
    const { accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const screens = useBreakpoint()

    const subject = "Line connection to mySoudronic Insights"
    const body = `Dear Sir or Madam, %20%0D%0AI'd like to connect following lines to mySoudronic Insights: %20%0D%0A-%20%0D%0A-%20%0D%0A- %20%0D%0A%20%0D%0A%20%0D%0A Username: ${account?.username}%20%0D%0A `
    const text = "Request support to connect your production line"
    const conditionalText = screens.lg ? text : "?"
    const tooltip = screens.lg ? undefined : text
    return <RequestButton text={conditionalText} subject={subject} body={body} tooltip={tooltip} />
}
