import { useLazyQuery } from "@apollo/client"
import { Empty, Statistic } from "antd"
import { ChartData } from "chart.js"
import React, { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import { gql } from "../../../__generated__"

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip as tt, Legend } from "chart.js"
import { useTranslation } from "react-i18next"
import { MachineTopErrorType, TopMachinErrorFromToQuery } from "../../../__generated__/graphql"
import { chunk } from "../../../helpers/helpers"
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, tt, Legend)

const GET_TOP_ERRORS = gql(`
    query TopMachinError($deviceId: String!){
        machineTopError(deviceId: $deviceId){
            count
            error{
                errorId
                errorText
            }
            machineId
            totalTime
        }
    }
`)

const backgroundColor = ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255,1)", "rgba(255, 159, 64, 1)"]

export const TopErrorWidget = ({ machineId, externalData, data, max = 9999 }: { machineId?: string; externalData: boolean; data?: TopMachinErrorFromToQuery | undefined; max?: number }) => {
    const { t } = useTranslation()

    const [chartData, setChartData] = useState<ChartData<"bar">>({ labels: [], datasets: [] })
    const [totalErrors, setTotalErrors] = useState(0)
    const [getData, { data: queryData }] = useLazyQuery(GET_TOP_ERRORS, { pollInterval: 120000, variables: { deviceId: machineId ?? "" } })

    useEffect(() => {
        if (!externalData) {
            getData()
        }
    }, [externalData])

    useEffect(() => {
        let topErrors: (MachineTopErrorType | null)[] | undefined = []
        if (data?.machineTopError) {
            topErrors = data.machineTopError
        } else if (queryData?.machineTopError) {
            topErrors = queryData.machineTopError
        }
        if (topErrors) {
            const labels = topErrors.slice(0, max).map((d) => d?.error?.errorText)
            const values = topErrors.slice(0, max).map((d) => d?.count)
            if (values.length > 0) {
                const sum = values.reduce((p, v) => p + v)
                setTotalErrors(sum)
            }
            setChartData({ labels: labels, datasets: [{ data: values, borderRadius: 5, backgroundColor: backgroundColor }] })
        }
    }, [data, queryData])
    //https://stackoverflow.com/questions/28476159/chart-js-pie-tooltip-getting-cut
    return chartData.datasets[0]?.data.length > 0 ? (
        <div style={{ height: 250 }}>
            <Statistic style={{ right: 30, top: 70, position: "absolute" }} title={t("TopErrorWidget.Statistic")} value={totalErrors} />
            <div style={{ height: 190, marginTop: 50 }}>
                <Bar
                    style={{}}
                    // width={100}
                    height={100}
                    options={{
                        scales: {
                            x: {
                                display: false,
                            },
                            y: {
                                ticks: {
                                    callback: (v) => (Number.parseFloat(v.toString()) % 1 === 0 ? v : ""),
                                },
                            },
                        },
                        maintainAspectRatio: false,
                        plugins: {
                            tooltip: {
                                enabled: true,
                                callbacks: {
                                    title: (context) => {
                                        const label = context.at(0)?.label
                                        if (label) {
                                            return chunk(label, 30)
                                        }
                                    },
                                },
                            },
                            legend: { display: false },
                        },
                    }}
                    data={chartData}
                />
            </div>
        </div>
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )
}
