import { Badge, Divider, Empty, Space, Statistic } from "antd"
import React, { useEffect, useState } from "react"
import { gql } from "../../../__generated__/gql"
import { LineConnectionStatusEnumType, MachineStatusType } from "../../../__generated__/graphql"
import { useRecoilValue } from "recoil"
import { machineSelector } from "../../../recoil/atom.machines"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { ChartData } from "chart.js"
import { Bar } from "react-chartjs-2"

const COUNTER_HIST_BUCKETS = gql(`
query GetCounterHistoryBuckets($deviceId: String!, $aggregationInterval: Int!){
  machineCounterHistoryBuckets(deviceId: $deviceId, aggregationInterval: $aggregationInterval){
    eventDuration
    startTimestamp
    endTimestamp
    machineId
    points
  }
}
`)
const UPDATE_INTERVAL = 200
const TIME_WINDOW = 9900
const MAX_DIFF = 210

export const MachineCounterWidget = ({ machineId, connected, showOee = true }: { machineId?: string; connected?: LineConnectionStatusEnumType | null; showOee?: boolean }) => {
    const { t } = useTranslation()
    const machine = useRecoilValue(machineSelector(machineId))

    const { loading, error, data } = useQuery(COUNTER_HIST_BUCKETS, { variables: { deviceId: machineId ?? "", aggregationInterval: 3600 }, pollInterval: 100000 })
    const [chartData, setChartData] = useState<ChartData<"bar">>({ datasets: [], labels: [] })
    const [productionRate, setProductionRate] = useState(0)
    const [startValue, setStartValue] = useState(0)
    const [oee, setOee] = useState(0)

    const [localCounter, setLocalCounter] = useState(0)
    const [previeusCounter, setPrevieusCounter] = useState(0)

    useEffect(() => {
        if (machine && machine.counter && machine.status) {
            let intervalHandle = {} as any
            const difference = machine.counter - previeusCounter
            if (Math.abs(difference) > MAX_DIFF || machine.status.status !== MachineStatusType.On) {
                setLocalCounter(machine.counter)
            } else {
                const increments = Math.round(difference / (TIME_WINDOW / UPDATE_INTERVAL))
                const intervalHandle = setInterval(() => {
                    setLocalCounter((prev) => prev + increments)
                }, UPDATE_INTERVAL)
                setTimeout(() => {
                    clearInterval(intervalHandle)
                }, TIME_WINDOW)
            }
            setPrevieusCounter(machine.counter)
            return () => {
                clearInterval(intervalHandle)
            }
        }
    }, [machine?.counter, machine?.status])

    useEffect(() => {
        if (machine?.productionRate) {
            setProductionRate(machine?.productionRate)
        }
    }, [machine?.productionRate])

    useEffect(() => {
        if (data?.machineCounterHistoryBuckets && data.machineCounterHistoryBuckets.points) {
            const labels: string[] = []
            const counterValues: number[] = []
            const targetValues: number[] = []

            const values: { Item1: string; Item2: number }[] = JSON.parse(data.machineCounterHistoryBuckets.points)
            values.forEach((v, idx, array) => {
                const hour = new Date(v.Item1).getHours() - 1
                let label = `${hour} - ${hour + 1}`
                if (idx === array.length - 1) {
                    label = `${hour + 1} - ${hour + 2}`
                }
                labels.push(label)
                counterValues.push(v.Item2)
                targetValues.push((productionRate ?? 0) * 60)
            })
            setChartData({
                labels: labels,
                datasets: [
                    { data: counterValues, label: "Produced", borderRadius: 5 },
                    { data: targetValues, label: "Target", borderRadius: 5 },
                ],
            })
            if (counterValues.length > 0) {
                setOee(Math.round((counterValues.reduce((prev, current) => prev + current) / targetValues.reduce((prev, current) => prev + current)) * 100) / 100)
            }
        }
    }, [data, productionRate])

    return (
        <>
            <Space style={{ width: "100%" }} direction="horizontal" align="center">
                {connected === LineConnectionStatusEnumType.Connected ? <Badge size="default" color="green" status="processing" text="" /> : <Badge status="default" size="default" />}
                <Statistic title={t("MachineCounterWidget.statistic.counter")} value={machine?.status?.status === MachineStatusType.On ? localCounter : machine?.counter ?? 0} />
                {/* <Divider type="vertical" />
                <Statistic title={t("MachineCounterWidget.statistic.productionRate")} value={machine?.productionRate ?? "-"} /> */}
                <Divider type="vertical" />
                {showOee && <Statistic title={t("MachineCounterWidget.statistic.oee")} precision={1} value={oee && isFinite(oee) ? oee * 100 : "-"} suffix={"%"} />}
            </Space>
            <div style={{ height: 180 }}>
                {chartData.datasets[0]?.data.length > 0 ? (
                    <Bar
                        height={100}
                        //width={100}
                        options={{ maintainAspectRatio: false, plugins: { tooltip: { callbacks: { label: (v) => (v.datasetIndex == 0 ? `${v.raw}` : v.raw + t("machineCounterWidget.target")) } } } }}
                        data={chartData}
                    />
                ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        </>
    )
}
