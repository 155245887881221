import { useQuery } from "@apollo/client"
import { Button, Card, Col, message, Row, Space, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { soudronicAnthracite } from "../../constants/colors"
import { machineExistSelector } from "../../recoil/atom.machines"
import { gql } from "../../__generated__/gql"
import { TimeEditor } from "../Components/TimeEdit"
import { TopErrorTable } from "../Components/TopErrorTable"
import { TopErrorWidget } from "../Dashboard/Widgets/TopErrorWidget"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { DownloadReport } from "../Components/ReportDownload"

const GET_TOP_ERRORS = gql(`
    query TopMachinErrorFromTo($deviceId: String!, $begin: DateTime, $end: DateTime, $max: Int){
        machineTopError(deviceId: $deviceId, begin: $begin, end: $end, maxNumber: $max){
            count
            error{
                errorId
                errorText
            }
            machineId
            totalTime
        }
    }
`)
const { Title } = Typography

export const TopErrorPage = () => {
    const params = useParams()
    const machineId = params.machineId ?? ""
    const { t } = useTranslation()
    const [messageApi, contextHolder] = message.useMessage()

    const timeSettings = useRecoilValue(selectedTimeSelector)

    const { data, loading, error, refetch } = useQuery(GET_TOP_ERRORS, { variables: { max: 100, deviceId: machineId, begin: timeSettings.start.toISOString(), end: timeSettings.stop.toISOString() } })

    const updateComponent = (
        <Space wrap>
            <Button onClick={() => handleRefetch()} style={{ backgroundColor: soudronicAnthracite, color: "white" }}>
                {t("updateData")}
            </Button>
            <DownloadReport begin={timeSettings.start} end={timeSettings.stop} deviceId={machineId} typeOfReport={"Errors"} name="Default" />
        </Space>
    )
    const success = () => {
        messageApi.open({
            type: "success",
            content: "Updated Data",
        })
    }
    const handleRefetch = () => {
        refetch()
        success()
    }
    const machineExists = useRecoilValue(machineExistSelector(machineId))

    if (!machineExists) {
        return (
            <Card style={{ marginTop: 20, cursor: "default" }} title="No Machine">
                <p>No machine with id: {machineId}</p>
            </Card>
        )
    }
    return (
        <>
            <Space size={"middle"} direction="vertical" style={{ display: "flex" }}>
                <div />
                {contextHolder}
                <Title level={2}>{t("TopError.Title")}</Title>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xl={12} sm={24}>
                        <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Time">
                            <div style={{ height: "100%", width: "100%" }}>
                                {" "}
                                {/*  justifyContent: "center", display: "flex", */}
                                <TimeEditor machineId={machineId} />
                            </div>
                        </Card>
                    </Col>
                    <Col xl={12} sm={24}>
                        <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Current Status">
                            <div style={{ height: "100%", width: "100%" }}>
                                {" "}
                                {/*  justifyContent: "center", display: "flex", */}
                                <TopErrorWidget machineId={machineId} data={data} externalData={true} />
                            </div>
                        </Card>
                    </Col>
                </Row>
                <Card style={{ alignItems: "center", cursor: "default", overflow: "scroll" }} hoverable title={t("TopError.HistoryTitle")} extra={updateComponent}>
                    {data && <TopErrorTable errorData={data} />}
                </Card>
            </Space>
        </>
    )
}
