import { Badge, Card, Descriptions, Grid, Popover, Space, Typography } from "antd"
import Meta from "antd/es/card/Meta"
import React, { useState } from "react"
import { useRecoilValue } from "recoil"
import { soudronicBlue } from "../../constants/colors"
import { machineSelector } from "../../recoil/atom.machines"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { NavigationButton } from "../Components/NavigationButton"
import { LineGrid } from "./LineGrid"
import { LineState } from "./Widgets/LineState"
import { useQuery } from "@apollo/client"
import { GET_LINE_DATA } from "../Pages/LinePage"
import { RequestLineButton } from "../Components/RequestButton"

const { Text } = Typography

const { useBreakpoint } = Grid
export const DEMO_LINE_ID = "InsightsPro-Demo-ST"

export const LineDashboard = ({ lineId, hide }: { lineId?: string; hide: boolean }) => {
    const machine = useRecoilValue(machineSelector(lineId))
    const screens = useBreakpoint()

    const [show, _setShow] = useState(true)

    const { data: lineData } = useQuery(GET_LINE_DATA, { variables: { deviceId: lineId ?? "" } })
    const isConnected = machine?.lineConnection?.status == LineConnectionStatusEnumType.Connected

    const popoverInfos = (
        <>
            <Descriptions
                style={{ width: 300 }}
                bordered
                column={1}
                title="Line Details"
                items={[
                    { key: "km", label: "KM", children: machine?.id },
                    { key: "customer", label: "Customer", children: machine?.customer },
                ]}
            />
        </>
    )

    const title = (
        <Space direction="horizontal">
            <div />
            {isConnected ? <Badge size="default" color="green" status="processing" text="" /> : <Badge status="error" text="" />}
            <NavigationButton size="large" text={machine?.name ?? lineId ?? "-"} linkTo={lineId} alternative={!isConnected} />
            <Popover content={popoverInfos}>
                <Text style={{ color: soudronicBlue }}>{machine?.machineType}</Text>
            </Popover>
            {lineId == DEMO_LINE_ID && screens.sm && (
                <div style={{ position: "absolute", top: 15, right: 15 }}>
                    <RequestLineButton />
                </div>
            )}
        </Space>
    )
    const linkStatus = `/${lineId}/machinestate`

    const compactMobileView = (
        <Card
            style={{ marginTop: 20, height: 350, alignItems: "center", cursor: "default", position: "relative", overflow: "hidden" }}
            hoverable
            title="Status"
            extra={<NavigationButton text="Details" linkTo={linkStatus} />}
        >
            <LineState machineId={lineId} connected={machine?.lineConnection?.status} lineData={lineData} />
        </Card>
    )
    const fullView = (
        <>
            <Space direction="vertical" style={{ display: "flex" }}>
                {" "}
                <LineGrid connected={machine?.lineConnection?.status} lineId={lineId} />
            </Space>
        </>
    )
    const dashboardElement = <> {show && !screens.lg ? compactMobileView : fullView}</>
    return (
        <>
            {!hide && (
                <Card
                    hoverable={true}
                    style={{
                        borderWidth: "3px",
                        borderRadius: "10px",
                        cursor: "default",
                    }}
                >
                    <Meta title={title} />
                    {isConnected && dashboardElement}
                </Card>
            )}
        </>
    )
}
