import { Col, Grid, Layout, Row, theme, Typography } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import React, { useEffect } from "react";
import { User } from "./components/Menu/User";
import { soudronicBlue, soudronicFontWhite } from "./constants/colors";
import { SoudronicFooter } from "./SoudronicFooter";
import { leftPadding } from "./constants/layoutConstants";
import useBreadcrumbs from "use-react-router-breadcrumbs";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useScrollToTop } from "./helpers/useScrollToTop";

const { useBreakpoint } = Grid;
const { Title } = Typography;


interface MyProps { }
export const AppLayout = (props: React.PropsWithChildren<MyProps>) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();



  const DynamicUserBreadcrumb = ({ match }: any) => (
    <span>{[match.params.machineId]}</span>
  );

  const routes = [
    { path: "/:machineId/", breadcrumb: DynamicUserBreadcrumb },
    { path: "/:machineId/Machinestate/", breadcrumb: "Machine State" },
  ];

  useScrollToTop()

  const breadcrumbs = useBreadcrumbs(routes);
  const screens = useBreakpoint();
  useEffect(() => {
  }, [screens])
  return (
    <Layout style={{ background: colorBgContainer, minHeight: "calc(100vh - 160px)" }}>
      <Layout>
        <Header style={{ padding: 0, height: 90, background: soudronicBlue }}>
          <Row style={{ height: 90, alignContent: "center", justifyContent: "center", display: "flex" }}>
            {/* <Col xl={3} lg={3} md={0} sm={0} xs={0}></Col> */}
            <Col xl={21} lg={18} md={18} sm={18} xs={24} style={{ height: 90, display: "flex" }} >
              {screens.sm ?
                <Link to={"/"} style={{ height: 90, paddingLeft: leftPadding, alignSelf: "center", display: "flex" }}>
                  {/* <img
                    style={{ alignSelf: "center", height: 70, marginRight: 10 }}
                    src="/my-insights.png" /> */}
                  <img
                    style={{ alignSelf: "center" }}
                    src="/Soudronic_weiss.png" />
                </Link>
                : <Title level={2} style={{ color: soudronicFontWhite, alignSelf: "center", paddingLeft: leftPadding }}>Soudronic</Title>}
            </Col>

            <Col xl={3} lg={6} md={6} sm={6} xs={0} style={{ height: 90 }}>
              <User />
            </Col>

          </Row>
        </Header>
        <Content >
          <div
            style={{
              padding: leftPadding,
              background: colorBgContainer,
            }}
          >
            <React.Fragment>
              {breadcrumbs.map(({ match, breadcrumb }) => {
                return <NavLink key={match.pathname} to={match.pathname}>{breadcrumb} / </NavLink>
              })}
            </React.Fragment>
            {props.children}
          </div>
        </Content>
        <Footer style={{ padding: 0, marginLeft: 0, backgroundColor: soudronicFontWhite }}>
          <SoudronicFooter />
        </Footer>
      </Layout>
    </Layout >
  );
};
