import { Descriptions, Progress, Statistic, Table, Tag } from "antd"
import { ColumnsType } from "antd/es/table"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getSecondsFromhhmmss, secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import React from "react"
import { GetCounterHistoryQuery, GetRollerheadIdsQuery, RollerheadIdsType } from "../../__generated__/graphql"
import {
    CloseCircleOutlined,
    CheckCircleOutlined,
} from '@ant-design/icons';
import dayjs from "dayjs"
import { TimeRangeSelection } from "../Pages/MachineRollerheadPage"
import { Link } from "react-router-dom"
import { IRollerheadData, iRollerheadRedressingType } from "../Timelines/RollerheadStatsDiagram"
import { useRecoilValue } from "recoil"
import { rollerheadStatisticUnitState } from "../../recoil/atoms.application"
import { soudronicBlue } from "../../constants/colors"


interface ChildType {
    name: string,
    diameter: number
}




export const RollerheadTable = ({ loading, redressingData, rollerheadData, updateRange, rollerheadInfos }: {
    loading: boolean,
    redressingData: RollerheadIdsType | undefined, rollerheadData: GetRollerheadIdsQuery | undefined, updateRange: (range: TimeRangeSelection, index: string) => void, rollerheadInfos: IRollerheadData
}) => {
    const [selectedKey, setSelectedKey] = useState("")
    const [data, setData] = useState<RollerheadIdsType[]>([])
    const { t } = useTranslation()

    const rollerheadStatsSettings = useRecoilValue(rollerheadStatisticUnitState)

    // const rollerheadTableData: DataType[] = rollerheadData.rollerheadsIds?.map(e => ({ redressings: e.redressings, rollerheadId: e?.rollerheadId.slice(0, -1), verified: e.rollerheadId.includes("©"), start: e?.startTimestamp, stop: e?.endTimestamp, duration: e?.eventDuration, counter: e.counter, counterEnd: e.counterEnd, counterStart: e.counterStart, km: e.km, warrantyProgress: e.warrantyProgress }))
    // setData(rollerheadTableData)
    // const selected = rollerheadTableData.at(-1)?.rollerheadId ?? ""


    useEffect(() => {
        if (rollerheadData?.rollerheadsIds) {
            setData(rollerheadData.rollerheadsIds)
            const selected = rollerheadData.rollerheadsIds.at(-1)?.rollerheadId ?? ""
            setSelectedKey(selected)
            if (rollerheadData.rollerheadsIds.length > 0) {
                const updateIndex = selected.slice(0, -1)
                updateRange({ begin: dayjs(rollerheadData.rollerheadsIds.at(-1)?.startTimestamp).utc(), end: dayjs(rollerheadData.rollerheadsIds.at(-1)?.endTimestamp).utc() }, updateIndex)
            }
        }
    }, [rollerheadData])


    const columns: ColumnsType<RollerheadIdsType> = [
        {
            title: t("rollerhead.table.rollerheadId"),
            dataIndex: "rollerheadId",
            render: (value) => value.replace("©", "")
        },
        {
            title: t("rollerhead.table.verified"),
            render: (value, record) => record.rollerheadId.includes("©") ? <Tag icon={<CheckCircleOutlined />} color="success">{t("Verified")}</Tag> : <Tag icon={<CloseCircleOutlined />} color="error">{t("Not Verified")} </Tag>

        },
        {
            title: t("rollerhead.table.request"),
            render: (value, record) => record.warrantyProgress < 1 ? <Link target="_blank" to="https://www.mysoudronic.com/support-tickets/create-case/">Claim warranty</Link> : <p>Out of warranty</p>

        },
        {
            title: t("startTimestamp"),
            dataIndex: "start",
            render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm')

        },
        {
            title: t("stopTimestamp"),
            dataIndex: "stop",
            render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm')

        },
        {
            title: t(rollerheadStatsSettings ? "Distance [km]" : "Counter [Cans]"),
            render: (value, record) => rollerheadStatsSettings ? record.km.toLocaleString() : record.counter.toLocaleString(),
            sorter: (a, b) => (a.counter) - (b.counter),
        }
    ]

    const onSelectionChange = (selectedRowKeys: React.Key[], selectedRows: RollerheadIdsType[]) => {
        setSelectedKey(selectedRowKeys.at(-1)?.toString() ?? "")
        const row = selectedRows[0]
        updateRange({ begin: dayjs(row.startTimestamp).utc(), end: dayjs(row.endTimestamp).utc() }, row.rollerheadId.slice(0, -1))
    }


    const detailView = (record: RollerheadIdsType) => (
        <>
            <Descriptions column={{ xs: 1, sm: 2, md: 8 }}>
                <Descriptions.Item label="Id">{record.rollerheadId.replace("©", "")}</Descriptions.Item>
                <Descriptions.Item label="Part-No">{record.rollerheadType?.partNo}</Descriptions.Item>
                <Descriptions.Item label="Description">Rollerhead {record.rollerheadType?.description}</Descriptions.Item>
                <Descriptions.Item label="Warranty Limit">{record.rollerheadType?.warrantyDistance} km</Descriptions.Item>
                <Descriptions.Item label={rollerheadStatsSettings ? "KM" : "Counter"}><Statistic value={rollerheadStatsSettings ? record.km : record.counter} /></Descriptions.Item>
                <Descriptions.Item label="Warranty Progress"><Progress key={record.rollerheadId + "progress"} percent={Math.round(record.warrantyProgress * 100)} status={record.warrantyProgress > 1 ? 'normal' : 'active'} strokeColor={record.warrantyProgress > 1 ? 'gray' : { from: soudronicBlue, to: soudronicBlue }} /></Descriptions.Item>

            </Descriptions>
            <RedressingsTable key={record.rollerheadId + "redressings"} redressingData={record.redressings} />
        </>
    )

    return (
        <Table loading={loading} style={{ overflowX: "scroll" }} rowSelection={{ selectedRowKeys: [selectedKey], type: 'radio', onChange: onSelectionChange }} columns={columns} dataSource={data} rowKey={"rollerheadId"} expandable={{
            expandedRowRender: (record) => detailView(record),
        }} />
    )
}



const RedressingsTable = ({ redressingData }: {
    redressingData: iRollerheadRedressingType[] | undefined
}) => {
    const { t } = useTranslation()
    const rollerheadStatisticUnit = useRecoilValue(rollerheadStatisticUnitState)
    const columns: ColumnsType<iRollerheadRedressingType> = [
        {
            title: t("rollerhead.table.redressings.diameter"),
            dataIndex: "diameter"
        },
        {
            title: rollerheadStatisticUnit ? t("KM") : t("Counter"),
            dataIndex: "counter",
            render: (value, record) => rollerheadStatisticUnit ? record.km.toLocaleString() : record.counter.toLocaleString()

        },
        {
            title: t("startTimestamp"),
            dataIndex: "startTimestamp",
            render: (value) => dayjs(value).format('YYYY-MM-DD HH:mm')

        },
        {
            title: t("duration"),
            dataIndex: "eventDuration",
            sorter: (a, b) => (a.eventDuration) - (b.eventDuration),
            render: (value) => secondsToDHHMMSS(value)
        }
    ]



    return (

        <Table style={{}} pagination={false} columns={columns} dataSource={redressingData} rowKey={"diameter"} />
    )
}


