import { Col, Grid, Row, Space, Typography } from "antd"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilState, useRecoilValue } from "recoil"
import { soudronicAnthracite, soudronicBlue, soudronicFontWhite } from "./constants/colors"
import { accessTokenState } from "./recoil/atom.msal"
import { Link } from "react-router-dom"
import { premiumState } from "./recoil/atoms.application"

const { Text } = Typography
const { useBreakpoint } = Grid
declare const VERSION: string

export const SoudronicFooter = () => {
    const screens = useBreakpoint()
    const { t } = useTranslation()
    const token = useRecoilValue(accessTokenState)
    const [apiVersion, setApiVersion] = useState("")
    const [_premium, setPremium] = useRecoilState(premiumState)

    useEffect(() => {
        const getVersion = async () => {
            const result = await (
                await fetch(process.env.REACT_APP_API_REST_URL + "/welcome", {
                    method: "get",
                    headers: new Headers({
                        Authorization: "Bearer " + token,
                    }),
                })
            ).json()
            if (result.version) {
                setApiVersion(result.version)
                setPremium(result.premium)
            }
        }
        if (token !== "") {
            getVersion()
        }
    }, [token])
    return (
        <div style={{ height: 200, margin: 0 }}>
            <Row style={{ backgroundColor: soudronicBlue, height: 240 }}>
                <Text style={{ color: soudronicFontWhite, whiteSpace: "pre-wrap" }}>
                    {`
            ${t("contact")}

            Soudronic AG
            Industriestrasse 35
            8962 Bergdietikon
            Switzerland

            Phone +41 44 743 66 66
            info@soudronic.com`}
                </Text>
            </Row>
            {screens.lg ? (
                <Row style={{ backgroundColor: soudronicAnthracite, height: 120 }}>
                    <Col span={8} style={{ height: 120, alignContent: "center", justifyContent: "center", display: "flex" }}>
                        <Text style={{ color: soudronicFontWhite, whiteSpace: "pre-wrap", alignSelf: "center" }}>
                            © Soudronic AG 2023
                            {` V: ${VERSION} / API: ${apiVersion}`}
                        </Text>
                    </Col>
                    <Col span={8} style={{ height: 120, alignContent: "center", justifyContent: "center", display: "flex" }}>
                        <img style={{ height: "50%", alignSelf: "center" }} src="/icons-canrow_wn.png" />
                    </Col>
                    <Col span={8} style={{ height: 120, alignContent: "center", justifyContent: "center", display: "flex" }}>
                        <Text style={{ color: soudronicFontWhite, whiteSpace: "pre-wrap", alignSelf: "center" }}>
                            <Space size={"large"} direction="horizontal">
                                <Link target="_blank" to={"https://soudronic.com/imprint/"} style={{ color: "white" }}>
                                    Imprint
                                </Link>
                                <Link target="_blank" to={"https://www.mysoudronic.com/General-Terms-and-Conditions/"} style={{ color: "white" }}>
                                    Terms & Condition
                                </Link>
                                <Link target="_blank" to={"https://soudronic.com/privacy-policy/"} style={{ color: "white" }}>
                                    Privacy Policy
                                </Link>
                                <Link target="_blank" to={"https://soudronic.com/cookie-policy/"} style={{ color: "white" }}>
                                    Cookie Policy
                                </Link>
                            </Space>
                        </Text>
                    </Col>
                </Row>
            ) : (
                <Row style={{ backgroundColor: soudronicAnthracite, height: 120 }}>
                    <Col span={24} style={{ height: 120, alignContent: "center", justifyContent: "center", display: "flex" }}>
                        <img style={{ height: "20%", alignSelf: "center" }} src="/icons-canrow_wn.png" />
                    </Col>
                </Row>
            )}
        </div>
    )
}
