import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { App } from "./app";

import "./index.css";

import { msalInstance } from "./auth/msal";
import { MsalProvider } from '@azure/msal-react'
import { RecoilRoot } from "recoil";

import './i18next';


const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <MsalProvider instance={msalInstance}>
    <RecoilRoot>
      <BrowserRouter>
        <Suspense fallback={<p></p>}>
          <App />
        </Suspense>
      </BrowserRouter>
    </RecoilRoot>
  </MsalProvider>

);
if (process.env.NODE_ENV !== "development") {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
        .then(registration => {
          // console.log('SW registered: ', registration);
        }).catch(registrationError => {
          // console.log('SW registration failed: ', registrationError);
        });
    });
  }

}
