import { Alert, Col, Divider, Progress, Row, Spin, Statistic, Tooltip } from "antd"
import React from "react"
import { gql } from "../../../__generated__/gql"
import { LineConnectionStatusEnumType } from "../../../__generated__/graphql"
import { useRecoilValue } from "recoil"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { secondsToDHHMMSS } from "../../../helpers/TimeHelpers"
import { premiumState, rollerheadStatisticUnitState } from "../../../recoil/atoms.application"
import { soudronicBlue, soudronicGreen, soudronicOrange, soudronicRaspberry } from "../../../constants/colors"
import { GetPremium } from "../../Components/GetPremium"

export const ROLLERHEAD_DATA = gql(`
  query GetRollerheadPrediction($deviceId: String!){
    rollerheadPrediction(deviceId:$deviceId){
      machineId
      eventDuration
      startTimestamp
      endTimestamp
      rollerheadId
      diameter
      expectedRemainingTime
      remainingPercentage
      counterActual
      distanceActual
      counterEndExptected
      distanceEndExpected
      predictionPossible
      meanDiameterReduction
      expectedDiamer
      actualDiameter
    }
  }
`)

const getStrokeColor = (percentage: number | undefined) => {
    if (percentage) {
        switch (true) {
            case percentage < 60:
                return soudronicGreen
            case percentage < 80:
                return soudronicOrange
            default:
                return soudronicRaspberry
        }
    }
    return soudronicBlue
}

export const MachineRollerheadWidget = ({ machineId, connected }: { machineId?: string; connected?: LineConnectionStatusEnumType | null }) => {
    const { t } = useTranslation()
    const rollerheadStatsSettings = useRecoilValue(rollerheadStatisticUnitState)
    const premium = useRecoilValue(premiumState)

    const { data } = useQuery(ROLLERHEAD_DATA, { variables: { deviceId: machineId ?? "" } })
    const moreThanExpected = Math.abs(data?.rollerheadPrediction.remainingPercentage ?? 0) > 100

    const expectedStats = (
        <>
            <Statistic title={t("MachineRollerhead.statistic.RemainingPercentage")} value={moreThanExpected ? "-" : data?.rollerheadPrediction.remainingPercentage + "%"} />
            <Statistic title={t("MachineRollerhead.statistic.ExpectedRedressing")} value={moreThanExpected ? "-" : secondsToDHHMMSS(data?.rollerheadPrediction.expectedRemainingTime ?? 0)} />
        </>
    )

    const strokeColor = getStrokeColor(100 - (data?.rollerheadPrediction.remainingPercentage ?? 0))

    const rollerheadWidget = data ? (
        data.rollerheadPrediction.predictionPossible ? (
            <>
                <Row style={{ width: "100%", display: "flex" }}>
                    <Col span={12}>
                        <Statistic title={t("MachineRollerhead.statistic.diameter")} value={data?.rollerheadPrediction.diameter ?? 0} />
                        {rollerheadStatsSettings ? (
                            <>
                                <Statistic title={"Actual KM"} value={data.rollerheadPrediction.distanceActual + " km"} />
                                <Statistic title={"Expected KM"} value={data.rollerheadPrediction.distanceEndExpected + " km"} />
                            </>
                        ) : (
                            <>
                                <Statistic title={t("MachineRollerhead.statistic.actualCounter")} value={data?.rollerheadPrediction.counterActual ?? 0} />
                                <Statistic title={t("MachineRollerhead.statistic.totalExpected")} value={data?.rollerheadPrediction.counterEndExptected ?? 0} />
                            </>
                        )}
                    </Col>
                    <Col span={12}>
                        {moreThanExpected ? (
                            <Tooltip color={soudronicOrange} title={t("Calculation out of window. Possible reason: Missing Redressing entries on machine")}>
                                {expectedStats}
                            </Tooltip>
                        ) : (
                            expectedStats
                        )}
                    </Col>
                </Row>
                <Divider style={{ margin: 10 }} dashed />
                <Progress percent={100 - data.rollerheadPrediction.remainingPercentage} status="active" strokeColor={{ from: strokeColor, to: strokeColor }} />
            </>
        ) : (
            <Row style={{ width: "100%", display: "flex" }}>
                <Col span={18}>
                    <Alert style={{ height: 240 }} showIcon message="Prediction not possible" description="Insufficient data to calculate rollerhead statistics" type="warning" />
                </Col>
            </Row>
        )
    ) : (
        <Spin />
    )

    return (
        <div style={{ position: "relative" }}>
            <img style={{ position: "absolute", right: 1, top: 1, maxWidth: "30%", maxHeight: "30%", verticalAlign: "middle" }} src="/images/rollerhead.png" alt="Rollerhead" />
            {premium ? rollerheadWidget : <GetPremium machineId={machineId} />}
        </div>
    )
}
