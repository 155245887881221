import { Card, Space, Timeline } from "antd"
import React from "react"
import { RightCircleOutlined, ClockCircleOutlined, PlusCircleOutlined, BugOutlined } from "@ant-design/icons"
import { useTranslation } from "react-i18next"

const updateInfos = [
    {
        dot: <BugOutlined color="red" style={{ color: "red", fontSize: "16px" }} />,
        children: "28.05.24 - V: 2.4.60: Persist selected filters for current session",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "27.05.24 - V: 2.4.44: Premium and Basic Access Levels",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "14.05.24 - V: 2.4.33: New Export Feature for Line and Machine",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "26.01.24 - V: 2.4.13: New Insights Pro Line Dashboard - The whole production line in one Dashboard",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "06.11.23 - V: 2.3.180: Add <LastConnected> Info on offline Machines (History only 60 days)",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "06.10.23 - V: 2.3.174: Add Feedback Form",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "03.10.23 - V: 2.3.168: Refactor Machine State Page",
    },
    {
        dot: <BugOutlined color="red" style={{ color: "red", fontSize: "16px" }} />,
        children: "25.09.23 - V: 2.3.162: Fixed scroll positions",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "21.09.23 - V: 2.3.156: Added quicksearch, use <f> on keyboard to search for machine and quickly navigate",
    },
    {
        dot: <RightCircleOutlined style={{ fontSize: "16px" }} />,
        children: "20.09.23 - V: 2.3.146 - Updated Rollerhead Dashboard",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "19.09.23 - Added working shifts in Time selector",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "15.09.23 - Added Machine Type Filter",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "14.09.23 - Added new Search Filter",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "11.09.23 - Added OEE Page",
    },
    {
        dot: <BugOutlined color="red" style={{ color: "red", fontSize: "16px" }} />,
        children: "20.07.23 - Fixed Problem with fetching Data",
    },
    {
        dot: <PlusCircleOutlined style={{ fontSize: "16px" }} />,
        children: "16.07.23 - Added Rollerhead Page",
    },
    {
        dot: <ClockCircleOutlined style={{ color: "green", fontSize: "16px" }} />,
        children: "01.06.23 - Initial Release",
    },
]

export const VersionHistory = () => {
    const { t } = useTranslation()

    return (
        <>
            <Card hoverable title={t("versionHistory")} style={{ cursor: "default", marginTop: 20 }}>
                <Space size={"middle"} direction="vertical" style={{ display: "flex" }}>
                    <Timeline mode="left" items={updateInfos} />
                </Space>
            </Card>
        </>
    )
}
