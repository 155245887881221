import { useQuery } from "@apollo/client"
import { Button, Empty, Timeline } from "antd"
import { TimeLineItemProps } from "antd/es/timeline/TimelineItem"
import React, { Children, useEffect, useState } from "react"
import { gql } from "../../__generated__/gql"
import { ErrorHistQuery } from "../../__generated__/graphql"

export const ErrorTimeline = ({ data, numberOfElements }: { data: ErrorHistQuery | undefined; numberOfElements?: number }) => {
    const [items, setItems] = useState<TimeLineItemProps[]>([])

    useEffect(() => {
        if (data?.eventLog) {
            const tmp: TimeLineItemProps[] = data.eventLog
                .slice(-(numberOfElements ?? 5))
                .map((d) => ({ color: "red", children: `${new Date(d?.startTimestamp).toLocaleTimeString()}: ${d?.errorText}` }))
            setItems(tmp)
        }
    }, [data])

    return (
        <>
            {items.length > 0 ? (
                <div style={{ overflow: "hidden", height: 250 }}>
                    <Timeline style={{ marginTop: 5 }} reverse={true} items={items} />
                </div>
            ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </>
    )
}
