import { Card } from "antd"
import React, { useState } from "react"
import { Responsive, WidthProvider } from "react-grid-layout"
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import { useRecoilValue } from "recoil"
import { editDashboard } from "../../recoil/atoms.application"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { NavigationButton } from "../Components/NavigationButton"
import { LineState } from "./Widgets/LineState"
import { useQuery } from "@apollo/client"
import { GET_LINE_DATA } from "../Pages/LinePage"
import { DownCauseWidget } from "./Widgets/DownCauseWidget"
import { LineMachinesTimeline } from "./Widgets/LineMachinesTimeline"
import { LineEfficiencyWidget } from "./Widgets/LineEfficiencyWidget"
import { LineTopErrorWidget } from "./Widgets/LineTopErrorWidget"

const ResponsiveReactGridLayout = WidthProvider(Responsive)

export const LineGrid = ({ lineId, connected }: { lineId?: string; connected?: LineConnectionStatusEnumType | null }) => {
    const edit = useRecoilValue(editDashboard)

    const { data: lineData } = useQuery(GET_LINE_DATA, { variables: { deviceId: lineId ?? "" } })

    const [layouts] = useState({
        lg: [
            { i: "a", x: 0, y: 0, w: 1, h: 1 },
            { i: "b", x: 1, y: 0, w: 1, h: 1 },
            { i: "c", x: 2, y: 0, w: 1, h: 1 },
            { i: "d", x: 3, y: 0, w: 1, h: 1 },
            { i: "e", x: 4, y: 0, w: 1, h: 1 },
        ],
    })

    const linkStatus = `/${lineId}/LineGeneral`
    const linkError = `/${lineId}/lineError`
    const linkEfficiency = `/${lineId}/LineEfficiency`
    const linkDowncause = `/${lineId}/LineDowncause`
    const linkStates = `/${lineId}/LineMachines`

    return (
        <ResponsiveReactGridLayout
            className="layout"
            layouts={layouts}
            rowHeight={350}
            compactType="vertical"
            cols={{ lg: 5, md: 1, sm: 1, xs: 1, xxs: 1 }}
            isResizable={edit}
            isDraggable={edit}
            maxRows={2}
        >
            <div key="a">
                <Card
                    style={{ height: 350, alignItems: "center", cursor: "default", position: "relative", overflow: "hidden" }}
                    hoverable
                    title="Status"
                    extra={<NavigationButton text="Details" linkTo={linkStatus} />}
                >
                    <LineState machineId={lineId} connected={connected} lineData={lineData} />
                </Card>
            </div>
            <div key="b">
                <Card style={{ height: 350, cursor: "default" }} hoverable title="Top Error" extra={<NavigationButton linkTo={linkError} text="Details" />}>
                    <LineTopErrorWidget lineId={lineId} />
                </Card>
            </div>

            <div key="c">
                <Card style={{ height: 350, cursor: "default" }} hoverable title="Machine States" extra={<NavigationButton linkTo={linkStates} text="Details" />}>
                    <LineMachinesTimeline machineId={lineId} numberOfElements={5} />
                </Card>
            </div>
            <div key="d">
                <Card style={{ height: 350, cursor: "default" }} hoverable title="Line-Downcause" extra={<NavigationButton text="Details" linkTo={linkDowncause} />}>
                    {lineData?.line?.deviceId && <DownCauseWidget downCauseInformation={lineData?.line?.currentDownCause} lineData={lineData} />}
                </Card>
            </div>
            <div key="e">
                <Card
                    style={{ height: 350, alignItems: "center", cursor: "default", position: "relative", overflow: "hidden" }}
                    hoverable
                    title="Line Efficiency"
                    extra={<NavigationButton text="Details" linkTo={linkEfficiency} />}
                >
                    {lineData?.line?.deviceId && <LineEfficiencyWidget machineId={lineId} connected={connected} lineData={lineData} />}
                </Card>
            </div>
        </ResponsiveReactGridLayout>
    )
}
