import { AccountInfo } from "@azure/msal-browser";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Button, Descriptions, Dropdown, MenuProps, Modal, Popover, Space, Switch } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { loginRequest, scopes } from "../../auth/start";
import { rightPadding } from "../../constants/layoutConstants";
import { useLocalStorage } from "../../helpers/useLocalStorage";
import { useRecoilState } from "recoil";
import { darkModeActive } from "../../recoil/atoms.application";


export const User = () => {
    const { instance, accounts, inProgress } = useMsal();
    const account = useAccount(accounts[0] || {});
    const isAuth = useIsAuthenticated()
    const [modalOpen, setModalOpen] = useState(false)

    const [darkmode, setDarkmode] = useRecoilState(darkModeActive)

    const accountRef = useRef<AccountInfo | null>()
    accountRef.current = account


    useEffect(() => {
        if (account) {
            try {
                // instance.ssoSilent({ account: account, scopes: scopes })
            } catch (e) {
                console.log("catched: ", e)
            }
        } else {
            try {
                // instance.ssoSilent(loginRequest)
            } catch (e) {
                console.log("catched: ", e)
            }
            // instance.loginRedirect(loginRequest)
        }

        setTimeout(() => {
            if (!accountRef.current) {
                try {
                    instance.loginRedirect()
                } catch (e) {
                    console.log("catched delayed: ", e)

                }
            }
        }, 1000);
    }, [])


    const items: MenuProps['items'] = [
        {
            label: "Info",
            key: '1',
        },
        {
            label: isAuth ? 'Logout' : "Login",
            key: '2',
        }
    ];
    const onClick: MenuProps['onClick'] = ({ key }) => {
        switch (key) {
            case "2":
                if (isAuth) {
                    instance.logoutRedirect();
                } else {
                    instance.loginRedirect();
                }
                break;
            case "1":
                setModalOpen(true)
            default:
                break;
        }
    };

    const handleDarkmodeChange = (checked: boolean) => {
        setDarkmode(checked)
    }

    const handleOk = () => {
        setModalOpen(false)
    }



    return (
        <div style={{ position: "absolute", right: 20 }}>
            <Dropdown overlayStyle={{ width: 40, right: 10 }} menu={{ items, onClick }} >
                <Button size="large" style={{ right: 0, color: "white", marginTop: 25, fontSize: 20 }} type="link">
                    {isAuth ? account?.name : "Sign In"}
                </Button>
            </Dropdown>
            <Modal open={modalOpen} width={600} title={"User Info"} onCancel={handleOk} onOk={handleOk}>
                <>
                    <Descriptions style={{ marginTop: 10 }} column={1} bordered >
                        <Descriptions.Item label="User">{account?.name}</Descriptions.Item>
                        <Descriptions.Item label="Username">{account?.username}</Descriptions.Item>
                        <Descriptions.Item label="Tenant Id">{account?.tenantId}</Descriptions.Item>
                    </Descriptions>
                    <Space direction="horizontal">
                        <p>Darkmode - BETA</p>
                        <Switch checked={darkmode} checkedChildren={"dark"} unCheckedChildren={"light"} title="Darkmode" onClick={handleDarkmodeChange} />
                    </Space>

                </>

            </Modal>
        </div>
    )



}
