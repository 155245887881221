import React, { useEffect, useState } from "react"
import * as vis from "vis-timeline"
import { getStyleString, optionsTimeline } from "./Vis.Options"
import "./vis.css"
import "vis-timeline/dist/vis-timeline-graph2d.css"
import { LineStatesLogQuery } from "../../__generated__/graphql"
import { secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import { useRecoilValue } from "recoil"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { Descriptions, Space, Spin } from "antd"
import dayjs from "dayjs"

let timeline: vis.Timeline
var items: vis.DataItem[] = []
var groups: vis.DataGroup[]

interface ElementDetails {
  start: string
  end: string
  Title: string | undefined
}

const LineMachineStatesTimeline = ({ data }: { data?: LineStatesLogQuery }) => {
  const container = React.useRef(null) as any
  const timeSettings = useRecoilValue(selectedTimeSelector)
  const [loading, setLoading] = useState(false)

  const [details, setDetails] = useState<ElementDetails | undefined>()

  useEffect(() => {
    if (container && container.current) {
      timeline = new vis.Timeline(container.current, [], [], optionsTimeline)
      timeline.on("select", handleTimelineSelect)
    }
  }, [])

  const handleTimelineSelect = (data: any) => {
    if (data.items.length > 0) {
      const index: number = data.items[0]
      const element = items[index]
      if (element) {
        setDetails({ start: dayjs(element.start).toDate().toLocaleString(), end: dayjs(element.end).toDate().toLocaleString(), Title: element.title })
      }
    }
  }

  useEffect(() => {
    if (container && container.current && data && data.lineStatesLog && timeline && !loading) {
      setTimeout(() => {
        setLoading(true)
        timeline.setWindow(timeSettings.start.toDate(), timeSettings.stop.toDate())
        console.log("Start")
        var machineIds = new Set<string>()
        items = data.lineStatesLog.map((d, i) => {
          const message = `${d?.machineStatus} `
          const title = `${d?.machineStatus} - ${secondsToDHHMMSS(d?.duration)}s - ${d?.errorId ?? ""} - ${d?.errorText ?? ""}`

          const item: vis.DataItem = {
            title: title,
            id: i,
            start: d?.changed,
            end: d?.end,
            content: message,
            group: d?.machineId?.toString() ?? "",
            style: getStyleString(d?.machineStatus?.toString()),
          }
          machineIds.add(d?.machineId ?? "")
          return item
        })
        console.log("2")
        groups = [...machineIds].map((mcId) => ({ content: mcId, id: mcId, title: mcId }))
        console.log("3")
        timeline.setItems(items)
        timeline.setGroups(groups)
        setLoading(false)
        console.log("4")
      }, 200)
    }
  }, [data])

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {loading && <Spin />}
      <div style={{}} ref={container} />
      {details && (
        <Descriptions column={1} bordered>
          <Descriptions.Item label={"Start"}> {details.start}</Descriptions.Item>
          <Descriptions.Item label={"End"}> {details.end}</Descriptions.Item>
          <Descriptions.Item label={"Info"}> {details.Title}</Descriptions.Item>
        </Descriptions>
      )}
    </Space>
  )
}

export default LineMachineStatesTimeline
