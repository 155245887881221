import { useAccount, useMsal } from "@azure/msal-react"
import { Alert, Space } from "antd"
import React from "react"
import { SoudronicButtonGreen } from "./SoudronicButtonGreen"
import { SoudronicButtonOrange } from "./SoudronicButtonOrange"

export const GetPremium = ({ machineId }: { machineId?: string }) => {
    const { accounts } = useMsal()
    const account = useAccount(accounts[0] || {})
    const body = `%20%0D%0A%20%0D%0A%20%0D%0AMachine: ${machineId}%20%0D%0A Account: ${account?.username}`
    return (
        <>
            <Space direction="vertical" align="center" style={{ width: "100%" }}>
                <Alert
                    message="Basic Access"
                    description="Unlock
Premium dashboards with time-unlimited live production statistics at line, machine and component level"
                    type="info"
                    showIcon
                />
                <Space direction="horizontal">
                    <SoudronicButtonOrange externalLink={`mailto:spareparts@soudronic.com?subject=Quotation request for mySoudronic Premium subscription&body=${body}`} text={"Request quotation"} />
                    <SoudronicButtonGreen externalLink="https://soudronic.com/products/soudronic-digital-solutions/?load=16435" text="More Information" />
                </Space>
            </Space>
        </>
    )
}
