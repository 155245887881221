import { useQuery } from "@apollo/client"
import { Button, Card, Col, message, Row, Space, Typography } from "antd"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { soudronicAnthracite } from "../../constants/colors"
import { machineExistSelector } from "../../recoil/atom.machines"
import { gql } from "../../__generated__/gql"
import { TimeEditor } from "../Components/TimeEdit"
import { ErrorTimeline } from "../Timelines/ErrorTimeline"
import { MachineErrosTimeline } from "../Timelines/MachineErrorsTimeline"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { ScrollHint } from "./Helpers"
import { DownloadReport } from "../Components/ReportDownload"

const { Title } = Typography
const UPPER_ROW_HEIGHT = 350

const GET_ERROR_HIST = gql(`
        query ErrorHistFromTo($deviceId: String!, $max: Int, $begin: DateTime, $end: DateTime){
        eventLog(deviceId: $deviceId, maxNumber: $max, begin: $begin, end: $end){
            machineId
            errorId
            errorText
            startTimestamp
            duration
            endTimestamp
        }
    }`)

export const MachineErrorPage = () => {
    const params = useParams()
    const machineId = params.machineId ?? ""

    const timeSettings = useRecoilValue(selectedTimeSelector)

    const [messageApi, contextHolder] = message.useMessage()
    const success = () => {
        messageApi.open({
            type: "success",
            content: "Updated Data",
        })
    }

    const { data, refetch } = useQuery(GET_ERROR_HIST, {
        variables: { deviceId: machineId ?? "", max: 10000, begin: timeSettings.start.utc().toISOString(), end: timeSettings.stop.utc().toISOString() },
    })
    const { t } = useTranslation()

    const handleRefetch = () => {
        refetch()
        success()
    }
    const downloadButton = <DownloadReport begin={timeSettings.start} end={timeSettings.stop} deviceId={machineId} typeOfReport={"Errors"} name="Default" data={data?.eventLog} />

    const updateComponent = (
        <Space wrap>
            <Button onClick={() => handleRefetch()} style={{ backgroundColor: soudronicAnthracite, color: "white" }}>
                {t("updateData")}
            </Button>
            {downloadButton}
        </Space>
    )

    const machineExists = useRecoilValue(machineExistSelector(machineId))

    if (!machineExists) {
        return (
            <Card style={{ marginTop: 20, cursor: "default" }} title="No Machine">
                <p>No machine with id: {machineId}</p>
            </Card>
        )
    }

    return (
        <Space size={"middle"} direction="vertical" style={{ display: "flex" }}>
            <div />
            {contextHolder}
            <Title level={2}>{t("MachineError.Title")}</Title>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xl={6} sm={24}>
                    <Card style={{ height: UPPER_ROW_HEIGHT, alignItems: "center", cursor: "default" }} hoverable title="Time">
                        <div style={{ height: "100%", width: "100%" }}>
                            <TimeEditor machineId={machineId} />
                        </div>
                    </Card>
                </Col>
                <Col xl={18} sm={24}>
                    <Card extra={updateComponent} style={{ height: UPPER_ROW_HEIGHT, alignItems: "center", cursor: "default" }} hoverable title="Event Log">
                        <ErrorTimeline data={data} numberOfElements={200} />
                    </Card>
                </Col>
            </Row>
            <Card style={{ alignItems: "center", cursor: "default" }} hoverable title="Production History" extra={updateComponent}>
                <Space direction="vertical" style={{ display: "flex" }}>
                    {ScrollHint}
                    <MachineErrosTimeline data={data} isLine={machineId.endsWith("-ST")} />
                </Space>
            </Card>
        </Space>
    )
}
