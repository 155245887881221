import { createHttpLink, split, ApolloClient, InMemoryCache, ApolloProvider, NormalizedCacheObject, from } from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { getMainDefinition } from "@apollo/client/utilities"
import { useMsal } from "@azure/msal-react"
import { App, FloatButton, message } from "antd"
import { createClient } from "graphql-ws"
import React, { FC, useEffect } from "react"
import { useRecoilValue } from "recoil"
import { accessTokenState } from "../recoil/atom.msal"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { BatchHttpLink } from "@apollo/client/link/batch-http"
import { onError } from "@apollo/client/link/error"

interface MyProps {}

export let apolloClient: ApolloClient<NormalizedCacheObject>

export const ApolloProviderProject: FC<any> = (props: React.PropsWithChildren<MyProps>) => {
    const [messageApi, contextHolder] = message.useMessage()
    const token = useRecoilValue(accessTokenState)

    // const httpLink = createHttpLink({
    //     uri: process.env.REACT_APP_API_GRAPHQL_URL, //'http://localhost:4000/graphql',
    //     credentials: 'same-origin',
    // })
    const httpLink = new BatchHttpLink({
        uri: process.env.REACT_APP_API_GRAPHQL_URL,
        batchMax: 20, // No more than 5 operations per batch
        batchInterval: 20, // Wait no more than 20ms after first batched operation
    })

    const wsLink = new GraphQLWsLink(
        createClient({
            url: process.env.REACT_APP_API_GRAPHQL_WEBSOCKET_URL ? process.env.REACT_APP_API_GRAPHQL_WEBSOCKET_URL : "", //`ws://localhost:4000/graphql`,
            retryAttempts: Infinity,
            shouldRetry: () => true,
            keepAlive: 10000,
            on: {
                error: (e) => error("Websocket Disconnected"),
                connected: () => success("Connected"),
            },
            connectionParams: {
                Authorization: token ? `${token}` : "",
            },
        }),
    )

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query)
            return definition.kind === "OperationDefinition" && definition.operation === "subscription"
        },
        wsLink,
        httpLink,
    )

    const authLink = setContext((_, { headers }) => {
        // get the authentication token from local storage if it exists
        // return the headers to the context so httpLink can read them

        return {
            headers: {
                ...headers,
                Authorization: token ? `Bearer ${token}` : "",
            },
        }
    })

    const error = (message: string) => {
        messageApi.destroy()
        messageApi.open({
            type: "error",
            content: message,
            duration: 20,
            onClick: () => messageApi.destroy(),
        })
    }

    const success = (message: string) => {
        messageApi.destroy()
        messageApi.open({
            type: "success",
            content: message,
            duration: 5,
            onClick: () => messageApi.destroy(),
        })
    }

    const onErrorLink = onError(({ graphQLErrors, networkError, operation }) => {
        if (graphQLErrors) {
            for (let err of graphQLErrors) {
                switch (err.extensions?.code) {
                    // Apollo Server sets code to UNAUTHENTICATED
                    // when an AuthenticationError is thrown in a resolver
                    case "UNAUTHENTICATED":
                        // Modify the operation context with a new token
                        console.log("UNAUTH")
                }
                console.log({ err, operation })
                // error(err.message)
            }
        }

        // To retry on network errors, we recommend the RetryLink
        // instead of the onError link. This just logs the error.
        if (networkError) {
            console.log(`[Network error]: ${networkError}`)
            error(networkError.message)
        }
    })

    apolloClient = new ApolloClient({
        link: from([onErrorLink, authLink.concat(splitLink)]),
        // uri: process.env.REACT_APP_API_GRAPHQL_WEBSOCKET_URL ? process.env.REACT_APP_API_GRAPHQL_WEBSOCKET_URL : '', //`ws://localhost:4000/graphql`,
        connectToDevTools: false,
        cache: new InMemoryCache({
            typePolicies: {
                ProductionPathType: {
                    fields: {
                        machines: {
                            merge(existing = [], incoming) {
                                // Use a map to keep track of merged items using their __ref as a unique identifier
                                const merged = new Map(existing.map((ref: any) => [ref.__ref, ref]))
                                // Add or overwrite with incoming items
                                for (const ref of incoming) {
                                    merged.set(ref.__ref, ref)
                                }
                                // Return the merged array of references
                                return Array.from(merged.values())
                            },
                        },
                    },
                },
            },
        }),
    })

    return (
        <ApolloProvider client={apolloClient}>
            {contextHolder}
            {props.children}
        </ApolloProvider>
    )
}
