import { useQuery } from "@apollo/client"
import { Button, Card, Col, message, Row, Space, Switch, Typography } from "antd"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import { soudronicAnthracite } from "../../constants/colors"
import { machineExistSelector } from "../../recoil/atom.machines"
import { LineConnectionStatusEnumType, RollerheadIdsType } from "../../__generated__/graphql"
import { TimeEditor } from "../Components/TimeEdit"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { MachineRollerheadWidget } from "../Dashboard/Widgets/MachineRollerheadWidget"
import { RollerheadTable } from "../Components/RollerheadTable"
import dayjs from "dayjs"
import { IRollerheadData, RollerheadStatsDiagram } from "../Timelines/RollerheadStatsDiagram"
import { rollerheadStatisticUnitState } from "../../recoil/atoms.application"
import { gql } from "../../__generated__"

const { Title } = Typography

const GET_ROLLERHEAD_IDS = gql(`
query GetRollerheadIds($deviceId: String!, $begin: DateTime, $end: DateTime){
rollerheadsIds(deviceId: $deviceId, begin: $begin, end: $end){
  machineId
  eventDuration
  startTimestamp
  endTimestamp
  rollerheadId
  counter
  km
  warrantyProgress
  counterStart
  counterEnd
  rollerheadType{
      partNo
      description
      warrantyDistance
      diameterNew
      diameterLowerEnd
    }
   redressings{
      diameter
      startTimestamp
      endTimestamp
      eventDuration
      counter
      counterStart
      counterEnd
      eventDuration
      km
  }
}
} 
`)
const DEFAULT_DURATION_SELECTION = 24 * 30 * 24

export interface TimeRangeSelection {
    begin: dayjs.Dayjs
    end: dayjs.Dayjs
}

const sampleRollerheadData: IRollerheadData = {
    rollerheadType: "64M-447059",
    rollerheadMax: 56.2,
    rollerheadMin: 54,
    entires: [],
}

export const MachineRollerheadPage = () => {
    const { t } = useTranslation()
    const [timeSelection, setTimeSelection] = useState<TimeRangeSelection | undefined>()

    const params = useParams()
    const machineId = params.machineId ?? ""
    const timeSettings = useRecoilValue(selectedTimeSelector)

    const [rollerheadStatisticUnit, SetRollerheadStatisticUnit] = useRecoilState(rollerheadStatisticUnitState)

    const {
        loading: loadingIds,
        error: errorIds,
        data: dataIds,
        refetch: refetchIds,
    } = useQuery(GET_ROLLERHEAD_IDS, { variables: { deviceId: machineId ?? "", begin: timeSettings.start.endOf("day").toISOString(), end: timeSettings.stop.endOf("day").toISOString() } })

    const [selectedRollerheadData, setSelectedRollerheadData] = useState<RollerheadIdsType | undefined>()

    const handleRefetch = () => {
        refetchIds()
        success()
    }
    const [messageApi, contextHolder] = message.useMessage()
    const success = () => {
        messageApi.open({
            type: "success",
            content: "Updated Data",
        })
    }

    const updateComponent = (
        <Button onClick={() => handleRefetch()} style={{ backgroundColor: soudronicAnthracite, color: "white" }}>
            {t("updateData")}
        </Button>
    )

    const machineExists = useRecoilValue(machineExistSelector(machineId))

    if (!machineExists) {
        return (
            <Card style={{ marginTop: 20, cursor: "default" }} title="No Machine">
                <p>No machine with id: {machineId}</p>
            </Card>
        )
    }

    const handleTimeRangeUpdate = (range: TimeRangeSelection, index: string) => {
        setTimeSelection(range)
        const selectedRedressings = dataIds?.rollerheadsIds?.filter((r) => r.rollerheadId.replace("©", "") == index).at(0)
        setSelectedRollerheadData(selectedRedressings)
    }

    // rollerhead prediction.
    return (
        <Space size={"middle"} direction="vertical" style={{ display: "flex" }}>
            <div />
            {contextHolder}
            <Title level={2}>{t("MachineRollerhead.Title")}</Title>
            <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                <Col xl={8} sm={24}>
                    <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Time">
                        <div style={{ height: "100%", width: "100%" }}>
                            <TimeEditor machineId={machineId} defaultDuration={DEFAULT_DURATION_SELECTION} />
                        </div>
                    </Card>
                </Col>
                <Col xl={8} sm={24}>
                    <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Current Status">
                        <div style={{ height: "100%", width: "100%" }}>
                            <MachineRollerheadWidget machineId={machineId} connected={LineConnectionStatusEnumType.Connected} />
                        </div>
                    </Card>
                </Col>
                <Col xl={8} sm={24}>
                    <Card style={{ height: 350, alignItems: "center", cursor: "default" }} hoverable title="Settings">
                        <div style={{ height: "100%", width: "100%" }}>
                            <p>Select unit for Statistic calculation</p>
                            <Switch
                                checkedChildren="km"
                                unCheckedChildren="Cans"
                                checked={rollerheadStatisticUnit}
                                onChange={() => SetRollerheadStatisticUnit(!rollerheadStatisticUnit)}
                                title="Edit"
                            />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Card style={{ alignItems: "center", cursor: "default" }} hoverable title="Rollerhead History" extra={updateComponent}>
                <RollerheadTable loading={loadingIds} redressingData={selectedRollerheadData} rollerheadData={dataIds} updateRange={handleTimeRangeUpdate} rollerheadInfos={sampleRollerheadData} />
            </Card>
            <Card style={{ alignItems: "center", cursor: "default" }} hoverable title="Rollerhead Statistics" extra={updateComponent}>
                {selectedRollerheadData && <RollerheadStatsDiagram rollerheadData={selectedRollerheadData} machineId={machineId} />}
            </Card>
        </Space>
    )
}
