import { atom, selector } from 'recoil'


export const accessTokenState = atom({
    key: "accessTokenState",
    default: ""
})

export const hasAccessToken = selector({
    key: 'hasAccessToken', // unique ID (with respect to other atoms/selectors)
    get: ({ get }) => {
        const token = get(accessTokenState);

        return token != "";
    },
});


export const accessTokenRolesState = atom({
    key: "accessTokenRolesState",
    default: [] as String[]
})