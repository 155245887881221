import { Card } from "antd"
import React, { useState } from "react"
import { Responsive, WidthProvider } from "react-grid-layout"
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import { useRecoilValue } from "recoil"
import { editDashboard } from "../../recoil/atoms.application"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { NavigationButton } from "../Components/NavigationButton"
import { machineSelector } from "../../recoil/atom.machines"
import { MachineRollerheadWidget } from "./Widgets/MachineRollerheadWidget"

const ResponsiveReactGridLayout = WidthProvider(Responsive)

export const ComponentGrid = ({ machineId, connected }: { machineId?: string; connected?: LineConnectionStatusEnumType | null }) => {
    const machine = useRecoilValue(machineSelector(machineId))
    const edit = useRecoilValue(editDashboard)

    const [layouts, setLayout] = useState({
        lg: [
            { i: "a", x: 0, y: 0, w: 2, h: 1 },
            { i: "b", x: 1, y: 0, w: 1, h: 1 },
            { i: "c", x: 2, y: 0, w: 1, h: 1 },
            { i: "d", x: 3, y: 0, w: 1, h: 1 },
            { i: "e", x: 4, y: 0, w: 1, h: 1 },

            //  { i: "c", x: 4, y: 0, w: 6, h: 20 },
        ],
    })

    const linkRollerHead = `/${machineId}/rollerhead`

    return (
        <ResponsiveReactGridLayout
            className="layout"
            layouts={layouts}
            rowHeight={350}
            compactType="vertical"
            cols={{ lg: 5, md: 1, sm: 1, xs: 1, xxs: 1 }}
            isResizable={edit}
            isDraggable={edit}
            maxRows={2}
        >
            {machine?.machineType?.includes("SOUCAN") && (
                <div key="a">
                    <Card style={{ height: 350, cursor: "default" }} hoverable title="Rollerhead" extra={<NavigationButton text="Details" linkTo={linkRollerHead} />}>
                        <MachineRollerheadWidget machineId={machineId} />
                    </Card>
                </div>
            )}
        </ResponsiveReactGridLayout>
    )
}
