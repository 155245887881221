import { ChartData, Chart, ChartOptions, Plugin, TooltipItem, ChartDataset, Point } from "chart.js"
import React, { useEffect, useState } from "react"
import { Scatter } from "react-chartjs-2"
import "chart.js/auto"
import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm"

import zoomPlugin from "chartjs-plugin-zoom"
import { useRecoilValue } from "recoil"
import { rollerheadStatisticUnitState } from "../../recoil/atoms.application"
import { useQuery } from "@apollo/client"
import { ROLLERHEAD_DATA } from "../Dashboard/Widgets/MachineRollerheadWidget"
import dayjs from "dayjs"
import { Spin } from "antd"
import { RollerheadIdsType } from "../../__generated__/graphql"
import { soudronicRaspberry } from "../../constants/colors"

export interface iRollerheadRedressingType {
    __typename?: "RollerheadRedressingType" | undefined
    diameter: number
    startTimestamp: any
    endTimestamp: any
    eventDuration: any
    counter: any
    counterStart: any
    counterEnd: any
    km: any
}

export interface IRollerheadData {
    rollerheadType?: string
    rollerheadMin?: number
    rollerheadMax?: number
    entires: iRollerheadRedressingType[] | undefined
}

Chart.register(zoomPlugin)

export const RollerheadStatsDiagram = ({ rollerheadData, machineId }: { rollerheadData: RollerheadIdsType; machineId: string }) => {
    const [chartData, setChartData] = useState<ChartData<"scatter">>({ datasets: [], labels: [] })

    const rollerheadStatisticUnit = useRecoilValue(rollerheadStatisticUnitState)

    const { data } = useQuery(ROLLERHEAD_DATA, { variables: { deviceId: machineId ?? "" } })

    useEffect(() => {
        if (rollerheadData.redressings && data && data.rollerheadPrediction) {
            const redressings = rollerheadData.redressings
            const d = [] as any
            let sum = 0
            redressings.forEach((v, i) => {
                d.push({ x: Math.round(sum * 100) / 100, y: v.diameter })
                if (i !== redressings.length - 1) {
                    sum += rollerheadStatisticUnit ? v.km : v.counter
                }
            })
            if (dayjs(redressings.at(-1)?.endTimestamp).isAfter(dayjs().add(-1, "day"))) {
                const xStart = Math.round(sum * 100) / 100
                const pred = []
                pred.push({ x: d.at(-1).x, y: d.at(-1).y })
                pred.push({
                    x: rollerheadStatisticUnit ? data.rollerheadPrediction.distanceEndExpected + xStart : data.rollerheadPrediction.counterEndExptected + xStart,
                    y: data.rollerheadPrediction.expectedDiamer,
                })

                const current = []
                const currentDistance = Math.round((data.rollerheadPrediction.distanceActual + xStart) * 100) / 100
                const currentCounter = data.rollerheadPrediction.counterActual + xStart
                current.push({
                    x: rollerheadStatisticUnit ? currentDistance : currentCounter,
                    y: data.rollerheadPrediction.actualDiameter,
                })
                const configuredDatasets: ChartDataset<"scatter", (number | Point | null)[]>[] = [
                    { data: d, label: "Redressings" },
                    { data: current, label: "Actual", pointRadius: 10, pointHoverRadius: 18 },
                ]
                if (data.rollerheadPrediction.predictionPossible) {
                    configuredDatasets.push({ data: pred, label: "Prediction", borderDash: [10, 5] })
                }
                setChartData({ datasets: configuredDatasets })
            } else {
                setChartData({ datasets: [{ data: d, label: "Redressings" }] })
            }
        }
    }, [rollerheadData, data, rollerheadStatisticUnit])

    let xMax = undefined
    if (rollerheadData.rollerheadType?.warrantyDistance && rollerheadStatisticUnit) {
        xMax = rollerheadData.rollerheadType.warrantyDistance + rollerheadData.rollerheadType.warrantyDistance * 0.1
        if (rollerheadData.km + (data?.rollerheadPrediction.distanceEndExpected ?? 0) > xMax) {
            xMax = undefined
        }
    }

    const options: ChartOptions<"scatter"> = {
        maintainAspectRatio: false,
        showLine: true,
        animations: {
            y: {
                duration: 0,
            },
            x: {},
        },
        scales: {
            y: {
                min: rollerheadData.rollerheadType?.diameterLowerEnd ? rollerheadData.rollerheadType.diameterLowerEnd - 0.3 : undefined,
                max: rollerheadData.rollerheadType?.diameterNew ? rollerheadData.rollerheadType.diameterNew + 0.3 : undefined,
                title: {
                    display: true,
                    text: "Diameter [mm]",
                },
            },
            x: {
                title: {
                    display: true,
                    text: rollerheadStatisticUnit ? "Distance [km]" : "Units",
                },
                max: xMax,
            },
        },
        plugins: {
            zoom: {
                zoom: {
                    wheel: {
                        enabled: true,
                        modifierKey: "ctrl",
                    },
                    mode: "x",
                },
                pan: {
                    enabled: true,
                    mode: "x",
                },
            },
            tooltip: {
                callbacks: {
                    label: (v: TooltipItem<"scatter">) => {
                        return rollerheadStatisticUnit ? `${v.dataset.label}: ${v.parsed.x} km, ${v.parsed.y} mm` : `${v.dataset.label}: ${v.parsed.x} cans, ${v.parsed.y} mm`
                    },
                },
            },
        },
    }

    const canvasBackground: Plugin<"scatter"> = {
        id: "test",
        beforeDraw(chart, _args, _pluginOptions) {
            const {
                ctx,
                chartArea: { top, bottom, left, right, width },
                scales: { x, y },
            } = chart
            ctx.fillStyle = "rgba(75,192,192,0.2"
            if (rollerheadData.rollerheadType?.diameterNew && rollerheadData.rollerheadType.diameterLowerEnd) {
                ctx.fillRect(
                    left,
                    y.getPixelForValue(rollerheadData.rollerheadType.diameterNew),
                    width,
                    y.getPixelForValue(rollerheadData.rollerheadType.diameterLowerEnd) - y.getPixelForValue(rollerheadData.rollerheadType.diameterNew),
                )
            }
            if (rollerheadData.rollerheadType?.warrantyDistance && rollerheadStatisticUnit) {
                const xwitdh = x.getPixelForValue(rollerheadData.rollerheadType.warrantyDistance)
                ctx.beginPath()
                ctx.strokeStyle = soudronicRaspberry
                ctx.moveTo(xwitdh, top)
                ctx.lineTo(xwitdh, bottom)
                ctx.stroke()

                // write label
                ctx.fillStyle = soudronicRaspberry
                ctx.lineWidth = 2
                ctx.textAlign = "left"
                ctx.textBaseline = "bottom"
                ctx.fillText("End Of Warranty", xwitdh + 2, bottom - 2)
            }
        },
    }

    return rollerheadData.redressings ? <Scatter height={400} options={options} data={chartData} plugins={[canvasBackground]} /> : <Spin />
}
