import { Badge, Card, Descriptions, Popover } from "antd"
import React from "react"
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { soudronicBlue } from "../../constants/colors"
import { premiumState } from "../../recoil/atoms.application"
import { GetPremium } from "../Components/GetPremium"
import { SoudronicButtonGreen } from "../Components/SoudronicButtonGreen"

export const GeneralInfos = ({ connectedLines, totalMachines }: { connectedLines?: number; totalMachines?: number }) => {
    const premium = useRecoilValue(premiumState)
    const popoverContent = <GetPremium />
    const ribbonContent = premium ? (
        "Premium"
    ) : (
        <Popover placement="left" content={popoverContent}>
            Basic
        </Popover>
    )
    return (
        <Badge.Ribbon color={soudronicBlue} text={ribbonContent}>
            <Card hoverable style={{ cursor: "default" }}>
                <Descriptions column={{ xs: 1, sm: 1, md: 1, xxl: 4, lg: 2, xl: 4 }} bordered title="Info">
                    <Descriptions.Item label="Connected Machines">
                        {" "}
                        <Badge color="green" status="processing" text="" /> {connectedLines ?? 0}
                    </Descriptions.Item>
                    <Descriptions.Item label="Total Machines">{totalMachines}</Descriptions.Item>
                    <Descriptions.Item label="Feedback">
                        <SoudronicButtonGreen externalLink="https://forms.office.com/e/0NJMigSRQm" text="Feedback Form" />
                    </Descriptions.Item>
                    <Descriptions.Item label="INSIGHTS">
                        <Link to="/versionHistory">Version History</Link>
                    </Descriptions.Item>
                </Descriptions>
            </Card>
        </Badge.Ribbon>
    )
}
