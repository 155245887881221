import { useQuery } from "@apollo/client"
import React, { useEffect, useState } from "react"
import { TimeLineItemProps } from "antd/es/timeline/TimelineItem"
import { LINE_MACHINE_LOG } from "../../Pages/LineMachineStatesPage"
import { Empty, Timeline } from "antd"

export const LineMachinesTimeline = ({ machineId, numberOfElements }: { machineId?: string; numberOfElements: number }) => {
    const { data } = useQuery(LINE_MACHINE_LOG, { variables: { deviceId: machineId ?? "", max: 5 } })
    const [items, setItems] = useState<TimeLineItemProps[]>([])

    useEffect(() => {
        if (data?.lineStatesLog) {
            const tmp: TimeLineItemProps[] = data.lineStatesLog
                .slice(-(numberOfElements ?? 5))
                .map((d) => ({ color: "red", children: `${new Date(d?.changed).toLocaleTimeString()}: ${d?.machineId ? d.machineId : ""} - ${d?.machineStatus}` }))
            setItems(tmp)
        }
    }, [data])

    return (
        <>
            <div style={{ overflow: "hidden", height: 250 }}>
                {items.length > 0 ? <Timeline style={{ marginTop: 5 }} reverse={true} items={items} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            </div>
        </>
    )
}
