import React from "react"
import { BadgeProps, Button, Tabs, Tooltip } from "antd"
import { Badge, Calendar } from "antd"
import type { Dayjs } from "dayjs"
import type { CellRenderInfo } from "rc-picker/lib/interface"
import "./timeEditCalendar.css"
import { GET_SHIFTS, ShiftsEditor } from "./ShiftsEditor"
import { SelectInfo } from "antd/es/calendar/generateCalendar"
import { useQuery } from "@apollo/client"
import { useRecoilState } from "recoil"
import { selectedTimeState } from "../../recoil/atom.time"
import dayjs from "dayjs"
import { FieldTimeOutlined } from "@ant-design/icons"

interface IListData {
    type: string
    content: string
    start: string
    end: string
    id: string
}

export const TimeEditCalendar = ({ machineId, closeCalendar }: { machineId: string; closeCalendar: () => void }) => {
    const { data, loading, error, refetch } = useQuery(GET_SHIFTS, { variables: { deviceId: machineId } })
    const [selectedTime, setSelectedTime] = useRecoilState(selectedTimeState)

    const getListData = (value: Dayjs): IListData[] => {
        if (data) {
            const weekday = value.day()
            const shiftsOnDay = data.shifts.filter((s) => s.days.includes(weekday))
            return shiftsOnDay.map((s) => ({ type: "warning", content: s.name, start: s.begin, end: s.end, id: s.id }))
        }
        return []
    }

    const getMonthData = (value: Dayjs) => {
        if (value.month() === 8) {
            return 1394
        }
    }

    const monthCellRender = (value: Dayjs) => {
        const num = getMonthData(value)
        return num ? (
            <div className="notes-month">
                <section>{num}</section>
                <span>Backlog number</span>
            </div>
        ) : null
    }

    const dateCellRender = (value: Dayjs) => {
        const listData = getListData(value)
        return (
            <ul className="events">
                {listData.map((item) => (
                    <li key={item.content} onClick={() => handleShiftSelect(item, value)}>
                        <Tooltip title={`${item.start} - ${item.end}`}>
                            <FieldTimeOutlined />
                            <a href={"#"}> {item.content}</a>
                        </Tooltip>
                    </li>
                ))}
            </ul>
        )
    }

    const cellRender = (current: Dayjs, info: CellRenderInfo<Dayjs>) => {
        if (info.type === "date") return dateCellRender(current)
        if (info.type === "month") return monthCellRender(current)
        return info.originNode
    }

    const format = "HH:mm"
    const handleShiftSelect = (item: IListData, value: dayjs.Dayjs) => {
        const shifts = data?.shifts.filter((s) => s.id == item.id)
        if (shifts?.length == 1) {
            const shift = shifts[0]
            const beginShift = dayjs(shift.begin, format)
            const begin = value.set("hour", beginShift.hour()).set("minute", beginShift.minute()).set("second", 0)
            const endShift = dayjs(shift.end, format)
            let end = value.set("hour", endShift.hour()).set("minute", endShift.minute()).set("second", 0)

            let diff = dayjs.duration(end.diff(begin))
            if (diff.asSeconds() < 0) {
                end = end.add(dayjs.duration(1, "day"))
            }
            setSelectedTime({ start: begin.unix(), stop: end.unix(), shiftName: shift.name })
            closeCalendar()
        }
    }

    const handleTabClick = (activKey: string) => {
        if (activKey == "calendar") refetch()
    }

    return (
        <>
            <Tabs
                onTabClick={handleTabClick}
                items={[
                    {
                        label: "Shifts",
                        key: "calendar",
                        children: <Calendar style={{ height: "80vh" }} cellRender={cellRender} />,
                    },
                    {
                        label: "Settings",
                        key: "Edit",
                        children: <ShiftsEditor machineId={machineId} />,
                    },
                ]}
            />
        </>
    )
}
