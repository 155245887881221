import { useEffect, useState } from "react";
import { useLocalStorage } from "./useLocalStorage";
let scrollPos = 0
export function useWindowScrollPosition(localStorageKey: string, setCondition: boolean): void {
    const [scrollYStorage, setScrollYStorage] = useLocalStorage(localStorageKey, 0);

    useEffect(() => {
        if (setCondition) {
            window.scrollTo(0, scrollYStorage)
        }
    }, [setCondition, scrollYStorage])

    const handleScroll = (event: any) => {
        scrollPos = (window.scrollY)

    }
    useEffect(() => {
        return () => {
            setScrollYStorage(scrollPos)
        };
    }, [])
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [])
}