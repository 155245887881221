import * as msal from "@azure/msal-browser";
import { EventMessage } from "@azure/msal-browser";


const msalConfig = {
    auth: {
        clientId: 'cf5753d4-c284-41fe-9aaa-c254bb52be23',
        authority: 'https://login.microsoftonline.com/2c1dce83-d5b7-40b0-b7f5-819de9a7ff63',
        redirectUri: '/',
    }, cache: {
        cacheLocation: "localStorage",
    },
};
export const msalInstance = new msal.PublicClientApplication(msalConfig);


msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === msal.EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as msal.AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});
