import { useQuery } from "@apollo/client"
import { Empty, Statistic } from "antd"
import { ChartData } from "chart.js"
import React, { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import { gql } from "../../../__generated__"

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip as tt, Legend } from "chart.js"
import { useTranslation } from "react-i18next"
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, tt, Legend)

export const GET_TOP_ERRORS = gql(`
query TopErrorsLine($deviceId: String!,$take: Int){
  topErrorLine(deviceId: $deviceId){
    deviceId
    begin
    end
    totalTime
    totalCount
    topMachineErrors(take:$take){
      machineId
      error{
        errorId
        errorText
      }
      count
      totalTime
    }
    machineErrors{
      machineId
      error{
        errorId
        errorText
      }
    }
  }
}
`)

const NUMBER_OF_ERRORS = 10

const backgroundColor = ["rgba(255, 99, 132, 1)", "rgba(54, 162, 235, 1)", "rgba(255, 206, 86, 1)", "rgba(75, 192, 192, 1)", "rgba(153, 102, 255,1)", "rgba(255, 159, 64, 1)"]

export const LineTopErrorWidget = ({ lineId, showLegend = false }: { lineId?: string; showLegend?: boolean }) => {
    const { t } = useTranslation()

    const { data } = useQuery(GET_TOP_ERRORS, { pollInterval: 120000, variables: { deviceId: lineId ?? "", take: 9999 } })
    const [chartData, setChartData] = useState<ChartData<"bar">>({ labels: [], datasets: [] })
    const [totalErrors, setTotalErrors] = useState(0)

    const [tooltip, setTooltip] = useState({
        value: "",
        description: "",
    }) //initial tooltip state

    useEffect(() => {
        if (data?.topErrorLine?.topMachineErrors) {
            const labels = data.topErrorLine.topMachineErrors.slice(0, NUMBER_OF_ERRORS).map((d) => d?.machineId + " " + d?.error?.errorText)
            const values = data.topErrorLine.topMachineErrors.slice(0, NUMBER_OF_ERRORS).map((d) => d?.count)
            if (values.length > 0) {
                const sum = values.reduce((p, v) => p + v)
                setTotalErrors(sum)
            }
            setChartData({ labels: labels, datasets: [{ data: values, borderRadius: 5, backgroundColor: backgroundColor }] })
        }
    }, [data])
    //https://stackoverflow.com/questions/28476159/chart-js-pie-tooltip-getting-cut
    return chartData.datasets[0]?.data.length > 0 ? (
        <div style={{ height: 250 }}>
            <Statistic style={{ right: 30, top: 70, position: "absolute" }} title={t("TopErrorWidget.Statistic")} value={totalErrors} />
            <div style={{ height: 190, marginTop: 50 }}>
                <Bar
                    // width={100}
                    height={100}
                    options={{
                        scales: {
                            x: {
                                display: false,
                            },
                            y: {
                                ticks: {
                                    callback: (v) => (Number.parseFloat(v.toString()) % 1 === 0 ? v : ""),
                                },
                            },
                        },
                        maintainAspectRatio: false,
                        plugins: {
                            tooltip: {
                                enabled: true,
                                external: (context) => {
                                    const tooltipModel = context.tooltip
                                    if (tooltipModel.dataPoints) {
                                        const newTooltipData = {
                                            value: tooltipModel.dataPoints[0]?.formattedValue,
                                            description: tooltipModel.dataPoints[0]?.label,
                                        }
                                        if (tooltip.description !== newTooltipData.description) setTooltip(newTooltipData)
                                    }
                                },
                            },
                            legend: { display: false },
                        },
                    }}
                    data={chartData}
                />
            </div>
        </div>
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    )
}
