import { Button, DatePicker, Drawer, Space, Tag } from "antd"
import { RangePickerProps } from "antd/es/date-picker"
import React, { useEffect, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { selectedTimeSelector, selectedTimeState } from "../../recoil/atom.time"
import dayjs, { Dayjs } from "dayjs"
import { secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import { ClockCircleOutlined, FieldTimeOutlined } from "@ant-design/icons"
import { TimeEditCalendar } from "./TimeEditCalendar"
import { premiumState } from "../../recoil/atoms.application"
import { GetPremium } from "./GetPremium"

const { RangePicker } = DatePicker

export const TimeEditor = ({ defaultDuration = 8, machineId }: { defaultDuration?: number; machineId: string }) => {
    const [selectedTime, setSelectedTime] = useRecoilState(selectedTimeState)
    const selectedTimeDate = useRecoilValue(selectedTimeSelector)
    const premium = useRecoilValue(premiumState)

    const [isModalOpen, setIsModalOpen] = useState(false)

    const showModal = () => {
        setIsModalOpen(true)
    }

    const handleOk = () => {
        setIsModalOpen(false)
    }

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        setSelectedTime({ start: dayjs.utc().subtract(defaultDuration, "hours").unix(), stop: dayjs.utc().unix() })
    }, [])

    const onChange = (value: RangePickerProps["value"], dateString: [string, string] | string) => {
        onOk(value)
    }

    const onOk = (value: RangePickerProps["value"]) => {
        const start = value?.[0]?.utc().unix() ?? 0
        const stop = value?.[1]?.utc().unix() ?? 0

        if (start && stop) {
            setSelectedTime({ start: start, stop: stop })
        }
    }

    const disabledDate = (current: Dayjs) => {
        const tooLate = selectedTimeDate.start && current.diff(selectedTimeDate.start, "days") >= 7
        const tooEarly = selectedTimeDate.stop && selectedTimeDate.stop.diff(current, "days") >= 7
        return !!tooEarly || !!tooLate
    }

    const rangePresets: {
        label: string
        value: [Dayjs, Dayjs]
    }[] = [
        { label: "Last 8 Hours", value: [dayjs().add(-8, "h"), dayjs()] },
        { label: "Last 10 Hours", value: [dayjs().add(-10, "h"), dayjs()] },
        { label: "Last 16 Hours", value: [dayjs().add(-16, "h"), dayjs()] },
        { label: "Last 24 Hours", value: [dayjs().add(-24, "h"), dayjs()] },
        { label: "Last 2 Days", value: [dayjs().add(-2, "d"), dayjs()] },
    ]
    if (!premium) {
        return <GetPremium machineId={machineId} />
    }
    return (
        <>
            <Space direction="vertical">
                <Space direction="horizontal">
                    <RangePicker
                        // disabledDate={disabledDate}
                        presets={rangePresets}
                        value={[selectedTimeDate.start, selectedTimeDate.stop]}
                        showTime={{ format: "HH:mm" }}
                        format="YYYY-MM-DD HH:mm"
                        onChange={onChange}
                        onOk={onOk}
                        showNow={true}
                        showSecond={false}
                        placement="bottomLeft"
                    />
                    <Button type="primary" onClick={showModal}>
                        Select Shift
                    </Button>
                </Space>
                <Space direction="horizontal">
                    <Tag icon={<ClockCircleOutlined />} color="default">
                        {secondsToDHHMMSS(selectedTimeDate.duration / 1000)}
                    </Tag>
                    <Tag icon={<FieldTimeOutlined />} color="default">
                        {selectedTime.shiftName ?? ""}
                    </Tag>
                </Space>
                <Drawer
                    title="Shifts"
                    open={isModalOpen}
                    placement="left"
                    onClose={() => handleOk()}
                    width="90VW"
                    extra={
                        <Space>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button type="primary" onClick={handleOk}>
                                OK
                            </Button>
                        </Space>
                    }
                >
                    <TimeEditCalendar machineId={machineId} closeCalendar={() => setIsModalOpen(false)} />
                </Drawer>
            </Space>
        </>
    )
}
