import { Card, Grid, Typography } from "antd"
import React, { useState } from "react"
import { Responsive, WidthProvider } from "react-grid-layout"
import "/node_modules/react-grid-layout/css/styles.css"
import "/node_modules/react-resizable/css/styles.css"
import { useRecoilValue } from "recoil"
import { editDashboard } from "../../recoil/atoms.application"
import { MachineState } from "./Widgets/MachineState"
import { LineConnectionStatusEnumType } from "../../__generated__/graphql"
import { NavigationButton } from "../Components/NavigationButton"
import { TopErrorWidget } from "./Widgets/TopErrorWidget"
import { TimelineWidget } from "./Widgets/TimelineWidget"
import { MachineStatesWidget } from "./Widgets/MachineStatesWidget"
import { MachineCounterWidget } from "./Widgets/MachineCounterWidget"
import { useTranslation } from "react-i18next"

const ResponsiveReactGridLayout = WidthProvider(Responsive)
const { Text } = Typography

const { useBreakpoint } = Grid

export const MachineGrid = ({ machineId, connected }: { machineId?: string; connected?: LineConnectionStatusEnumType | null }) => {
  const { t } = useTranslation()

  const screens = useBreakpoint()
  const edit = useRecoilValue(editDashboard)

  const [layouts, setLayout] = useState({
    lg: [
      { i: "a", x: 0, y: 0, w: 1, h: 1 },
      { i: "b", x: 1, y: 0, w: 1, h: 1 },
      { i: "c", x: 2, y: 0, w: 1, h: 1 },
      { i: "d", x: 3, y: 0, w: 1, h: 1 },
      { i: "e", x: 4, y: 0, w: 1, h: 1 },

      //  { i: "c", x: 4, y: 0, w: 6, h: 20 },
    ],
  })

  const linkStatus = `/${machineId}/machinestate`
  const linkError = `/${machineId}/machineerror`
  const linkTopError = `/${machineId}/MachineTopError`
  const linkStates = `/${machineId}/machinestate`
  const linkEffectiveness = `/${machineId}/Effectiveness`

  return (
    <ResponsiveReactGridLayout
      className="layout"
      layouts={layouts}
      rowHeight={350}
      compactType="vertical"
      cols={{ lg: 5, md: 1, sm: 1, xs: 1, xxs: 1 }}
      isResizable={edit}
      isDraggable={edit}
      maxRows={2}
    >
      <div key="a">
        <Card
          style={{ height: 350, alignItems: "center", cursor: "default", position: "relative", overflow: "hidden" }}
          hoverable
          title="Machine Status"
          extra={<NavigationButton text="Details" linkTo={linkStatus} />}
        >
          {/* <div style={{ height: "100%", width: "100%", position: "relative" }}> */}
          <MachineState machineId={machineId} connected={connected} />
          {/* </div> */}
        </Card>
      </div>

      <div key="b">
        <Card style={{ height: 350, cursor: "default" }} hoverable title="Top Errors" extra={<NavigationButton text="Details" linkTo={linkTopError} />}>
          <TopErrorWidget machineId={machineId} externalData={false} max={8} />
        </Card>
      </div>
      <div key="c">
        <Card style={{ height: 350, cursor: "default" }} hoverable title="Event Log" extra={<NavigationButton linkTo={linkError} text="Details" />}>
          <TimelineWidget machineId={machineId} />
        </Card>
      </div>
      <div key="d">
        <Card style={{ height: 350, cursor: "default" }} hoverable title="Machine State Summary" extra={<NavigationButton linkTo={linkStates} text="Details" />}>
          <MachineStatesWidget machineId={machineId} />
        </Card>
      </div>
      <div key="e">
        <Card style={{ height: 350, cursor: "default" }} hoverable title={t("machineEffectiveness.title")} extra={<NavigationButton linkTo={linkEffectiveness} text="Details" />}>
          <MachineCounterWidget machineId={machineId} connected={connected} />
        </Card>
      </div>
    </ResponsiveReactGridLayout>
  )
}
