import { Table } from "antd"
import { ColumnsType } from "antd/es/table"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { getSecondsFromhhmmss, secondsToDHHMMSS } from "../../helpers/TimeHelpers"
import { TopMachinErrorQuery } from "../../__generated__/graphql"

interface DataType {
    error: string,
    errorId: string,
    count: number,
    duration: number
}

export const TopErrorTable = ({ errorData }: { errorData: TopMachinErrorQuery }) => {


    const [data, setData] = useState<DataType[]>([])
    const { t } = useTranslation()
    useEffect(() => {
        if (errorData.machineTopError) {
            const errors: DataType[] = errorData.machineTopError?.map(e => ({ errorId: e?.error?.errorId ?? "?", error: e?.error?.errorText ?? "?", count: e?.count, duration: e?.totalTime }))
            setData(errors)
        }
    }, [errorData])


    const columns: ColumnsType<DataType> = [
        {
            title: t("topErrorTable.error"),
            dataIndex: "error"
        },
        {
            title: t("topErrorTable.errorId"),
            dataIndex: "errorId",
            responsive: ['md'],
        },
        {
            title: t("topErrorTable.count"),
            dataIndex: "count",
            sorter: (a, b) => a.count - b.count,
            defaultSortOrder: "descend"
        },
        {
            title: t("topErrorTable.duration"),
            dataIndex: "duration",
            render: (value) => secondsToDHHMMSS(value),
            sorter: (a, b) => (a.duration) - (b.duration),
        }
    ]



    return (
        <Table columns={columns} dataSource={data} rowKey="errorId" />
    )
}