import { Button, Card, Dropdown, MenuProps, message, Modal, Radio, RadioChangeEvent, Select, SelectProps, Space, Tag, Typography } from "antd"
import dayjs from "dayjs"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useRecoilValue } from "recoil"
import { accessTokenState } from "../../recoil/atom.msal"
import { selectedTimeSelector } from "../../recoil/atom.time"
import { ClockCircleOutlined, FieldTimeOutlined } from "@ant-design/icons"
import { secondsToDHHMM } from "../../helpers/TimeHelpers"
import { MessageType } from "antd/es/message/interface"
import { premiumState } from "../../recoil/atoms.application"
import { SoudronicButtonOrange } from "./SoudronicButtonOrange"
const { Text } = Typography

interface IProps {
    deviceId: string
    typeOfReport?: string
    buttonText?: string
    begin: dayjs.Dayjs
    end: dayjs.Dayjs
    name?: string
    data?: any
    all?: boolean
}
const items: MenuProps["items"] = [
    {
        label: "Custom",
        key: "custom",
    },
]

const reportingApiUrl = (process.env.REACT_APP_API_REST_URL ?? "") + "/reporting"
let messageStorage: MessageType

export const DownloadReport = (props: IProps) => {
    const [messageApi, contextHolder] = message.useMessage()
    const { t } = useTranslation()
    const [downloading, setDownloading] = useState(false)
    const [_failure, setFailure] = useState<string | undefined>(undefined)
    const [reportTypes, setReportTypes] = useState<string[]>([])
    const selectedTimeDate = useRecoilValue(selectedTimeSelector)
    const [radioValue, setRadioValue] = useState(1)
    const [selectedReports, setSelectedReports] = useState<string[]>([])

    const premium = useRecoilValue(premiumState)

    const token = useRecoilValue(accessTokenState)
    const authHeader = () => {
        const authorization = "Bearer " + token
        return { Authorization: authorization }
    }

    const [open, setOpen] = useState(false)

    useEffect(() => {
        const fetchReportTypes = async () => {
            const result_request = await fetch(reportingApiUrl, { headers: { ...authHeader(), "Content-Type": "application/json" } })
            const result_json: string[] = await result_request.json()
            if (result_json) {
                setReportTypes(result_json.slice(1))
            }
        }
        if (open) {
            fetchReportTypes()
        }
    }, [open])

    useEffect(() => {
        if (props.all) {
            setSelectedReports(reportTypes)
        } else {
            if (props.typeOfReport && reportTypes.includes(props.typeOfReport)) {
                setSelectedReports([props.typeOfReport])
            }
        }
    }, [reportTypes, props.all])

    const success = (filename: string) => {
        messageStorage.then(() => message.success(`Export Successful: ${filename}`, 2.5))
    }

    const handleError = (error: any) => {
        setFailure("Cannot fetch")
        return Promise.reject(error && error.message)
    }

    const onDownloadClick = async () => {
        setDownloading(true)
        messageStorage = messageApi.open({
            type: "loading",
            content: "Report Requested",
            duration: 2.5,
        })
        setTimeout(async () => {
            const reportingApiUrl = (process.env.REACT_APP_API_REST_URL ?? "") + "/reporting"
            const requestOptions: RequestInit = {
                method: "POST",
                headers: { ...authHeader(), "Content-Type": "application/json" },
                body: JSON.stringify({
                    TypeOfReport: "All",
                    DeviceId: props.deviceId,
                    Begin: props.begin,
                    End: props.end,
                    TimeRangeName: selectedTimeDate.shiftName || "Time Range",
                }),
            }
            const blobResponse = await fetch(reportingApiUrl, requestOptions).catch(handleError)

            const filename = `${props.deviceId}.xlsx`
            if (blobResponse.ok) {
                blobResponse.blob().then((blob) => {
                    // Download the file
                    let url = window.URL.createObjectURL(blob)
                    let a = document.createElement("a")
                    a.href = url
                    a.download = filename
                    a.click()
                })
            } else {
                setFailure(blobResponse.statusText)
            }
            setDownloading(false)
            success(filename)
        }, 2000)
    }

    const downloadJson = () => {
        if (props.data) {
            const filename = `${props.deviceId}_${props.typeOfReport}.json`
            const blob = new Blob([JSON.stringify(props.data, null, 2)], { type: "application/json" })
            let url = window.URL.createObjectURL(blob)
            let a = document.createElement("a")
            a.href = url
            a.download = filename
            a.click()
        }
    }

    const handleMenuClick: MenuProps["onClick"] = (e) => {
        switch (e.key) {
            case "excel":
                onDownloadClick()
                break
            case "custom":
                setOpen(true)
                break
            default:
        }
    }

    const handleButtonClick = (_e: React.MouseEvent<HTMLButtonElement>) => {
        onDownloadClick()
    }

    const menuProps = {
        items,
        onClick: handleMenuClick,
    }

    const handleChange = (value: string[]) => {
        setSelectedReports(value)
    }

    const options: SelectProps["options"] = reportTypes.map((rp) => ({ value: rp, label: rp }))

    const onRadioChange = (e: RadioChangeEvent) => {
        setRadioValue(e.target.value)
    }

    const handleModalOk = () => {
        onDownloadClick()
        setOpen(false)
    }

    return (
        <>
            {premium ? (
                <Dropdown.Button loading={downloading} menu={menuProps} onClick={handleButtonClick}>
                    Export
                </Dropdown.Button>
            ) : (
                <SoudronicButtonOrange externalLink="https://soudronic.com/products/soudronic-digital-solutions/?load=16435" text={"Export - Get Premium"} />
            )}
            <Modal title="Configure Export" open={open} okText={"Download"} okButtonProps={{ disabled: selectedReports.length < 1 }} onCancel={() => setOpen(false)} onOk={handleModalOk}>
                <Space direction="vertical" style={{ width: "100%" }}>
                    <Card hoverable style={{ cursor: "default" }}>
                        <p>
                            <Text>Selecte time from </Text>
                            <Text strong>{selectedTimeDate.start.toDate().toLocaleString()}</Text>
                            <Text> to </Text>
                            <Text strong>{selectedTimeDate.stop.toDate().toLocaleString()}</Text>
                        </p>
                        <Space direction="horizontal">
                            <Tag icon={<ClockCircleOutlined />} color="default">
                                {secondsToDHHMM(selectedTimeDate.duration / 1000)}
                            </Tag>
                            {selectedTimeDate.shiftName && (
                                <Tag icon={<FieldTimeOutlined />} color="default">
                                    {selectedTimeDate.shiftName ?? ""}
                                </Tag>
                            )}
                        </Space>
                    </Card>
                    <Space.Compact style={{ width: "100%" }} direction="horizontal">
                        <Select mode="multiple" allowClear style={{ width: "100%" }} placeholder="Please select" value={selectedReports} onChange={handleChange} options={options} />
                        <Button onClick={() => handleChange(reportTypes)}>Add All</Button>
                    </Space.Compact>
                    <Radio.Group buttonStyle="solid" onChange={onRadioChange} value={radioValue}>
                        <Radio.Button value={1}>Excel</Radio.Button>
                        <Radio.Button value={2}>JSON</Radio.Button>
                    </Radio.Group>
                </Space>
            </Modal>
            {contextHolder}
        </>
    )
}
